import { action } from 'mobx'

export default function TemplateServiceFunction({ servicesData, setServicesData, insertValues }) {
	const fieldDataService = (data, templateCreator, newTemplate) => {
		let newServicesData = { card: { cardFees: {} }, ach: { achFees: {} }, mgm: { mgmFees: {} }, odp: { } }
		let structure = { visible: true, subHeader: '', subFooter: '' }
		for (let element in structure) {
			newServicesData[element] = insertValues(data[templateCreator], 'servicesData', element, structure[element], 'oneElement')
		}

		structure = { visible: true, pricingPlan: 0 }
		for (let element in structure) {
			newServicesData.card[element] = insertValues(data[templateCreator].servicesData, 'card', element, structure[element], 'oneElement')
			newServicesData.ach[element] = insertValues(data[templateCreator].servicesData, 'ach', element, structure[element], 'oneElement')
			newServicesData.mgm[element] = insertValues(data[templateCreator].servicesData, 'mgm', element, structure[element], 'oneElement')
		}

		structure = {
			card: {
				provider: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				gateway: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				processor: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				tierName: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				pdfTemplateId: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				cardFlat_percentxAuth: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardFlat_amountxAuth: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardFlat_lowPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardFlat_highPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardPassThrough_percentxAuth: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardPassThrough_amountxAuth: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardPassThrough_percentRecurring: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardPassThrough_amountRecurring: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardPassThrough_lowPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardPassThrough_highPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardICP_percentxAuth: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardICP_amountxAuth: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardICP_lowPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				cardICP_highPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				pricingType: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				discountFrequency: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				fundingRollup: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				passThroughCost: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: null },
				cardAcceptance: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false },
				cardICP: { visible: true },
				cardFlat: { visible: true },
				cardPassThrough: { visible: true, multiTier: false }
			},
			ach: {
				provider: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				gateway: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				processor: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				tierName: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				pdfTemplateId: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				achAbsorb_lowPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				achAbsorb_highPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				achPass_lowPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				achPass_highPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				pricingType: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				discountFrequency: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				fundingRollup: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				achAcceptance: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false },
				achAbsorb: { visible: true, multiTier: false },
				achPassThrough: { visible: true, multiTier: false }
			},
			mgm: {
				provider: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				gateway: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				processor: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				tierName: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				pdfTemplateId: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				mgmAbsorb_lowPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				mgmAbsorb_highPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				mgmPass_lowPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				mgmPass_highPayRange: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
				pricingType: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				discountFrequency: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				fundingRollup: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				mgmAcceptance: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false },
				mgmAbsorb: { visible: true, multiTier: false },
				mgmPassThrough: { visible: true, multiTier: false }
			},
			odp: {
				programId: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			}
		}
		for (let parentElement in structure) {
			for (let element in structure[parentElement]) {
				newServicesData[parentElement][element] = insertValues(data[templateCreator].servicesData, parentElement, element, structure[parentElement][element])
			}
		}

		structure = {
			card: {
				cardFees: {
					transactionCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					chargebackCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					achBatchCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					avsCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					montlyPlatformCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					annualCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					minimumProcessingCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					ddaRejectsCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					retrievalCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					monthlyPCICardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					earlyTerminationCardFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null }
				},
				cardAcceptance: {
					visa: { visible: true, readOnly: false, required: null },
					masterCard: { visible: true, readOnly: false, required: null },
					discover: { visible: true, readOnly: false, required: null },
					amex: { visible: true, readOnly: false, required: null }
				},
				cardICP: {
					visa: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					masterCard: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					discover: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					amex: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 }
				},
				cardFlat: {
					visa: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					masterCard: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					discover: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					amex: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 }
				},
				cardPassThrough: {
					visa: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 },
					masterCard: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 },
					discover: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 },
					amex: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 }
				}
			},
			ach: {
				achFees: {
					returnedAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					chargebackAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					monthlyAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					annualAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					verifyBankAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					verifyNegativeAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					verifyFundAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					sundayOriginationAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					sameDayAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					advancedSettlementAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					quarterlyPCIAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					earlyTerminationAchFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null }
				},
				achAcceptance: {
					web: { visible: true, readOnly: false, required: null },
					ppd: { visible: true, readOnly: false, required: null },
					ccd: { visible: true, readOnly: false, required: null }
				},
				achAbsorb: {
					web: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					ppd: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					ccd: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 }
				},
				achPassThrough: {
					web: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 },
					ppd: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 },
					ccd: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 }
				}
			},
			mgm: {
				mgmFees: {
					returnedMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					chargebackMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					monthlyMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					annualMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					verifyBankMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					verifyNegativeMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					verifyFundMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: true, required: null },
					sundayOriginationMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					sameDayMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					advancedSettlementMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null },
					quarterlyPCIMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: true, required: null },
					earlyTerminationMgmFee: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: null }
				},
				mgmAcceptance: {
					web: { visible: true, readOnly: false, required: null },
					ppd: { visible: true, readOnly: false, required: null },
					ccd: { visible: true, readOnly: false, required: null }
				},
				mgmAbsorb: {
					web: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					ppd: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 },
					ccd: { percentxAuth: 0, amountxAuth: 0, lowPayRange: 0, highPayRange: 0 }
				},
				mgmPassThrough: {
					web: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 },
					ppd: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 },
					ccd: { amountFeeOneTime: 0, percentFeeOneTime: 0, amountFeeRecurring: 0, percentFeeRecurring: 0, lowPayRange: 0, highPayRange: 0 }
				}
			}
		}
		for (let parentElement in structure) {
			for (let SubElement in structure[parentElement]) {
				const entryElement = {}
				if (SubElement === 'cardAcceptance' || SubElement === 'achAcceptance' || SubElement === 'mgmAcceptance') {
					newServicesData[parentElement][SubElement]['types'] = {}
				} else if (SubElement !== 'cardAcceptance' && SubElement !== 'cardFees' && SubElement !== 'achAcceptance' && SubElement !== 'achFees' && SubElement !== 'mgmAcceptance' && SubElement !== 'mgmFees') {
					newServicesData[parentElement][SubElement]['tiers'] = []
				}

				for (let element in structure[parentElement][SubElement]) {
					if (SubElement === 'cardFees' || SubElement === 'achFees' || SubElement === 'mgmFees') {
						newServicesData[parentElement][SubElement][element] = insertValues(data[templateCreator].servicesData[parentElement], SubElement, element, structure[parentElement][SubElement][element])
					} else if (SubElement === 'cardAcceptance' || SubElement === 'achAcceptance' || SubElement === 'mgmAcceptance') {
						newServicesData[parentElement][SubElement]['types'][element] = data[templateCreator].servicesData[parentElement] === null || data[templateCreator].servicesData[parentElement] === undefined ? insertValues(data[templateCreator].servicesData[parentElement], 'types', element, structure[parentElement][SubElement][element]) : insertValues(data[templateCreator].servicesData[parentElement][SubElement], 'types', element, structure[parentElement][SubElement][element])
					} else {
						entryElement[element] = data[templateCreator].servicesData[parentElement] === null || data[templateCreator].servicesData[parentElement] === undefined ? insertValues(data[templateCreator].servicesData[parentElement], 'tiers', element, structure[parentElement][SubElement][element], 'array') : insertValues(data[templateCreator].servicesData[parentElement][SubElement], 'tiers', element, structure[parentElement][SubElement][element], 'array')
					}
				}
				if (SubElement !== 'cardAcceptance' && SubElement !== 'cardFees' && SubElement !== 'achAcceptance' && SubElement !== 'achFees' && SubElement !== 'mgmAcceptance' && SubElement !== 'mgmFees') newServicesData[parentElement][SubElement]['tiers'].push(entryElement)
			}
		}

		newServicesData.card.cardFees.visible = !data[templateCreator].servicesData.card || data[templateCreator].servicesData.card === null || data[templateCreator].servicesData.card === undefined ? true : insertValues(data[templateCreator].servicesData.card, 'cardFees', 'visible', true, 'oneElement')

		newServicesData.ach.achFees.visible = !data[templateCreator].servicesData.ach || data[templateCreator].servicesData.ach === null || data[templateCreator].servicesData.ach === undefined ? true : insertValues(data[templateCreator].servicesData.ach, 'achFees', 'visible', true, 'oneElement')

		newServicesData.mgm.mgmFees.visible = !data[templateCreator].servicesData.mgm || data[templateCreator].servicesData.mgm === null || data[templateCreator].servicesData.mgm === undefined ? true : insertValues(data[templateCreator].servicesData.mgm, 'mgmFees', 'visible', true, 'oneElement')
		
		newServicesData.odp.visible = !data[templateCreator].servicesData || data[templateCreator].servicesData === null || data[templateCreator].servicesData === undefined ? true : insertValues(data[templateCreator].servicesData, 'odp', 'visible', true, 'oneElement')

		if (newTemplate) {
			newServicesData = {
				...newServicesData,
				card: { ...newServicesData.card, discountFrequency: { ...newServicesData.card.discountFrequency, required: false }, fundingRollup: { ...newServicesData.card.fundingRollup, required: false }, pricingType: { ...newServicesData.card.pricingType, required: false } },
				ach: { ...newServicesData.ach, discountFrequency: { ...newServicesData.ach.discountFrequency, required: false }, fundingRollup: { ...newServicesData.ach.fundingRollup, required: false }, pricingType: { ...newServicesData.ach.pricingType, required: false } }
			}
		}
		setServicesData(newServicesData)
	}
	const handleChangeService = action((type, subProperty, property, field, value, onlyField = false) => {
		if (!onlyField) {
			let valuesChecked = ''
			if (field === 'visible' && !value) {
				valuesChecked = { readOnly: false, required: false, visible: false }
			} else if (field === 'readOnly' && value) {
				valuesChecked = { readOnly: value, required: false, visible: true }
			} else if (field === 'required' && value) {
				valuesChecked = { readOnly: false, required: value, visible: true }
			}
			if ((subProperty === 'cardFees' || subProperty === 'achFees' || subProperty === 'mgmFees') && field === 'visible') {
				setServicesData(value ? { ...servicesData, [type]: { ...servicesData[type], [subProperty]: { ...servicesData[type][subProperty], visible: true, [property]: { ...servicesData[type][subProperty][property], [field]: value } } } } : { ...servicesData, [type]: { ...servicesData[type], [subProperty]: { ...servicesData[type][subProperty], [property]: { ...servicesData[type][subProperty][property], [field]: value } } } })
			} else if (subProperty) {
				if (!valuesChecked) {
					setServicesData({ ...servicesData, [type]: { ...servicesData[type], [subProperty]: { ...servicesData[type][subProperty], [property]: { ...servicesData[type][subProperty][property], [field]: value } } } })
				} else {
					setServicesData({ ...servicesData, [type]: { ...servicesData[type], [subProperty]: { ...servicesData[type][subProperty], [property]: { ...servicesData[type][subProperty][property], readOnly: valuesChecked.readOnly, required: valuesChecked.required, visible: valuesChecked.visible } } } })
				}
			} else if (type) {
				if (!valuesChecked) {
					setServicesData({ ...servicesData, [type]: { ...servicesData[type], [property]: { ...servicesData[type][property], [field]: value } } })
				} else {
					setServicesData({ ...servicesData, [type]: { ...servicesData[type], [property]: { ...servicesData[type][property], readOnly: valuesChecked.readOnly, required: valuesChecked.required, visible: valuesChecked.visible } } })
				}
			} else {
				setServicesData({ ...servicesData, [property]: { ...servicesData[property], [field]: value } })
			}
		} else {
			setServicesData({ ...servicesData, [field]: value })
		}
	})
	const handleChangeFees = action((type, property, value) => {
		const valuesElement = {}
		const fees = {
			card: ['transactionCardFee', 'chargebackCardFee', 'achBatchCardFee', 'avsCardFee', 'montlyPlatformCardFee', 'annualCardFee', 'minimumProcessingCardFee', 'ddaRejectsCardFee', 'retrievalCardFee', 'monthlyPCICardFee', 'earlyTerminationCardFee'],
			ach: ['returnedAchFee', 'chargebackAchFee', 'monthlyAchFee', 'annualAchFee', 'verifyBankAchFee', 'verifyNegativeAchFee', 'verifyFundAchFee', 'sundayOriginationAchFee', 'sameDayAchFee', 'advancedSettlementAchFee', 'quarterlyPCIAchFee', 'earlyTerminationAchFee'],
			mgm: ['returnedMgmFee', 'chargebackMgmFee', 'monthlyMgmFee', 'annualMgmFee', 'verifyBankMgmFee', 'verifyNegativeMgmFee', 'verifyFundMgmFee', 'sundayOriginationMgmFee', 'sameDayMgmFee', 'advancedSettlementMgmFee', 'quarterlyPCIMgmFee', 'earlyTerminationMgmFee']
		}
		fees[type].forEach((element) => {
			valuesElement[element] = {
				...servicesData[type][property][element],
				visible: value
			}
		})
		valuesElement.visible = value
		setServicesData({ ...servicesData, [type]: { ...servicesData[type], [property]: valuesElement } })
	})
	const handleChangePricingType = action((type, subProperty0, subProperty, property, field, value) => {
		if (!Array.isArray(subProperty)) {
			setServicesData({ ...servicesData, [type]: { ...servicesData[type], [subProperty0]: { ...servicesData[type][subProperty0], [subProperty]: { ...servicesData[type][subProperty0][subProperty], [property]: { ...servicesData[type][subProperty0][subProperty][property], [field]: value } } } } })
		} else {
			const newTiers = servicesData[type][subProperty0][subProperty[0]].map((data) => {
				return {
					...data,
					[property]: { ...data[property], [field]: value }
				}
			})
			setServicesData({ ...servicesData, [type]: { ...servicesData[type], [subProperty0]: { ...servicesData[type][subProperty0], [subProperty[0]]: newTiers } } })
		}
	})
	const validCheckedOdp = (value) => {
		const valuesElement = {}
		const elementService = Object.entries(servicesData?.odp).filter((element) => element[0] !== 'visible').map((element) => element[0])
		elementService.forEach((element) => {
			valuesElement[element] = {
				...servicesData?.odp[element],
				visible: value, required: value, readOnly: false
			}
		})
		valuesElement.visible = value
		setServicesData({ ...servicesData, odp: valuesElement })
	}
	return { handleChangeService, handleChangeFees, handleChangePricingType, fieldDataService, validCheckedOdp }
}
