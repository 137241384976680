import React, { useState, useEffect } from 'react'
import { NameAvatar } from '../NameAvatar'
import { MdAdminPanelSettings, MdOutlineEmail } from 'react-icons/md'
import { BiRightArrowAlt, BiCheckCircle, BiMessageRoundedError } from 'react-icons/bi'
import { PayabliStorageManager } from '../../api/localStorageManager'
import globalStore from '../../store/GlobalStore'
import NoteSendMail from './NoteSendMail'

export default function MessageList({ notesEvents = [], sendEmail = false }) {
	const [openSendMail, setOpenSendMail] = useState('')
	const [managementUser, setManagementUser] = useState(false)

	const sendEmailNote = (className, idNote) => {
		return (
			<div className={`${className}`}>
				<label
					onClick={() => setOpenSendMail(!openSendMail ? idNote : '')}
					style={{ cursor: 'pointer', fontSize: '11px', color: '#03A9F4' }}>
					<MdOutlineEmail style={{ height: '16px', width: '16px' }} />
					&nbsp;Send note by Email
				</label>
			</div>
		)
	}

	useEffect(() => {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let user = encryptStorage.getItem('pUser')
		setManagementUser(user.permissions.map((element) => element.toUpperCase()).includes('MANAGEMENT'))
	}, [])

	return notesEvents.length > 0 ? (
		<>
			{notesEvents.map((note, i) => (
				<div
					key={'MessageList' + i}
					className={`d-flex flex-row w-100 send-note-email-clicked ${openSendMail === note.id ? 'btn-clicked' : ''}`}>
					<div className="d-flex justify-content-center align-items-center">
						{note.messageType === 1 && openSendMail !== note.id ? (
							<NameAvatar text={note.userName} />
						) : note.messageType === 2 && openSendMail !== note.id ? (
							<div
								className="avatar-container"
								style={{ backgroundColor: '#CEEEFD' }}>
								<div
									className="d-flex align-items-center justify-content-center"
									style={{ width: '35px', height: '35px' }}>
									<MdAdminPanelSettings
										className="icon-modal"
										style={{ height: '24px', color: '#015074' }}
									/>
								</div>
							</div>
						) : (
							<BiCheckCircle
								className="avatar-container"
								style={{ height: '40px', width: '40px', padding: '0px', color: '#03A9F4' }}
							/>
						)}
					</div>
					<div className="d-flex flex-column w-100 send-note-email-main">
						<div className="d-flex flex-row align-items-center flex-wrap">
							<div className="d-flex flex-row align-items-center flex-wrap flex-grow-1">
								<label className="name-notes-event">{note.userName}</label>&nbsp;&nbsp;
								<label className="date-notes-event">{`${globalStore.stringDateFormatV3(note.createdAt)} - ${globalStore.stringTimeFormatV3(note.createdAt)}`}</label>
							</div>
							{sendEmail && managementUser && sendEmailNote('send-note-email', note.id)}
						</div>
						<p
								className="name-notes-event"
								style={{ fontWeight: '400', textAlign: 'left', margin: '0' }}>
								{note.content.split(';').map((content, index) => (
									<React.Fragment key={index}>
										{content}
										{index < note.content.split(';').length - 1 && <br />}
									</React.Fragment>
								))}
							</p>
						{note.messageType !== 1 && (<div className="d-flex flex-row flex-wrap">
								<span>{globalStore.getAppStatusText(note.originalApplicationStatus)}</span>&nbsp;
								<span>
									<BiRightArrowAlt style={{ color: '#ADB5BD', fontSize: '18px' }} />
								</span>
								&nbsp;
								<span>{globalStore.getAppStatusText(note.currentApplicationStatus)}</span>
							</div>)}
						
						{sendEmail && managementUser && sendEmailNote('send-note-email-phone', note.id)}
					</div>
				</div>
			))}
			{openSendMail && (
				<NoteSendMail
					showModal={openSendMail ? true : false}
					closeModal={() => setOpenSendMail('')}
					idMessage={openSendMail}
				/>
			)}
		</>
	) : (
		<div className="d-flex flex-column justify-content-center align-items-center gap-1">
			<BiMessageRoundedError
				className="avatar-container"
				style={{ height: '32px', width: '32px', padding: '0px', color: '#606060' }}
			/>
			<label style={{ color: '#606060', fontSize: '16px', fontWeight: '700' }}>No notes found</label>
		</div>
	)
}
