import React from 'react'
import { inject, observer } from 'mobx-react'
import { Tab, Tabs, Row, Button, Table, InputGroup, Tooltip, OverlayTrigger, Modal, Card, DropdownButton, Dropdown } from 'react-bootstrap'
import axios from 'axios'
import { TopBar } from '../../components/TopBar'
import { MainTopBarMenu } from '../../components/MainTopBarMenu'
import { ApplicationStatus } from '../../components/ApplicationStatus'
import { Layout } from '../../components/Layout'
import { InputSimpleLink } from '../../components/InputSimpleLink'
import { ReadOnlySimpleLink } from '../../components/ReadOnlySimpleLink'
import { InputSelectLink } from '../../components/InputSelectLink'
import { InputSelectSearchable } from '../../components/InputSelectSearchable'
import { InputCalendarLink } from '../../components/InputCalendarLink'
import { InputSelectTemplate } from '../../components/InputSelectTemplate'
import { CheckLink } from '../../components/CheckLink'
import { ReadOnlyCheckLink } from '../../components/ReadOnlyCheckLink'
import { ToastContainer, toast, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BiCopyAlt, BiTrash, BiChevronRight, BiChevronLeft, BiDetail, BiUserCheck, BiCog, BiDollarCircle, BiPlus, BiInfoCircle, BiX } from 'react-icons/bi'
import { RiBankLine } from 'react-icons/ri'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import 'react-datepicker/dist/react-datepicker.css'
import { IMaskInput } from 'react-imask'
import Moment from 'moment'
import { LocationSearchInput } from '../../components/LocationSearchInput'
import visa from '../../assets/images/visa.svg'
import mastercard from '../../assets/images/mastercard.svg'
import amex from '../../assets/images/amex.png'
import discover from '../../assets/images/discover.svg'
import achIcon from '../../assets/images/ach.svg'
import PhoneInput from '../../components/PhoneInput'
import { SessionManager } from '../../api/sessionManager'
import { PayabliCookieManager, PayabliStorageManager } from '../../api/localStorageManager'
import { getTiersDetailsGET } from '../../store/Template/Connections/connections'
import SendEmailModal from './Applications/SendEmailModal'
import * as Sentry from '@sentry/react'

@inject('boarding', 'store', 'global', 'reports', 'entry')
@observer
class EditApplication extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			baseTemplate: null,
			template_data: null,
			baseData: null,
			boardingStatus: null,
			boardingSubStatus: null,
			appId: 0,
			filesSize: 0,
			fileExceededSize: false,
			lastFileSize: 0,
			currentSection: 'business',
			offOwn: true,
			offPrc: true,
			offSrv: true,
			offDoc: true,
			complete: false,
			bsameaddress: false,
			endLinkModalIsOpen: false,
			additionalOwners: [],
			lastowner: 0,
			additionalContacts: [],
			lastcontact: 0,
			additionalFiles: [],
			payPointId: null,
			createDatePayPoint: '',
			lastfile: 0,
			currentPos: 0,
			tiersName: [],
			card_tierName: '',
			ach_tierName: '',
			activePos: [],
			isLoading: true,
			cancelTplModalIsOpen: false,
			saveTplModalIsOpen: false,
			isRoot: false,
			termsAccepted: [],
			checkAccepted: [],
			appIdToModify: null,
			saveEmailModalIsOpen: false,
			appemail: '',
			applicationEmailModalIsOpen: false,
			applicationCopyLinkModalIsOpen: false,
			applicationLinkEmail: '',
			applicationLinkEmailError: false,
			idAppLink: null,
			appLink: '',
			referenceId: '',
			showSend2Processor: false,
			cancelAppModalIsOpen: false,
			signer: {
				name: '',
				ssn: '',
				dob: '',
				phone: '',
				email: '',
				address: '',
				address1: '',
				state: '',
				country: '',
				city: '',
				zip: '',
				acceptance: false
			},
			signers: []
		}
		this.setTab = this.setTab.bind(this)
		this.bSameaddresshandleChange = this.bSameaddresshandleChange.bind(this)
		this.addOwner = this.addOwner.bind(this)
		this.removeOwner = this.removeOwner.bind(this)
		this.addOwnerContacts = this.addOwnerContacts.bind(this)
		this.onFileChange = this.onFileChange.bind(this)
		this.addDoc = this.addDoc.bind(this)
		this.removeDoc = this.removeDoc.bind(this)
		this.saveToComplete = this.saveToComplete.bind(this)
		this.submitComplete = this.submitComplete.bind(this)
		this.closeEndLinkModal = this.closeEndLinkModal.bind(this)
		this.getSignerBlock = this.getSignerBlock.bind(this)
		this.selectContactAsSigner = this.selectContactAsSigner.bind(this)
		this.handlerGetBaseLink = this.handlerGetBaseLink.bind(this)
		this.setSignerDOB = this.setSignerDOB.bind(this)
		this.handleAcceptClick = this.handleAcceptClick.bind(this)
		this.handleAcceptCheckClick = this.handleAcceptCheckClick.bind(this)
		this.handleEmailChange = this.handleEmailChange.bind(this)
		this.saveEmailModal = this.saveEmailModal.bind(this)
		this.closeSaveEmailModal = this.closeSaveEmailModal.bind(this)
		this.openApplicationEmailModal = this.openApplicationEmailModal.bind(this)
		this.closeApplicationEmailModal = this.closeApplicationEmailModal.bind(this)
		this.handleCopyApplicationEmail = this.handleCopyApplicationEmail.bind(this)
		this.getApplicationLink = this.getApplicationLink.bind(this)
		this.copyAppLink = this.copyAppLink.bind(this)
		this.copyToClipboard = this.copyToClipboard.bind(this)
		this.openCancelAppModal = this.openCancelAppModal.bind(this)
		this.closeCancelAppModal = this.closeCancelAppModal.bind(this)
		this.cancelAppAction = this.cancelAppAction.bind(this)
		this.onCreatePaypoint = this.onCreatePaypoint.bind(this)
		this.handleTextChangeMask = this.handleTextChangeMask.bind(this)
		this.handleGoogleAutocompleteBusinessAddress = this.handleGoogleAutocompleteBusinessAddress.bind(this)
		this.handleGoogleAutocompleteBusinessMAddress = this.handleGoogleAutocompleteBusinessMAddress.bind(this)
		this.handleGoogleAutocompleteOwnerAddress = this.handleGoogleAutocompleteOwnerAddress.bind(this)
		this.handleGoogleAutocompleteSignerAddress = this.handleGoogleAutocompleteSignerAddress.bind(this)
		this.sendToProcessor = this.sendToProcessor.bind(this)
		this.setOwnerDataDOB = this.setOwnerDataDOB.bind(this)
		this.handleChangeTierName = this.handleChangeTierName.bind(this)
		this.changeStatus = this.changeStatus.bind(this)
		this.OwnerTypeChange = this.OwnerTypeChange.bind(this)
		this.onCopySigner2Owner = this.onCopySigner2Owner.bind(this)
	}
	changeStatus(status, subStatus = null) {
		const show2Processor = (this.state.baseTemplate?.services?.ach?.processor?.value === 'gp' || this.state.baseTemplate?.services?.card?.processor?.value === 'gp') && [3, 10].includes(parseInt(status))
		this.setState({
			boardingStatus: status,
			boardingSubStatus: subStatus,
			showSend2Processor: show2Processor
		})
	}
	OwnerTypeChange(ownType) {
		if (ownType === 'Non-Profit Org') {
			this.onCopySigner2Owner()
		}
	}

	setBusinessDataDate(k, data, field) {
		let baseData = this.self.state?.baseData
		if (baseData && baseData[field]) {
			baseData[field] = data
		}
		this.self.setState({
			baseData: baseData
		})
	}
	handleChangeTierName(e) {
		this.props.boarding.setTemplateData(e.target.name, e.target.value)
		this.setState({
			[e.target.name]: e.target.value?.toUpperCase()
		})
	}
	setOwnerDataDOB(k, data, field) {
		let baseData = this.state.baseData
		if (baseData.ownerData && baseData.ownerData[k]) {
			baseData.ownerData[k][field] = data
		}

		this.setState({
			baseData: baseData
		})
	}
	handleGoogleAutocompleteSignerAddress(signer_address, address2, signer_city, signer_state, signer_zip, signer_country) {
		this.props.boarding.setTemplateData('signer_address', signer_address)
		this.props.boarding.setTemplateData('signer_address2', address2)
		this.props.boarding.setTemplateData('signer_city', signer_city)
		this.props.boarding.setTemplateData('signer_state', signer_state)
		this.props.boarding.setTemplateData('signer_zip', signer_zip)
		this.props.boarding.setTemplateData('signer_country', signer_country)
		let signer = this.state.signer
		signer.country = signer_country ? signer_country : ''
		signer.city = signer_city ? signer_city : ''
		signer.state = signer_state ? signer_state : ''
		signer.address = signer_address ? signer_address : ''
		signer.address1 = address2 ? address2 : ''
		signer.zip = signer_zip ? signer_zip : ''
		this.setState({
			signer: signer
		})
	}

	handleGoogleAutocompleteOwnerAddress(oaddress, address2, ocity, ostate, ozip, ocountry, k) {
		this.props.boarding.setTemplateData('oaddress_' + k, oaddress)
		this.props.boarding.setTemplateData('ocity_' + k, ocity)
		this.props.boarding.setTemplateData('ostate_' + k, ostate)
		this.props.boarding.setTemplateData('ozip_' + k, ozip)
		this.props.boarding.setTemplateData('ocountry_' + k, ocountry)
		this.props.boarding.setTemplateData('oaddress2_' + k, address2)
		let values = {}
		values['ocountry_' + k] = ocountry ? ocountry : ''
		values['ocity_' + k] = ocity ? ocity : ''
		values['ostate_' + k] = ostate ? ostate : ''
		values['ozip_' + k] = ozip ? ozip : ''
		values['oaddress_' + k] = oaddress ? oaddress : ''
		values['oaddress2_' + k] = address2 ? address2 : ''
		this.setState(values)
	}

	handleGoogleAutocompleteBusinessAddress(baddress, baddress1, bcity, bstate, bzip, bcountry) {
		this.props.boarding.setTemplateData('baddress', baddress)
		this.props.boarding.setTemplateData('baddress1', baddress1)
		this.props.boarding.setTemplateData('bcity', bcity)
		this.props.boarding.setTemplateData('bstate', bstate)
		this.props.boarding.setTemplateData('bzip', bzip)
		this.props.boarding.setTemplateData('bcountry', bcountry)
		this.setState({
			baddress: baddress ? baddress : '',
			baddress1: baddress1 ? baddress1 : '',
			bcity: bcity ? bcity : '',
			bstate: bstate ? bstate : '',
			bzip: bzip ? bzip : '',
			bcountry: bcountry ? bcountry : ''
		})
	}

	handleGoogleAutocompleteBusinessMAddress(maddress, maddress1, mcity, mstate, mzip, mcountry) {
		this.props.boarding.setTemplateData('maddress', maddress)
		this.props.boarding.setTemplateData('maddress1', maddress1)
		this.props.boarding.setTemplateData('mcity', mcity)
		this.props.boarding.setTemplateData('mstate', mstate)
		this.props.boarding.setTemplateData('mzip', mzip)
		this.props.boarding.setTemplateData('mcountry', mcountry)
		this.setState({
			maddress: maddress ? maddress : '',
			maddress1: maddress1 ? maddress1 : '',
			mcity: mcity ? mcity : '',
			mstate: mstate ? mstate : '',
			mzip: mzip ? mzip : '',
			mcountry: mcountry ? mcountry : ''
		})
	}

	handleTextChangeMask(e, value) {
		this.props.boarding.setTemplateData(e, value)
		this.setState({ [e]: value })
	}

	handleAcceptClick = (e) => {
		e.preventDefault()
		let index = e.target.getAttribute('term')
		if (this.state.termsAccepted?.length > 0) {
			let accept = this.state.termsAccepted
			accept[index] = true
			this.setState({ termsAccepted: accept })
		} else {
			let accept = Array(this.state.baseTemplate.banking.termsConditions.length).fill(false)
			accept[index] = true
			this.setState({ termsAccepted: accept })
		}
		let href = e.target.getAttribute('href')
		if (href === null) {
			window.open('/404', '_blank')
		} else {
			window.open(href, '_blank')
		}
	}

	handleAcceptCheckClick = (e) => {
		let accept = this.state.termsAccepted
		let allchecked = (arr) => arr.every((v) => v === true)
		let signer = this.state.signer
		let signer_acceptance = false
		if (e.target.checked) {
			if (!allchecked(accept)) {
				e.preventDefault()
				toast.error('You must click and review link(s) above before agreeing.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			} else {
				signer.acceptance = true
				signer_acceptance = true
				this.setState({ signer: signer })
			}
		} else {
			signer.acceptance = false
			this.setState({ signer: signer })
		}
		this.props.boarding.setTemplateData('signer_acceptance', signer_acceptance)
	}

	handlerGetBaseLink = async () => {
		this.props.boarding.clearTemplateData()
		this.props.boarding.clearErrorData()
		this.props.global.setLoading(true)
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		axios
			.get(process.env.REACT_APP_URL_API + 'Boarding/read/' + this.state.appId, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((template) => {
				axios
					.get(process.env.REACT_APP_URL_API + 'Templates/get/' + template.data.templateId, {
						headers: {
							requestToken: encryptStorage.getItem('pToken')
						}
					})
					.then((tdata) => {
						if (!tdata.data || tdata.data === null) {
							this.props.global.setLoading(false)
							let errorMessage = 'Missing Template Data!'
							toast.error(errorMessage, {
								position: toast.POSITION.BOTTOM_RIGHT,
								className: 'toast-error-container'
							})
							//this.props.history.goBack();
							return
						}
						if (!template.data || template.data === null) {
							this.props.global.setLoading(false)
							let errorMessage = 'Missing Template Data!'
							toast.error(errorMessage, {
								position: toast.POSITION.BOTTOM_RIGHT,
								className: 'toast-error-container'
							})
							this.props.history.push('/' + PayabliStorageManager.getEntryName() + '/boarding/applications')
							return
						}
						let orgId = encryptStorage.getItem(this.props.global.getURLEntry() + '_pEntry').orgId
						axios
							.get(process.env.REACT_APP_URL_API + 'Query/paypoints/' + orgId + '?limitRecord=1&boardingId(eq)=' + this.state.appId, {
								headers: {
									requestToken: encryptStorage.getItem('pToken')
								}
							})
							.then((paypoint) => {
								let template_data = template.data
								let baseTemplate = tdata.data.templateContent
								let terms = baseTemplate.documentsData?.termsAndConditions?.visible
								let termsAccepted = []

								if (terms) {
									if (template.data.signer.acceptance) {
										termsAccepted = Array(terms.length).fill(true)
									} else {
										termsAccepted = Array(terms.length).fill(false)
									}
								}
								const show2Processor = (baseTemplate?.servicesData?.ach?.processor?.value === 'gp' || baseTemplate?.servicesData?.card?.processor?.value === 'gp') && [3, 10].includes(template.data.boardingStatus)
								let valuesState = {
									baseTemplate: baseTemplate,
									template_data: template_data,
									baseData: template_data,
									boardingStatus: template.data.boardingStatus,
									boardingSubStatus: template.data.boardingSubStatus,
									showSend2Processor: show2Processor,
									signer: template.data.signer,
									isLoadingBase: false,
									update: true,
									termsAccepted: termsAccepted
								}
								if (template_data.ownType === 'Non-Profit Org') {
									const signer = valuesState.signer
									const ownerNonProfit = {
										ownername: signer?.name,
										ownerssn: signer?.ssn,
										ownerdob: signer?.dob,
										ownerphone1: signer?.phone,
										owneremail: signer?.email,
										oaddress: signer?.address,
										oaddress1: signer?.address1,
										ostate: signer?.state,
										ocountry: signer?.country,
										ocity: signer?.city,
										ozip: signer?.zip,
										ownertitle: valuesState.baseData.ownerData[0]?.ownertitle,
										ownerpercent: valuesState.baseData.ownerData[0]?.ownerpercent,
										ownerphone2: valuesState.baseData.ownerData[0]?.ownerphone2,
										ownerdriver: valuesState.baseData.ownerData[0]?.ownerdriver,
										odriverstate: valuesState.baseData.ownerData[0]?.odriverstate
									}
									valuesState.baseData.ownerData[0] = ownerNonProfit
								}
								let payPointData = paypoint.data.Records
								if (payPointData.length > 0) {
									valuesState.payPointId = payPointData[0].IdPaypoint
									valuesState.createDatePayPoint = payPointData[0].CreatedAt
									valuesState.entryPoint = payPointData[0]?.EntryPoints[0]
								}
								this.setState(valuesState, () => {
									if (this.state.baseTemplate) {
										if (this.state.baseTemplate.documentsData && this.state.baseTemplate.documentsData.minimumDocuments) {
											let x = parseInt(this.state.baseTemplate.documentsData.minimumDocuments, 10)
											this.setState({ lastfile: x })
										}
										if (this.state.baseData.contactData !== null && this.state.baseData.contactData.length > 1) {
											var cts = []
											for (var cc = 1; cc < this.state.baseData.contactData.length; cc++) {
												cts.push(cc)
											}
											this.setState({
												additionalContacts: cts,
												lastcontact: this.state.baseData.contactData.length
											})
										}
										if (this.state.baseData.ownerData !== null && this.state.baseData.ownerData.length > 1) {
											var ots = []
											for (var oc = 1; oc < this.state.baseData.ownerData.length; oc++) {
												ots.push(oc)
											}
											this.setState({
												additionalOwners: ots,
												lastowner: this.state.baseData.ownerData.length
											})
										}

										//bsameaddress checkbox
										if (
											(this.state.template_data.bAddress1 !== null || this.state.template_data.bAddress2 !== null || this.state.template_data.bCity !== null || this.state.template_data.bState !== null || this.state.template_data.bZip !== null || this.state.template_data.bCountry !== null) &&
											(this.state.template_data.mAddress1 !== null || this.state.template_data.mAddress2 !== null || this.state.template_data.mCity !== null || this.state.template_data.mState !== null || this.state.template_data.mZip !== null || this.state.template_data.mCountry !== null)
										) {
											this.setState({
												bsameaddress: true
											})
										}
										//init positions
										var atabs = []
										var cs = ''
										if (this.state.baseTemplate.businessData !== null) {
											atabs.push(0)
											cs = 'business'
										}
										if (this.state.baseTemplate.ownershipData !== null) {
											atabs.push(1)
											if (cs === '') cs = 'owners'
										}
										if (this.state.baseTemplate.processingDataData !== null) {
											atabs.push(2)
											if (cs === '') cs = 'processing'
										}
										if (this.state.baseTemplate.servicesData !== null) {
											atabs.push(3)
											if (cs === '') cs = 'services'
										}
										if (this.state.baseTemplate.documentsData !== null) {
											atabs.push(4)
											if (cs === '') cs = 'banking'
										}
										atabs.push(5)
										this.setState({
											currentSection: cs,
											activePos: atabs
										})
										this.props.global.setLoading(false)
									}
								})
							})
					})
					.catch((error) => {
						this.props.global.setLoading(false)
						let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
						toast.error(errorMessage, {
							position: toast.POSITION.BOTTOM_RIGHT,
							className: 'toast-error-container'
						})
						this.props.history.push('/' + PayabliStorageManager.getEntryName() + '/boarding/applications')
					})
			})
	}

	onCreatePaypoint(e) {
		this.props.global.setLoading(true)
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		axios
					.get(process.env.REACT_APP_URL_API + 'Management/paypointFromApp/' + this.state.appId, {
						headers: {
							requestToken: encryptStorage.getItem('pToken'),
						}
					})
					.then((paypoint) => {
						let paypointId = paypoint.data.responseData
						axios
							.get(process.env.REACT_APP_URL_API + 'Management/paypoint/' + paypointId, {
								headers: {
									requestToken: encryptStorage.getItem('pToken'),
								}
							})
							.then((res) => {
								this.setState(
									{
										payPointId: res.data.idPaypoint,
										createDatePayPoint: res.data.createdAt
									},
									() => {
										this.props.global.setLoading(false)
									}
								)
							})
					})
					.catch((error) => {
						this.props.global.setLoading(false)
						toast.error('Error creating paypoint', {
							position: toast.POSITION.BOTTOM_RIGHT,
							className: 'toast-error-container'
						})
					})
	}

	onFileChange(event) {
		const div = document.getElementById(event.target.name)
		const currentFileSize = this.state.filesSize + event.target.files[0].size - this.state.lastFileSize
		if (currentFileSize > 31457280) {
			event.target.value = null
			this.setState({ fileExceededSize: true })
		} else {
			if (event.target.files.length > 0) {
				div.classList.remove('input-error')
				this.props.boarding.setErrorData(event.target.name, false)
				this.props.boarding.setTemplateData(event.target.name, event.target.files[0])
			} else {
				div.classList.add('input-error')
				this.props.boarding.setErrorData(event.target.name, true)
				this.props.boarding.setTemplateData(event.target.name, null)
			}
		}
	}

	bSameaddresshandleChange(checked) {
		this.setState({ bsameaddress: checked.target.checked }, function () {
			let template = this.state.template_data
			let baseTemplate = this.state.baseTemplate
			if (this.state.bsameaddress) {
				if (this.props.boarding.template_data.baddress) {
					template.mAddress1 = this.props.boarding.template_data.baddress.value
					this.props.boarding.setTemplateData('maddress', this.props.boarding.template_data.baddress.value)
					baseTemplate.businessData.maddress.readOnly = true
					this.props.boarding.setErrorData('maddress', false)
				}
				if (this.props.boarding.template_data.baddress1) {
					template.mAddress2 = this.props.boarding.template_data.baddress1.value
					this.props.boarding.setTemplateData('maddress1', this.props.boarding.template_data.baddress1.value)
					baseTemplate.businessData.maddress1.readOnly = true
					this.props.boarding.setErrorData('maddress1', false)
				}
				if (this.props.boarding.template_data.bcity) {
					template.mCity = this.props.boarding.template_data.bcity.value
					this.props.boarding.setTemplateData('mcity', this.props.boarding.template_data.bcity.value)
					baseTemplate.businessData.mcity.readOnly = true
					this.props.boarding.setErrorData('mcity', false)
				}
				if (this.props.boarding.template_data.bstate) {
					template.mState = this.props.boarding.template_data.bstate.value
					this.props.boarding.setTemplateData('mstate', this.props.boarding.template_data.bstate.value)
					baseTemplate.businessData.mstate.readOnly = true
					this.props.boarding.setErrorData('mstate', false)
				}
				if (this.props.boarding.template_data.bzip) {
					template.mZip = this.props.boarding.template_data.bzip.value
					this.props.boarding.setTemplateData('mzip', this.props.boarding.template_data.bzip.value)
					baseTemplate.businessData.mzip.readOnly = true
					this.props.boarding.setErrorData('mzip', false)
				}
				if (this.props.boarding.template_data.bcountry) {
					template.mCountry = this.props.boarding.template_data.bcountry.value
					this.props.boarding.setTemplateData('mcountry', this.props.boarding.template_data.bcountry.value)
					baseTemplate.businessData.mcountry.readOnly = true
					this.props.boarding.setErrorData('mcountry', false)
				}
			} else {
				if (this.props.boarding.template_data.maddress) {
					baseTemplate.businessData.maddress.readOnly = false
				}
				if (this.props.boarding.template_data.maddress1) {
					baseTemplate.businessData.maddress1.readOnly = false
				}
				if (this.props.boarding.template_data.mcity) {
					baseTemplate.businessData.mcity.readOnly = false
				}
				if (this.props.boarding.template_data.mstate) {
					baseTemplate.businessData.mstate.readOnly = false
				}
				if (this.props.boarding.template_data.mzip) {
					baseTemplate.businessData.mzip.readOnly = false
				}
				if (this.props.boarding.template_data.mcountry) {
					baseTemplate.businessData.mcountry.readOnly = false
				}
			}
			this.setState({
				template_data: template,
				baseTemplate: baseTemplate
			})
		})
	}

	async componentDidMount() {
		this.props.global.protect(this.props.history)
		const tiersResult = await getTiersDetailsGET()
		const tiersName = tiersResult?.data?.Records?.map((tier) => {
			return { value: tier.TierName?.toUpperCase(), text: tier.TierName?.toUpperCase() }
		})
		tiersName.unshift({ value: '', text: 'Select' })
		this.setState({ appId: this.props.match.params.id, tiersName: tiersName }, function () {
			this.handlerGetBaseLink()
		})
		this.props.boarding.clearTemplateData()
		this.props.boarding.clearErrorData()
	}

	setTab(event) {
		var cp = 0
		if (event === 'business') {
			cp = 0
		}
		if (event === 'owners') {
			cp = 1
		}
		if (event === 'processing') {
			cp = 2
		}
		if (event === 'services') {
			cp = 3
		}
		if (event === 'banking') {
			cp = 4
		}
		this.setState({ currentSection: event, currentPos: cp })
	}

	gotoNextTab(_n) {
		if (!this.props.boarding.isValidTab(this.state.currentPos, this.state.additionalOwners, this.state.additionalContacts)) {
			this.setState({ complete: false })
			this.props.boarding.setNextClicked(true)
		}
		let cmp = false
		if (_n === 5) {
			if (this.props.boarding.errors()) {
				this.setState({ complete: false })
				this.props.boarding.setNextClicked(true)
				toast.error('Please review the information required or with errors and try again.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				document.body.scrollTop = 0
				document.documentElement.scrollTop = 0
			}
		} else {
			if (_n === 5) _n = 4
			if (!this.props.boarding.errors()) cmp = true
			this.setState({ currentPos: _n, complete: cmp })
			switch (_n) {
				case 0:
					this.setState({
						currentSection: 'business',
						currentPos: _n
					})
					break
				case 1:
					this.setState({
						offOwn: false,
						currentSection: 'owners',
						currentPos: _n
					})
					break
				case 2:
					this.setState({
						offPrc: false,
						currentSection: 'processing',
						currentPos: _n
					})
					break
				case 3:
					this.setState({
						offSrv: false,
						currentSection: 'services',
						currentPos: _n
					})
					break
				case 4:
				default:
					this.setState({
						offDoc: false,
						currentSection: 'banking',
						currentPos: _n
					})
					break
			}
			this.props.boarding.setNextClicked(false)
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
		}
	}

	saveToComplete() {
		let percentage = 0
		for (var j = 0; j <= this.state.additionalOwners.length; j++) {
			if (this.props.boarding.template_data['ownerpercent_' + j]) percentage += parseInt(this.props.boarding.template_data['ownerpercent_' + j].value)
		}
		let tmp_data = this.props.boarding.template_data
		let percentage_proc = 0
		if (tmp_data['binperson']) percentage_proc += parseInt(tmp_data['binperson'].value)
		if (tmp_data['binphone']) percentage_proc += parseInt(tmp_data['binphone'].value)
		if (tmp_data['binweb']) percentage_proc += parseInt(tmp_data['binweb'].value)
		if (percentage_proc > 100) {
			toast.error('Combined total payments percent must be equal to or less than 100%!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (percentage > 100) {
			toast.error('Total ownership must be equal to or less than 100%!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else {
			this.props.global.setLoading(true)
			this.props.boarding
				.saveApplication(this.state.orgId, this.state.linkId, this.state.additionalOwners, this.state.additionalContacts, this.state.update, this.state.appId)
				.then((res) => {
					var addc = this.state.additionalContacts
					var naddc = []
					if (addc.length > 0) {
						for (var ci = 0; ci < addc.length; ci++) {
							let cx = addc[ci]
							if (cx !== 0) {
								naddc.push(cx)
							}
						}
					}
					var addo = this.state.additionalOwners
					var naddo = []
					if (addo.length > 0) {
						for (var oi = 0; oi < addo.length; oi++) {
							let ox = addo[oi]
							if (ox !== 0) {
								naddo.push(ox)
							}
						}
					}
					this.setState({
						appId: res.data.responseData,
						additionalContacts: naddc,
						additionalOwners: naddo
					})
					this.props.global.setLoading(false)
					toast.success('Application saved successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
				})
				.catch((err) => {
					var addc = this.state.additionalContacts
					var naddc = []
					if (addc.length > 0) {
						for (var ci = 0; ci < addc.length; ci++) {
							let cx = addc[ci]
							if (cx !== 0) {
								naddc.push(cx)
							}
						}
					}
					var addo = this.state.additionalOwners
					var naddo = []
					if (addo.length > 0) {
						for (var oi = 0; oi < addo.length; oi++) {
							let ox = addo[oi]
							if (ox !== 0) {
								naddo.push(ox)
							}
						}
					}
					this.setState({
						additionalContacts: naddc,
						additionalOwners: naddo
					})
					this.props.global.setLoading(false)
					toast.error(err?.response?.data?.responseText || 'Oops! something went wrong, please review and try again.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				})
		}
	}

	submitComplete() {
		let percentage = 0
		let checkAccepted = this.state.termsAccepted
		let allchecked = (arr) => arr.every((v) => v === true)
		let allValueSigner = (signers) => signers.every((s) => s && s.value !== '')
		const { name, ssn, phone, email, address, state, country, city, zip, acceptance } = this.state.signer
		for (var j = 0; j <= this.state.additionalOwners.length; j++) {
			if (this.props.boarding.template_data['ownerpercent_' + j]) percentage += parseInt(this.props.boarding.template_data['ownerpercent_' + j].value)
		}
		let tmp_data = this.props.boarding.template_data
		let percentage_proc = 0
		if (tmp_data['binperson']) percentage_proc += parseInt(tmp_data['binperson'].value)
		if (tmp_data['binphone']) percentage_proc += parseInt(tmp_data['binphone'].value)
		if (tmp_data['binweb']) percentage_proc += parseInt(tmp_data['binweb'].value)
		if (!allValueSigner([address, city, country, email, name, phone, ssn, state, zip])) {
			toast.error('The signer informations is incomplete.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (this.state.baseTemplate.documentsData.termsConditions && this.state.baseTemplate.documentsData.termsConditions.visible && !acceptance) {
			toast.error('The signer has not accepted the terms.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (percentage_proc > 100) {
			toast.error('Combined total payments percent must be equal to or less than 100%!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (percentage > 100) {
			toast.error('Total ownership must be equal to or less than 100%!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (this.props.boarding.errors()) {
			this.setState({ complete: false })
			toast.error('Please review the information required or with errors and try again.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (checkAccepted.length !== 0 && (!allchecked(checkAccepted) || !this.state.signer.acceptance)) {
			toast.error('You must click and review link(s) above before agreeing.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else {
			//submit app
			this.props.global.setLoading(true)
			this.props.boarding
				.saveApplication(this.state.orgId, this.state.linkId, this.state.additionalOwners, this.state.additionalContacts, true, this.state.appId)
				.then((res) => {
					var addc = this.state.additionalContacts
					var naddc = []
					if (addc.length > 0) {
						for (var ci = 0; ci < addc.length; ci++) {
							let cx = addc[ci]
							if (cx !== 0) {
								naddc.push(cx)
							}
						}
					}
					var addo = this.state.additionalOwners
					var naddo = []
					if (addo.length > 0) {
						for (var oi = 0; oi < addo.length; oi++) {
							let ox = addo[oi]
							if (ox !== 0) {
								naddo.push(ox)
							}
						}
					}
					toast.success('Application saved successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
					this.setState({
						appId: res.data.responseData,
						additionalContacts: naddc,
						additionalOwners: naddo
					})
					this.props.boarding
						.updateApplicationStatus(res.data.responseData, 1)
						.then((res) => {
							this.props.global.setLoading(false)
							this.props.history.push('/' + PayabliStorageManager.getEntryName() + '/boarding/applications')
						})
						.catch(() => {
							this.props.global.setLoading(false)
							this.props.history.push('/' + PayabliStorageManager.getEntryName() + '/boarding/applications')
						})
				})
				.catch((err) => {
					var addc = this.state.additionalContacts
					var naddc = []
					if (addc.length > 0) {
						for (var ci = 0; ci < addc.length; ci++) {
							let cx = addc[ci]
							if (cx !== 0) {
								naddc.push(cx)
							}
						}
					}
					var addo = this.state.additionalOwners
					var naddo = []
					if (addo.length > 0) {
						for (var oi = 0; oi < addo.length; oi++) {
							let ox = addo[oi]
							if (ox !== 0) {
								naddo.push(ox)
							}
						}
					}
					this.setState({
						additionalContacts: naddc,
						additionalOwners: naddo
					})
					this.props.global.setLoading(false)
					toast.error('Oops! something went wrong, please review and try again.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				})
		}
	}

	closeEndLinkModal() {
		if (this.state.endLinkModalIsOpen) {
			window.location.reload()
		}
	}

	addOwnerContacts() {}

	removeOwner(k) {
		if (this.state.additionalOwners.length === 0) return
		let f = this.state.additionalOwners
		let idx = -1
		let g = this.state.lastowner - 1
		for (var i = 0; i < this.state.additionalOwners.length; i++) {
			if (this.state.additionalOwners[i] === k) {
				idx = i
			}
		}
		if (idx >= 0) {
			f.splice(idx, 1)
			this.setState({ additionalOwners: f, lastowner: g })
		}
	}

	removeDoc(k) {
		if (this.state.additionalFiles.length === 0) return
		let f = this.state.additionalFiles
		let idx = -1
		for (var i = 0; i < this.state.additionalFiles.length; i++) {
			if (this.state.additionalFiles[i] === k) {
				idx = i
			}
		}
		if (idx >= 0) {
			this.props.boarding.setErrorData('doc_' + this.state.additionalFiles[idx], false)
			this.props.boarding.setTemplateData('doc_' + this.state.additionalFiles[idx], null)
			f.splice(idx, 1)
			this.setState({ additionalFiles: f })
		}
	}

	addOwner() {
		if (!this.state.baseTemplate) return
		let f = this.state.additionalOwners
		let g = this.state.lastowner + 1
		f.push(g)
		this.setState({ additionalOwners: f, lastowner: g })
	}

	addContact() {
		if (!this.state.baseTemplate) return
		let f = this.state.additionalContacts
		let g = this.state.lastcontact + 1
		f.push(g)
		this.setState({ additionalContacts: f, lastcontact: g })
	}

	addDoc() {
		if (!this.state.baseTemplate) return
		let f = this.state.additionalFiles
		let g = this.state.lastfile + 1
		f.push(g)
		this.setState({ additionalFiles: f, lastfile: g })
	}

	removeContact(k) {
		if (this.state.additionalContacts.length === 0) return
		let f = this.state.additionalContacts
		let idx = -1
		for (var i = 0; i < this.state.additionalContacts.length; i++) {
			if (this.state.additionalContacts[i] === k) {
				idx = i
			}
		}
		if (idx >= 0) {
			f.splice(idx, 1)
			this.setState({ additionalContacts: f })
		}
	}

	setSignerDOB(k, e, field) {
		let signer = this.state.signer ? this.state.signer : {}
		signer.dob = e
		this.setState(
			{
				signer: signer
			},
			() => {
				this.props.boarding.setTemplateData('signer_dob', e)
			}
		)
	}

	getSignerBlock() {
		let signers = []
		if (this.props.boarding.template_data['ownername_0']) {
			signers.push({
				contactName: this.props.boarding.template_data['ownername_0'] ? this.props.boarding.template_data['ownername_0'].value : '',
				contactEmail: this.props.boarding.template_data['owneremail_0'] ? this.props.boarding.template_data['owneremail_0'].value : '',
				contactPhone: this.props.boarding.template_data['ownerphone1_0'] ? this.props.boarding.template_data['ownerphone1_0'].value : '',
				contactDOB: this.props.boarding.template_data['ownerdob_0'] ? this.props.boarding.template_data['ownerdob_0'].value : '',
				contactCountry: this.props.boarding.template_data['ocountry_0'] ? this.props.boarding.template_data['ocountry_0'].value : '',
				contactAddress: this.props.boarding.template_data['oaddress_0'] ? this.props.boarding.template_data['oaddress_0'].value : '',
				contactAddress2: this.props.boarding.template_data['oaddress2_0'] ? this.props.boarding.template_data['oaddress2_0'].value : '',
				contactCity: this.props.boarding.template_data['ocity_0'] ? this.props.boarding.template_data['ocity_0'].value : '',
				contactState: this.props.boarding.template_data['ostate_0'] ? this.props.boarding.template_data['ostate_0'].value : '',
				contactZip: this.props.boarding.template_data['ozip_0'] ? this.props.boarding.template_data['ozip_0'].value : '',
				contactSSN: this.props.boarding.template_data['ownerssn_0'] ? this.props.boarding.template_data['ownerssn_0'].value : ''
			})
		}

		this.state.additionalOwners.forEach((element) => {
			if (this.props.boarding.template_data['ownername_' + element]) {
				signers.push({
					contactName: this.props.boarding.template_data['ownername_' + element] ? this.props.boarding.template_data['ownername_' + element].value : '',
					contactEmail: this.props.boarding.template_data['owneremail_' + element] ? this.props.boarding.template_data['owneremail_' + element].value : '',
					contactPhone: this.props.boarding.template_data['ownerphone1_' + element] ? this.props.boarding.template_data['ownerphone1_' + element].value : '',
					contactDOB: this.props.boarding.template_data['ownerdob_' + element] ? this.props.boarding.template_data['ownerdob_' + element].value : '',
					contactSSN: this.props.boarding.template_data['ownerssn_' + element] ? this.props.boarding.template_data['ownerssn_' + element].value : '',
					contactCountry: this.props.boarding.template_data['ocountry_' + element] ? this.props.boarding.template_data['ocountry_' + element].value : '',
					contactAddress: this.props.boarding.template_data['oaddress_' + element] ? this.props.boarding.template_data['oaddress_' + element].value : '',
					contactAddress2: this.props.boarding.template_data['oaddress2_' + element] ? this.props.boarding.template_data['oaddress2_' + element].value : '',
					contactCity: this.props.boarding.template_data['ocity_' + element] ? this.props.boarding.template_data['ocity_' + element].value : '',
					contactState: this.props.boarding.template_data['ostate_' + element] ? this.props.boarding.template_data['ostate_' + element].value : '',
					contactZip: this.props.boarding.template_data['ozip_' + element] ? this.props.boarding.template_data['ozip_' + element].value : ''
				})
			}
		})

		if (this.props.boarding.template_data['contactName_0']) {
			signers.push({
				contactName: this.props.boarding.template_data['contactName_0'] ? this.props.boarding.template_data['contactName_0'].value : '',
				contactEmail: this.props.boarding.template_data['contactEmail_0'] ? this.props.boarding.template_data['contactEmail_0'].value : '',
				contactPhone: this.props.boarding.template_data['contactPhone_0'] ? this.props.boarding.template_data['contactPhone_0'].value : ''
			})
		}

		this.state.additionalContacts.forEach((element) => {
			if (this.props.boarding.template_data['contactName_' + element]) {
				signers.push({
					contactName: this.props.boarding.template_data['contactName_' + element] ? this.props.boarding.template_data['contactName_' + element].value : '',
					contactEmail: this.props.boarding.template_data['contactEmail_' + element] ? this.props.boarding.template_data['contactEmail_' + element].value : '',
					contactPhone: this.props.boarding.template_data['contactPhone_' + element] ? this.props.boarding.template_data['contactPhone_' + element].value : ''
				})
			}
		})

		this.OwnerTypeChange(this.props.boarding.template_data?.btype?.value)
		return (
			<>
				<h5 className="mt-5">Signer</h5>
				<p className="small mb-4 dark-grey">Please select which of the Owners or Contacts will be signing the application. You may also manually add a non-owner signer if desired.</p>
				<div className="row">
					{this.props.boarding.template_data?.btype?.value !== 'Non-Profit Org' && (
						<div className="col-12 mb-4">
							<div className="form-floating">
								<select
									onChange={(e) => this.selectContactAsSigner(e)}
									className="form-select form-control"
									id="selectContactAsSigner">
									<option value=""> Add Signer information manually.... </option>
									{signers.map((record, i) => (
										<option
											key={i}
											value={JSON.stringify(record)}>
											{record.contactName}
										</option>
									))}
								</select>
								<label htmlFor="selectContactAsSigner">Owner or Contact as signer</label>
							</div>
						</div>
					)}
					<div className="col-sm-12">
						<InputSimpleLink
							iTitle="Name"
							iName="signer_name"
							iValue={this.state.signer && this.state.signer.name ? this.state.signer.name : ''}
							iValidator={this.props.global.getValidator().text}
							full
						/>
					</div>
					<div className="col-sm-6">
						<InputSimpleLink
							iHide={true}
							iMask="ssn"
							iTitle="Social Security Number"
							iName="signer_ssn"
							iValue={this.state.signer && this.state.signer.ssn ? this.state.signer.ssn : ''}
							iValidator={this.props.global.getValidator().ssn}
							full
						/>
					</div>
					<InputCalendarLink
						iChangeCallback={this.setSignerDOB}
						iKey={0}
						iField="signer_dob"
						iMax={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())}
						iTitle="Date of Birth"
						iName={'signer_dob'}
						iValue={this.state.signer.dob ? this.props.global.getDateFromValue(this.state.signer.dob) : new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())}
						full
					/>
					<div className="col-sm-6">
						<PhoneInput
							iMask="phone"
							iTitle="Phone"
							iName="signer_phone"
							iValue={this.state.signer && this.state.signer.phone ? this.state.signer.phone : ''}
							iValidator={this.props.global.getValidator().phone}
							full
						/>
					</div>
					<div className="col-sm-6">
						<InputSimpleLink
							iMask="email"
							iTitle="Email"
							iName="signer_email"
							iValue={this.state.signer && this.state.signer.email ? this.state.signer.email : ''}
							iValidator={this.props.global.getValidator().email}
							full
						/>
					</div>
					<LocationSearchInput
						placeholder="Signer Address"
						customSize="col-md-6 mb-3"
						withCountry="true"
						withoutRow="true"
						onChange={this.handleGoogleAutocompleteSignerAddress}
						handleTextChange={this.handleTextChangeMask}
						address={this.state.signer_address || this.state.signer.address}
						address2={this.state.signer_address2 || this.state.signer.address1}
						city={this.state.signer_city || this.state.signer.city}
						state={this.state.signer_state || this.state.signer.state}
						zipcode={this.state.signer_zip || this.state.signer.zip}
						country={this.state.signer_country || this.state.signer.country}
						handleTextChangeKeys={['signer_address', 'signer_address2', 'signer_city', 'signer_state', 'signer_zip', 'signer_country']}
					/>
				</div>
			</>
		)
	}

	onCopyAddress2Business(e, k) {
		this.setState({
			['oaddress_' + k]: this.props.boarding.template_data['baddress'].value,
			['ocity_' + k]: this.props.boarding.template_data['bcity'].value,
			['ozip_' + k]: this.props.boarding.template_data['bzip'].value,
			['ostate_' + k]: this.props.boarding.template_data['bstate'].value,
			['ocountry_' + k]: this.props.boarding.template_data['bcountry'].value
		})
	}
	onCopySigner2Owner() {
		const ownerNonProfit = {
			ownername: this.props.boarding.template_data?.signer_name?.value || '',
			ownerssn: this.props.boarding.template_data?.signer_ssn?.value || '',
			ownerdob: this.props.boarding.template_data?.signer_dob?.value || '',
			ownerphone1: this.props.boarding.template_data?.signer_phone?.value || '',
			owneremail: this.props.boarding.template_data?.signer_email?.value || '',
			oaddress: this.props.boarding.template_data?.signer_address?.value || '',
			oaddress1: this.props.boarding.template_data?.signer_address1?.value || '',
			ostate: this.props.boarding.template_data?.signer_state?.value || '',
			ocountry: this.props.boarding.template_data?.signer_country?.value || '',
			ocity: this.props.boarding.template_data?.signer_city?.value || '',
			ozip: this.props.boarding.template_data?.signer_zip?.value || ''
		}
		Object.entries(ownerNonProfit).map((e) => this.props.boarding.setTemplateData(`${e[0]}_0`, e[1]))
	}
	getOwnerBlock(k) {
		this.OwnerTypeChange(this.props.boarding.template_data?.btype?.value)
		if (!this.state.baseTemplate.ownershipData || this.state.baseTemplate.ownershipData === null) return
		var rows = []

		if (this.state.baseTemplate.ownershipData.ownername && this.state.baseTemplate.ownershipData.ownername !== null) {
			if (this.state.baseTemplate.ownershipData.ownername.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownername.required) ? true : false}
						iTitle="Owner Name"
						iName={'ownername_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownername : ''}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Owner Name"
						iName={'ownername_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownername : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.ownertitle && this.state.baseTemplate.ownershipData.ownertitle !== null) {
			if (this.state.baseTemplate.ownershipData.ownertitle.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownertitle.required) ? true : false}
						iTooltip="Business Title / Role of the Owner"
						iTitle="Owner Title"
						iName={'ownertitle_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownertitle : ''}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="Business Title / Role of the Owner"
						iTitle="Owner Title"
						iName={'ownertitle_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownertitle : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.ownerpercent && this.state.baseTemplate.ownershipData.ownerpercent !== null) {
			if (this.state.baseTemplate.ownershipData.ownerpercent.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerpercent.required) ? true : false}
						iMask="percentage"
						iTitle="Ownership %"
						iName={'ownerpercent_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerpercent : ''}
						iValidator={this.props.global.getValidator().float}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Ownership %"
						iName={'ownerpercent_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerpercent : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.ownerssn && this.state.baseTemplate.ownershipData.ownerssn !== null) {
			if (this.state.baseTemplate.ownershipData.ownerssn.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerssn.required) ? true : false}
						iHide={true}
						iMask="ssn"
						iTooltip="Social Security Number of the Owner. Federal Law requires us to request this information"
						iTitle="Owner SSN"
						iName={'ownerssn_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerssn : ''}
						iValidator={this.props.global.getValidator().ssn}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iHide={true}
						iMask="ssn"
						iTooltip="Social Security Number of the Owner. Federal Law requires us to request this information"
						iTitle="Owner SSN"
						iName={'ownerssn_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerssn : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData && this.state.baseTemplate.ownershipData.ownerdob && this.state.baseTemplate.ownershipData.ownerdob !== null) {
			if (this.state.baseTemplate.ownershipData.ownerdob.readOnly === false) {
				rows.push(
					<InputCalendarLink
						iKey={k}
						iField={'ownerdob'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerdob.required) ? true : false}
						iMax={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())}
						iTitle="Date of Birth"
						iName={'ownerdob_' + k}
						iValue={this.state.baseData?.ownerData[k] ? this.props.global.getDateFromValue(this.state.baseData?.ownerData[k]?.ownerdob) : new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Date of Birth"
						iName={'ownerdob_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdob : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.ownerphone1 && this.state.baseTemplate.ownershipData.ownerphone1 !== null) {
			if (this.state.baseTemplate.ownershipData.ownerphone1.readOnly === false) {
				rows.push(
					<PhoneInput
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerphone1.required) ? true : false}
						iMask="phone"
						iTooltip="Direct Phone Number of the owner"
						iTitle="Phone Number"
						iName={'ownerphone1_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone1 : ''}
						iValidator={this.props.global.getValidator().phone}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="Direct Phone Number of the owner"
						iMask="phone"
						iTitle="Phone Number"
						iName={'ownerphone1_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone1 : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.ownerphone2 && this.state.baseTemplate.ownershipData.ownerphone2 !== null) {
			if (this.state.baseTemplate.ownershipData.ownerphone2.readOnly === false) {
				rows.push(
					<PhoneInput
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerphone2.required) ? true : false}
						iMask="phone"
						iTitle="Additional Phone Number"
						iName={'ownerphone2_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone2 : ''}
						iValidator={this.props.global.getValidator().phone}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iMask="phone"
						iTitle="Additional Phone Number"
						iName={'ownerphone2_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone2 : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.owneremail && this.state.baseTemplate.ownershipData.owneremail !== null) {
			if (this.state.baseTemplate.ownershipData.owneremail.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.owneremail.required) ? true : false}
						iMask="email"
						iTooltip="Direct Email of the Owner"
						iTitle="Email Address"
						iName={'owneremail_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].owneremail : ''}
						iValidator={this.props.global.getValidator().email}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iMask="email"
						iTooltip="Direct Email of the Owner"
						iTitle="Email Address"
						iName={'owneremail_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].owneremail : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.ownerdriver && this.state.baseTemplate.ownershipData.ownerdriver !== null) {
			if (this.state.baseTemplate.ownershipData.ownerdriver.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerdriver.required) ? true : false}
						iTitle="Driver's License #"
						iName={'ownerdriver_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdriver : ''}
						iValidator={this.props.global.getValidator().alphanumeric}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Driver's License #"
						iName={'ownerdriver_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdriver : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.odriverstate && this.state.baseTemplate.ownershipData.odriverstate !== null) {
			if (this.state.baseTemplate.ownershipData.odriverstate.readOnly === false) {
				rows.push(
					<InputSelectLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.odriverstate.required) ? true : false}
						iType="us_states"
						iTitle="Driver's State License"
						iName={'odriverstate_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].odriverstate : ''}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Driver's State License"
						iName={'odriverstate_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].odriverstate : ''}
					/>
				)
			}
		}

		let invisibles_fields = ['address2']
		rows.push(
			<LocationSearchInput
				placeholder="Address"
				customSize="col-md-6 mb-3"
				withCountry="true"
				withoutRow="true"
				onChange={this.handleGoogleAutocompleteOwnerAddress}
				handleTextChange={this.handleTextChangeMask}
				address={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].oaddress : ''}
				city={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocity : ''}
				state={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ostate : ''}
				zipcode={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ozip : ''}
				country={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocountry : ''}
				oindex={k}
				iInvisible={invisibles_fields}
				iTooltip="Residential Street Address of Owner. Federal Law requires us to request this information"
				// iLink="true"
				// iLinkClick={(e) => this.onCopyAddress2Business(e, k)}
				// iLinkText="Copy address from business details"
				handleTextChangeKeys={['oaddress_' + k, 'address2', 'ocity_' + k, 'ostate_' + k, 'ozip_' + k, 'ocountry_' + k]}
			/>
		)
		return <>{rows}</>
	}

	getContactBlock(k) {
		if (!this.state.baseTemplate.ownershipData) return
		if (this.state.baseTemplate.ownershipData === null) return
		var rows = []

		if (this.state.baseTemplate.ownershipData.contactName && this.state.baseTemplate.ownershipData.contactName !== null) {
			if (this.state.baseTemplate.ownershipData.contactName.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactName.required) ? true : false}
						iTitle="Contact Name"
						iName={'contactName_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactName : ''}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Contact Name"
						iName={'contactName_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactName : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.contactTitle && this.state.baseTemplate.ownershipData.contactTitle !== null) {
			if (this.state.baseTemplate.ownershipData.contactTitle.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactTitle.required) ? true : false}
						iTitle="Contact Title"
						iName={'contactTitle_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactTitle : ''}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Contact Title"
						iName={'contactTitle_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactTitle : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.contactEmail && this.state.baseTemplate.ownershipData.contactEmail !== null) {
			if (this.state.baseTemplate.ownershipData.contactEmail.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactEmail.required) ? true : false}
						iMask="email"
						iTitle="Contact Email"
						iName={'contactEmail_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactEmail : ''}
						iValidator={this.props.global.getValidator().email}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iMask="email"
						iTitle="Contact Email"
						iName={'contactEmail_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactEmail : ''}
					/>
				)
			}
		}
		if (this.state.baseTemplate.ownershipData.contactPhone && this.state.baseTemplate.ownershipData.contactPhone !== null) {
			if (this.state.baseTemplate.ownershipData.contactPhone.readOnly === false) {
				rows.push(
					<PhoneInput
						iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactPhone.required) ? true : false}
						iMask="phone"
						iTitle="Contact Phone"
						iName={'contactPhone_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactPhone : ''}
						iValidator={this.props.global.getValidator().phone}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iMask="phone"
						iTitle="Contact Phone"
						iName={'contactPhone_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactPhone : ''}
					/>
				)
			}
		}

		return <Row className="mt-4">{rows}</Row>
	}

	getDocumentBlock() {
		var rows = []
		var arows = []
		var files = []
		let zipfile = ''
		if (this.state.baseData.documentsRef && this.state.baseData.documentsRef !== null) {
			if (this.state.baseData.documentsRef.zipfile !== null) zipfile = <b>{'Documents stored in: ' + this.state.baseData.documentsRef.zipfile}</b>
			if (this.state.baseData.documentsRef.filelist !== null && this.state.baseData.documentsRef.filelist.length > 0) {
				for (var j = 0; j < this.state.baseData.documentsRef.filelist.length; j++) {
					files.push(
						<>
							<span className="text-success">{this.state.baseData.documentsRef.filelist[j].originalName}</span>
							<br />
						</>
					)
				}
			}
		}
		let x = parseInt(this.state.baseTemplate.documentsData.minimumDocuments, 10)
		if (files.length < x) {
			x = x - files.length
			for (let i = 0; i < x; i++) {
				rows.push(
					<div
						className="col-md-6 mt-2"
						key={i}>
						<input
							type="file"
							className="form-control input-error"
							id={'doc_' + i}
							name={'doc_' + i}
							onChange={this.onFileChange}
							accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain"
						/>
					</div>
				)
				if (!this.props.boarding.existsTemplateData('doc_' + i)) {
					this.props.boarding.setErrorData('doc_' + i, true)
					this.props.boarding.setTemplateData('doc_' + i, null)
				}
			}
		} else {
			x = 0
		}
		if (this.state.additionalFiles.length > 0) {
			for (let j = 0; j < this.state.additionalFiles.length; j++) {
				let f = this.state.additionalFiles[j]
				rows.push(
					<div
						className="col-md-6 mt-2"
						key={f}>
						<InputGroup>
							<input
								type="file"
								className="form-control input-error"
								id={'doc_' + f}
								name={'doc_' + f}
								onChange={this.onFileChange}
								accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain"
							/>
							<Button
								variant="danger"
								onClick={(e) => this.removeDoc(f)}>
								<BiTrash fontSize="1.5em" />
							</Button>
						</InputGroup>
					</div>
				)
				if (!this.props.boarding.existsTemplateData('doc_' + f)) {
					this.props.boarding.setErrorData('doc_' + f, true)
					this.props.boarding.setTemplateData('doc_' + f, null)
				}
			}
		}

		return (
			<>
				<Row className="mt-2">
					<div className="col-3">
						<p>{zipfile}</p>
					</div>
					<div className="col-9">{files}</div>
				</Row>
				<Row className="mt-2">
					{x > 0 && (
						<p className="small">
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip>To help our Underwriting Team get your merchant account ready the more information the better. Some recommended docs are: Current merchant services statements, Drivers License, Business license, Voided Check</Tooltip>}>
								<BiInfoCircle className="info-icon" />
							</OverlayTrigger>
							Please upload supporting documents below; at least 1 file is expected. Upload max is 30MB of any combination of .pdf .jpg or .png files.
						</p>
					)}
					{rows}
					{arows}
					<div
						className="col-md-6 mt-2"
						id="addFiles">
						<button
							className="btn bordered mt-1"
							style={{ margin: '0' }}
							onClick={(e) => this.addDoc()}>
							<BiPlus /> Add more documents
						</button>
					</div>
				</Row>
			</>
		)
	}
	onCopyLegalName(e) {
		this.setState({
			taxfillname: this.props.boarding.template_data.legalname.value
		})
	}
	getBusinessBlock() {
		var rows = []
		if (this.state.baseTemplate.businessData && this.state.baseTemplate.businessData !== null) {
			if (this.state.baseTemplate.businessData.legalname && this.state.baseTemplate.businessData.legalname !== null) {
				if (this.state.baseTemplate.businessData.legalname.readOnly === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.legalname.required) ? true : false}
							iTitle="Business Legal Name"
							iName="legalname"
							iValue={this.state.baseData.legalName ? this.state.baseData.legalName : ''}
							iValidator={this.props.global.getValidator().text}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="Business Legal Name"
							iName="legalname"
							iValue={this.state.baseData.legalName ? this.state.baseData.legalName : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.dbaname && this.state.baseTemplate.businessData.dbaname !== null) {
				if (this.state.baseTemplate.businessData.dbaname.readOnly === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.dbaname.required) ? true : false}
							iTooltip='This is your fictitious "doing business as" name that you may have registered to distinguish from your legal name'
							iTitle="Business DBA Name"
							iName="dbaname"
							iValue={this.state.baseData.dbaName ? this.state.baseData.dbaName : ''}
							iValidator={this.props.global.getValidator().text}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTooltip='This is your fictitious "doing business as" name that you may have registered to distinguish from your legal name'
							iTitle="Business DBA Name"
							iName="dbaname"
							iValue={this.state.baseData.dbaName ? this.state.baseData.dbaName : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.btype && this.state.baseTemplate.businessData.btype !== null) {
				if (this.state.baseTemplate.businessData.btype.readOnly === false) {
					rows.push(
						<InputSelectLink
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.btype.required) ? true : false}
							iType="btype"
							iTitle="Business Type"
							iName="btype"
							iValue={this.state.baseData.ownType ? this.state.baseData.ownType : ''}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="Business Type"
							iName="btype"
							iValue={this.state.baseData.ownType ? this.state.baseData.ownType : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.ein && this.state.baseTemplate.businessData.ein !== null) {
				if (this.state.baseTemplate.businessData.ein.readOnly === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.ein.required) ? true : false}
							iTooltip="Tax ID Number or Social Security Number for Sole Proprietor"
							iMask="ein"
							iTitle="Business EIN"
							iName="ein"
							iValue={this.state.baseData.ein ? this.state.baseData.ein : ''}
							iValidator={this.props.global.getValidator().ein}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTooltip="Tax ID Number or Social Security Number for Sole Proprietor"
							iMask="ein"
							iTitle="Business EIN"
							iName="ein"
							iValue={this.state.baseData.ein ? this.state.baseData.ein : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.taxfillname && this.state.baseTemplate.businessData.taxfillname !== null) {
				if (this.state.baseTemplate.businessData.taxfillname.readOnly === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.taxfillname.required) ? true : false}
							iTooltip="The combination of your Tax ID Number and your Tax Filing Name must match your IRS Documents"
							iTitle="Tax Filing Name"
							iName="taxfillname"
							iLink="true"
							iLinkClick={(e) => this.onCopyLegalName(e)}
							iLinkText="Tax Fill Name same as Business Legal Name"
							iValue={this.state.taxfillname ? this.state.taxfillname : this.state.baseData.taxfillname ? this.state.baseData.taxfillname : ''}
							iValidator={this.props.global.getValidator().text}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTooltip="The combination of your Tax ID Number and your Tax Filing Name must match your IRS Documents"
							iTitle="Tax Filing Name"
							iName="taxfillname"
							iValue={this.state.baseData.taxfillname ? this.state.baseData.taxfillname : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.license && this.state.baseTemplate.businessData.license !== null) {
				if (this.state.baseTemplate.businessData.license.readOnly === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.license.required) ? true : false}
							iTooltip="ID number for Government Issued Business License"
							iTitle="Business License"
							iName="license"
							iValue={this.state.baseData.license ? this.state.baseData.license : ''}
							iValidator={this.props.global.getValidator().alphanumeric}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTooltip="ID number for Government Issued Business License"
							iTitle="Business License"
							iName="license"
							iValue={this.state.baseData.license ? this.state.baseData.license : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.licstate && this.state.baseTemplate.businessData.licstate !== null) {
				if (this.state.baseTemplate.businessData.licstate.readOnly === false) {
					rows.push(
						<InputSelectLink
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.licstate.required) ? true : false}
							iTitle="License State"
							iName="licstate"
							iValue={this.state.baseData.licenseState ? this.state.baseData.licenseState : ''}
							iType="us_states"
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="License State"
							iName="licstate"
							iValue={this.state.baseData.licenseState ? this.state.baseData.licenseState : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.website && this.state.baseTemplate.businessData.website !== null) {
				if (this.state.baseTemplate.businessData.website.readOnly === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.website.required) ? true : false}
							iTitle="Website"
							iName="website"
							iValue={this.state.baseData.websiteAddress ? this.state.baseData.websiteAddress : ''}
							iValidator={this.props.global.getValidator().url}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="Website"
							iName="website"
							iValue={this.state.baseData.websiteAddress ? this.state.baseData.websiteAddress : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.startdate && this.state.baseTemplate.businessData.startdate !== null) {
				if (this.state.baseTemplate.businessData.startdate.readOnly === false) {
					rows.push(
						<InputCalendarLink
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.startdate.required) ? true : false}
							iTitle="Business Start Date"
							self={this}
							iKey={0}
							iField={'bStartdate'}
							iChangeCallback={this.setBusinessDataDate}
							iName="startdate"
							iValue={this.state.baseData.bStartdate ? this.props.global.getDateFromValue(this.state.baseData.bStartdate) : new Date()}
							iMax={new Date()}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="Business Start Date"
							iName="startdate"
							iValue={this.state.baseData.bStartdate ? this.state.baseData.bStartdate : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.phonenumber && this.state.baseTemplate.businessData.phonenumber !== null) {
				if (this.state.baseTemplate.businessData.phonenumber.readOnly === false) {
					rows.push(
						<PhoneInput
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.phonenumber.required) ? true : false}
							iMask="phone"
							iTooltip="Direct Phone Number of the owner"
							iTitle="Phone Number"
							iName="phonenumber"
							iValue={this.state.baseData.bPhone ? this.state.baseData.bPhone : ''}
							iValidator={this.props.global.getValidator().phone}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iMask="phone"
							iTooltip="Direct Phone Number of the owner"
							iTitle="Phone Number"
							iName="phonenumber"
							iValue={this.state.baseData.bPhone ? this.state.baseData.bPhone : ''}
						/>
					)
				}
			}
			if (this.state.baseTemplate.businessData.faxnumber && this.state.baseTemplate.businessData.faxnumber !== null) {
				if (this.state.baseTemplate.businessData.faxnumber.readOnly === false) {
					rows.push(
						<PhoneInput
							iRequired={[true, null].includes(this.state.baseTemplate.businessData.faxnumber.required) ? true : false}
							iMask="phone"
							iTitle="Fax Number"
							iName="faxnumber"
							iValue={this.state.baseData.bFax ? this.state.baseData.bFax : ''}
							iValidator={this.props.global.getValidator().phone}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iMask="phone"
							iTitle="Fax Number"
							iName="faxnumber"
							iValue={this.state.baseData.bFax ? this.state.baseData.bFax : ''}
						/>
					)
				}
			}
		}
		return <Row className="mt-4">{rows}</Row>
	}

	getBusinessAddressBlock() {
		var rows = []
		var mrows = []
		let required_fields = []
		let readonly_fields = []
		let invisibles_fields = []

		if (this.state.baseTemplate.businessData && this.state.baseTemplate.businessData !== null) {
			if (this.state.baseTemplate.businessData.baddress && this.state.baseTemplate.businessData.baddress !== null) {
				if (this.state.baseTemplate.businessData.baddress.readOnly === true) {
					readonly_fields.push('address')
				} else if ([true, null].includes(this.state.baseTemplate.businessData.baddress.required) ? true : false) {
					required_fields.push('address')
				}
			} else {
				invisibles_fields.push('address')
			}
			if (this.state.baseTemplate.businessData.baddress1 && this.state.baseTemplate.businessData.baddress1 !== null) {
				if (this.state.baseTemplate.businessData.baddress1.readOnly === true) {
					readonly_fields.push('address2')
				} else if ([true, null].includes(this.state.baseTemplate.businessData.baddress1.required) ? true : false) {
					required_fields.push('address2')
				}
			} else {
				invisibles_fields.push('address2')
			}
			if (this.state.baseTemplate.businessData.bcity && this.state.baseTemplate.businessData.bcity !== null) {
				if (this.state.baseTemplate.businessData.bcity.readOnly === true) {
					readonly_fields.push('city')
				} else if ([true, null].includes(this.state.baseTemplate.businessData.bcity.required) ? true : false) {
					required_fields.push('city')
				}
			} else {
				invisibles_fields.push('city')
			}
			if (this.state.baseTemplate.businessData.bstate && this.state.baseTemplate.businessData.bstate !== null) {
				if (this.state.baseTemplate.businessData.bstate.readOnly === true) {
					readonly_fields.push('state')
				} else if ([true, null].includes(this.state.baseTemplate.businessData.bstate.required) ? true : false) {
					required_fields.push('state')
				}
			} else {
				invisibles_fields.push('state')
			}
			if (this.state.baseTemplate.businessData.bzip && this.state.baseTemplate.businessData.bzip !== null) {
				if (this.state.baseTemplate.businessData.bzip.readOnly === true) {
					readonly_fields.push('zipcode')
				} else if ([true, null].includes(this.state.baseTemplate.businessData.bzip.required) ? true : false) {
					required_fields.push('zipcode')
				}
			} else {
				invisibles_fields.push('zipcode')
			}
			if (this.state.baseTemplate.businessData.bcountry && this.state.baseTemplate.businessData.bcountry !== null) {
				if (this.state.baseTemplate.businessData.bcountry.readOnly === true) {
					readonly_fields.push('country')
				} else if ([true, null].includes(this.state.baseTemplate.businessData.bcountry.required) ? true : false) {
					required_fields.push('country')
				}
			} else {
				invisibles_fields.push('country')
			}
			rows.push(
				<LocationSearchInput
					placeholder="Business Address"
					customSize="col-md-6 mb-3"
					withCountry="true"
					withoutRow="true"
					onChange={this.handleGoogleAutocompleteBusinessAddress}
					handleTextChange={this.handleTextChangeMask}
					address={this.state.baseData.bAddress1}
					address2={this.state.baseData.bAddress2}
					city={this.state.baseData.bCity}
					state={this.state.baseData.bState}
					zipcode={this.state.baseData.bZip}
					country={this.state.baseData.bCountry}
					iRequired={required_fields}
					iReadonly={readonly_fields}
					iInvisible={invisibles_fields}
					iTooltip="Where the business is located"
					handleTextChangeKeys={['baddress', 'baddress1', 'bcity', 'bstate', 'bzip', 'bcountry']}
				/>
			)
			if (
				(this.state.baseTemplate.businessData.baddress !== null || this.state.baseTemplate.businessData.baddress1 !== null || this.state.baseTemplate.businessData.bcity !== null || this.state.baseTemplate.businessData.bstate !== null || this.state.baseTemplate.businessData.bzip !== null || this.state.baseTemplate.businessData.bcountry !== null) &&
				(this.state.baseTemplate.businessData.maddress !== null || this.state.baseTemplate.businessData.maddress1 !== null || this.state.baseTemplate.businessData.mcity !== null || this.state.baseTemplate.businessData.mstate !== null || this.state.baseTemplate.businessData.mzip !== null || this.state.baseTemplate.businessData.mcountry !== null)
			) {
				mrows.push(
					<div className="col-12 text-left mb-3">
						<div className="icheck-primary">
							<input
								checked={this.state.bsameaddress}
								type="checkbox"
								name="b_sameaddress"
								id="b_sameaddress"
								onChange={this.bSameaddresshandleChange}
							/>
							<label htmlFor="b_sameaddress">Mailing Address same as Business Address</label>
						</div>
					</div>
				)
			}
			if (!this.state.bsameaddress) {
				let required_fields = []
				let readonly_fields = []
				let invisibles_fields = []
				if (this.state.baseTemplate.businessData.maddress && this.state.baseTemplate.businessData.maddress !== null) {
					if (this.state.baseTemplate.businessData.maddress.readOnly === true) {
						readonly_fields.push('address')
					} else if ([true, null].includes(this.state.baseTemplate.businessData.maddress.required) ? true : false) {
						required_fields.push('address')
					}
				} else {
					invisibles_fields.push('address')
				}

				if (this.state.baseTemplate.businessData.maddress1 && this.state.baseTemplate.businessData.maddress1 !== null) {
					if (this.state.baseTemplate.businessData.maddress1.readOnly === true) {
						readonly_fields.push('address2')
					} else if ([true, null].includes(this.state.baseTemplate.businessData.maddress1.required) ? true : false) {
						required_fields.push('address2')
					}
				} else {
					invisibles_fields.push('address2')
				}
				if (this.state.baseTemplate.businessData.mcity && this.state.baseTemplate.businessData.mcity !== null) {
					if (this.state.baseTemplate.businessData.mcity.readOnly === true) {
						readonly_fields.push('city')
					} else if ([true, null].includes(this.state.baseTemplate.businessData.mcity.required) ? true : false) {
						required_fields.push('city')
					}
				} else {
					invisibles_fields.push('city')
				}
				if (this.state.baseTemplate.businessData.mstate && this.state.baseTemplate.businessData.mstate !== null) {
					if (this.state.baseTemplate.businessData.mstate.readOnly === true) {
						readonly_fields.push('state')
					} else if ([true, null].includes(this.state.baseTemplate.businessData.mstate.required) ? true : false) {
						required_fields.push('state')
					}
				} else {
					invisibles_fields.push('state')
				}
				if (this.state.baseTemplate.businessData.mzip && this.state.baseTemplate.businessData.mzip !== null) {
					if (this.state.baseTemplate.businessData.mzip.readOnly === true) {
						readonly_fields.push('zipcode')
					} else if ([true, null].includes(this.state.baseTemplate.businessData.mzip.required) ? true : false) {
						required_fields.push('zipcode')
					}
				} else {
					invisibles_fields.push('zipcode')
				}
				if (this.state.baseTemplate.businessData.mcountry && this.state.baseTemplate.businessData.mcountry !== null) {
					if (this.state.baseTemplate.businessData.mcountry.readOnly === true) {
						readonly_fields.push('country')
					} else if ([true, null].includes(this.state.baseTemplate.businessData.mcountry.required) ? true : false) {
						required_fields.push('country')
					}
				} else {
					invisibles_fields.push('country')
				}
				mrows.push(
					<LocationSearchInput
						placeholder="Mailing Address"
						customSize="col-md-6 mb-3"
						withCountry="true"
						withoutRow="true"
						onChange={this.handleGoogleAutocompleteBusinessMAddress}
						handleTextChange={this.handleTextChangeMask}
						address={this.state.baseData.mAddress1}
						address2={this.state.baseData.mAddress2}
						city={this.state.baseData.mCity}
						state={this.state.baseData.mState}
						zipcode={this.state.baseData.mZip}
						country={this.state.baseData.mCountry}
						iRequired={required_fields}
						iReadonly={readonly_fields}
						iInvisible={invisibles_fields}
						handleTextChangeKeys={['maddress', 'maddress1', 'mcity', 'mstate', 'mzip', 'mcountry']}
					/>
				)
			}
		}
		return (
			<>
				<Row className="mt-2">{rows}</Row>
				<hr />
				<Row className="mt-2">{mrows}</Row>
			</>
		)
	}

	getProcessingBlock() {
		if (this.state.baseTemplate.processingData === null) return
		var rows = []

		if (this.state.baseTemplate.processingData.mcc && this.state.baseTemplate.processingData.mcc !== null) {
			if (this.state.baseTemplate.processingData.mcc.readOnly === false) {
				rows.push(
					<>
						<InputSelectSearchable
							iRequired={[true, null].includes(this.state.baseTemplate.processingData.mcc.required) ? true : false}
							iTitle="Industry (Merchant Category Code)"
							iName="mcc"
							iValue={this.state.baseData.mccid}
							iValidator={this.props.global.getValidator().text}
						/>
					</>
				)
			} else {
				rows.push(
					<InputSelectSearchable
						iReadonly={true}
						iTitle="Industry (Merchant Category Code)"
						iName="mcc"
						iValue={this.state.baseData.mccid}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.bsummary && this.state.baseTemplate.processingData.bsummary !== null) {
			if (this.state.baseTemplate.processingData.bsummary.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.bsummary.required) ? true : false}
						iTooltip="Please provide some background on your Business"
						iTitle="Tell us about your Business"
						iName="bsummary"
						iValue={this.state.baseData.bSummary}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="Please provide some background on your Business"
						iTitle="Tell us about your Business"
						iName="bsummary"
						iValue={this.state.baseData.bSummary}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.binperson && this.state.baseTemplate.processingData.binperson !== null) {
			if (this.state.baseTemplate.processingData.binperson.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iMask="percentage"
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.binperson.required) ? true : false}
						iType="s100x10"
						iTitle="Percent of payments in Person"
						iName="binperson"
						iValue={this.state.baseData.binPerson || '0'}
						iValidator={this.props.global.getValidator().float}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Percent of payments in Person"
						iName="binperson"
						iValue={this.state.baseData.binPerson}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.binphone && this.state.baseTemplate.processingData.binphone !== null) {
			if (this.state.baseTemplate.processingData.binphone.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iMask="percentage"
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.binphone.required) ? true : false}
						iType="s100x10"
						iTitle="Percent of payments by Phone"
						iName="binphone"
						iValue={this.state.baseData.binPhone || '0'}
						iValidator={this.props.global.getValidator().float}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Percent of payments by Phone"
						iName="binphone"
						iValue={this.state.baseData.binPhone}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.binweb && this.state.baseTemplate.processingData.binweb !== null) {
			if (this.state.baseTemplate.processingData.binweb.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iMask="percentage"
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.binweb.required) ? true : false}
						iType="s100x10"
						iTitle="Percent of payments online"
						iName="binweb"
						iValue={this.state.baseData.binWeb || '0'}
						iValidator={this.props.global.getValidator().float}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Percent of payments online"
						iName="binweb"
						iValue={this.state.baseData.binWeb}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.avgmonthly && this.state.baseTemplate.processingData.avgmonthly !== null) {
			if (this.state.baseTemplate.processingData.avgmonthly.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.avgmonthly.required) ? true : false}
						iTooltip="How much credit card volume do you anticipate"
						iMask="money"
						iTitle="Average Monthly Volume"
						iName="avgmonthly"
						iValue={this.state.baseData.averageMonthlyVolume}
						iValidator={this.props.global.getValidator().moneynonzero}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="How much credit card volume do you anticipate"
						iMask="money"
						iTitle="Average Monthly Volume"
						iName="avgmonthly"
						iValue={this.state.baseData.averageMonthlyVolume}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.ticketamt && this.state.baseTemplate.processingData.ticketamt !== null) {
			if (this.state.baseTemplate.processingData.ticketamt.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.ticketamt.required) ? true : false}
						iTooltip="What is the average transaction size that you charge your customers"
						iMask="money"
						iTitle="Average Ticket Amount"
						iName="ticketamt"
						iValue={this.state.baseData.averageTicketAmount}
						iValidator={this.props.global.getValidator().moneynonzero}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="What is the average transaction size that you charge your customers"
						iMask="money"
						iTitle="Average Ticket Amount"
						iName="ticketamt"
						iValue={this.state.baseData.averageTicketAmount}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.highticketamt && this.state.baseTemplate.processingData.highticketamt !== null) {
			if (this.state.baseTemplate.processingData.highticketamt.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.highticketamt.required) ? true : false}
						iTooltip="What is the maximum transaction size you charge your customers?"
						iMask="money"
						iTitle="High Ticket Amount"
						iName="highticketamt"
						iValue={this.state.baseData.highTicketAmount}
						iValidator={this.props.global.getValidator().moneynonzero}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="What is the maximum transaction size you charge your customers?"
						iMask="money"
						iTitle="High Ticket Amount"
						iName="highticketamt"
						iValue={this.state.baseData.highTicketAmount}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.annualRevenue && this.state.baseTemplate.processingData.annualRevenue !== null) {
			if (this.state.baseTemplate.processingData.annualRevenue.readOnly === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.annualRevenue.required) ? true : false}
						iTooltip="What is the annual revenue volumen?"
						iMask="money"
						iTitle="Annual Revenue Volume"
						iName="annualRevenue"
						iValue={this.state.baseData.annualRevenue}
						iValidator={this.props.global.getValidator().moneynonzero}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="What is the annual revenue volumen?"
						iMask="money"
						iTitle="Annual Revenue Volume"
						iName="annualRevenue"
						iValue={this.state.baseData.annualRevenue}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.whenCharged && this.state.baseTemplate.processingData.whenCharged !== null) {
			if (this.state.baseTemplate.processingData.whenCharged.readOnly === false) {
				rows.push(
					<InputSelectLink
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.whenCharged.required) ? true : false}
						iType="whencharged"
						iTitle="When is Payment Charged"
						iName="whenCharged"
						iValue={this.state.baseData.whencharged}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="When is Payment Charged"
						iName="whenCharged"
						iValue={this.state.baseData.whencharged}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.whenProvided && this.state.baseTemplate.processingData.whenProvided !== null) {
			if (this.state.baseTemplate.processingData.whenProvided.readOnly === false) {
				rows.push(
					<InputSelectLink
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.whenProvided.required) ? true : false}
						iType="whenprovided"
						iTitle="Service Generally Provided In"
						iName="whenProvided"
						iValue={this.state.baseData.whenprovided}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Service Generally Provided In"
						iName="whenProvided"
						iValue={this.state.baseData.whenprovided}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.whenDelivered && this.state.baseTemplate.processingData.whenDelivered !== null) {
			if (this.state.baseTemplate.processingData.whenDelivered.readOnly === false) {
				rows.push(
					<InputSelectLink
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.whenDelivered.required) ? true : false}
						iType="whendelivered"
						iTitle="Products/Services Delivered In"
						iName="whenDelivered"
						iValue={this.state.baseData.whendelivered}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Products/Services Delivered In"
						iName="whenDelivered"
						iValue={this.state.baseData.whendelivered}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processingData.whenRefunded && this.state.baseTemplate.processingData.whenRefunded !== null) {
			if (this.state.baseTemplate.processingData.whenRefunded.readOnly === false) {
				rows.push(
					<InputSelectLink
						iRequired={[true, null].includes(this.state.baseTemplate.processingData.whenRefunded.required) ? true : false}
						iType="whenrefunded"
						iTitle="Refund Policy"
						iName="whenRefunded"
						iValue={this.state.baseData.whenrefund}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Refund Policy"
						iName="whenRefunded"
						iValue={this.state.baseData.whenrefund}
					/>
				)
			}
		}
		return <Row className="mt-4">{rows}</Row>
	}

	getCardAcceptance() {
		if (this.state.baseTemplate.servicesData.card.cardAcceptance === null || this.state.baseTemplate.servicesData.card.cardAcceptance.types === null || this.state.baseData.serviceData === null) return
		var rows = []
		if (this.state.baseTemplate.servicesData.card.cardAcceptance.types.visa && this.state.baseTemplate.servicesData.card.cardAcceptance.types.visa !== null) {
			if (this.state.baseTemplate.servicesData.card.cardAcceptance.types.visa.readOnly === false) {
				rows.push(
					<CheckLink
						iInvisible={true}
						iTitle="Visa"
						iName="card_visa"
						iValue={this.state.baseData.serviceData.card.acceptVisa}
					/>
				)
			} else {
				rows.push(
					<ReadOnlyCheckLink
						iInvisible={true}
						iTitle="Visa"
						iName="card_visa"
						iValue={this.state.baseData.serviceData.card.acceptVisa}
					/>
				)
			}
		}
		if (this.state.baseTemplate.servicesData.card.cardAcceptance.types.masterCard && this.state.baseTemplate.servicesData.card.cardAcceptance.types.masterCard !== null) {
			if (this.state.baseTemplate.servicesData.card.cardAcceptance.types.masterCard.readOnly === false) {
				rows.push(
					<CheckLink
						iInvisible={true}
						iTitle="Mastercard"
						iName="card_mastercard"
						iValue={this.state.baseData.serviceData.card.acceptMastercard}
					/>
				)
			} else {
				rows.push(
					<ReadOnlyCheckLink
						iInvisible={true}
						iTitle="Mastercard"
						iName="card_mastercard"
						iValue={this.state.baseData.serviceData.card.acceptMastercard}
					/>
				)
			}
		}
		if (this.state.baseTemplate.servicesData.card.cardAcceptance.types.discover && this.state.baseTemplate.servicesData.card.cardAcceptance.types.discover !== null) {
			if (this.state.baseTemplate.servicesData.card.cardAcceptance.types.discover.readOnly === false) {
				rows.push(
					<CheckLink
						iInvisible={true}
						iTitle="Discover"
						iName="card_discover"
						iValue={this.state.baseData.serviceData.card.acceptDiscover}
					/>
				)
			} else {
				rows.push(
					<ReadOnlyCheckLink
						iInvisible={true}
						iTitle="Discover"
						iName="card_discover"
						iValue={this.state.baseData.serviceData.card.acceptDiscover}
					/>
				)
			}
		}
		if (this.state.baseTemplate.servicesData.card.cardAcceptance.types.amex && this.state.baseTemplate.servicesData.card.cardAcceptance.types.amex !== null) {
			if (this.state.baseTemplate.servicesData.card.cardAcceptance.types.amex.readOnly === false) {
				rows.push(
					<CheckLink
						iInvisible={true}
						iTitle="Amex"
						iName="card_amex"
						iValue={this.state.baseData.serviceData.card.acceptDiscover}
					/>
				)
			} else {
				rows.push(
					<ReadOnlyCheckLink
						iInvisible={true}
						iTitle="Amex"
						iName="card_amex"
						iValue={this.state.baseData.serviceData.card.acceptAmex}
					/>
				)
			}
		}
		return (
			<Row className="mt-2 mb-3">
				<h6 className="mb-2">Card Acceptance</h6>
				<br />
				<label className="mb-2 small">Integrated per transaction fee for card acceptance.</label>
				<br />
				{rows}
			</Row>
		)
	}

	capitalize([first, ...rest]) {
		return first.toUpperCase() + rest.join('').toLowerCase()
	}

	getCardPricing() {
		if (this.state.baseTemplate.servicesData.card.pricingType === null || !this.state.baseTemplate.servicesData.card.pricingType.value) return
		if (this.state.baseTemplate.servicesData.card.pricingType.value === '1') {
			return (
				<>
					{/* <strong>IC+ Card Price</strong> */}
					{this.getCardPricingICPlus()}
				</>
			)
		} else if (this.state.baseTemplate.servicesData.card.pricingType.value === '2') {
			return (
				<>
					{/* <strong>Flat Card Price</strong> */}
					{this.getCardPricingFlat()}
				</>
			)
		} else if (this.state.baseTemplate.servicesData.card.pricingType.value === '3') {
			return (
				<>
					{/* <strong>Pass-Through Card Price</strong> */}
					{this.getCardPricingPassThrought()}
				</>
			)
		} else if (this.state.baseTemplate.servicesData.card.pricingType.value === '4') {
			return (
				<>
					{/* <strong>Flat Card Price</strong> */}
					{this.getCardPricingFlat()}
					{/* <strong className="mt-3">Pass-Through Card Price</strong> */}
					{this.getCardPricingPassThrought()}
				</>
			)
		}
	}

	getAchPricing() {
		if (this.state.baseTemplate.servicesData.ach.pricingType === null || !this.state.baseTemplate.servicesData.ach.pricingType.value) return
		if (this.state.baseTemplate.servicesData.ach.pricingType.value === '5') {
			return (
				<>
					{/* <strong>Absorb ACH Price</strong> */}
					{this.getAchPricingAbsorb()}
				</>
			)
		} else if (this.state.baseTemplate.servicesData.ach.pricingType.value === '3') {
			return (
				<>
					{/* <strong>Pass-Through ACH Price</strong> */}
					{this.getAchPricingPassThrough()}
				</>
			)
		} else if (this.state.baseTemplate.servicesData.ach.pricingType.value === '6') {
			return (
				<>
					{/* <strong>Absorb ACH Price</strong> */}
					{this.getAchPricingAbsorb()}
					{/* <strong className="mt-3">Pass-Through ACH Price</strong> */}
					{this.getAchPricingPassThrough()}
				</>
			)
		}
	}

	getFeeName(code) {
		const names = {
			transactionCardFee: 'per Transaction',
			chargebackCardFee: 'per Chargeback',
			achBatchCardFee: 'ACH Batch',
			avsCardFee: 'per AVS',
			montlyPlatformCardFee: 'Monthly per Platform',
			annualCardFee: 'Annual Fee',
			minimumProcessingCardFee: 'Minimum Processing',
			ddaRejectsCardFee: 'per DDA Reject',
			retrievalCardFee: 'Retrieval Fee',
			monthlyPCICardFee: 'Monthly PCI Fee',
			earlyTerminationCardFee: 'Early Termination Fe',
			returnedAchFee: 'per Returned',
			chargebackAchFee: 'per Chargeback',
			monthlyAchFee: 'Verify Bank',
			annualAchFee: 'Verify Negative',
			verifyBankAchFee: 'Verify Fund',
			verifyNegativeAchFee: 'Monthly per Platform',
			verifyFundAchFee: 'Annual Fee',
			sundayOriginationAchFee: 'Sunday Origination',
			sameDayAchFee: 'Same Day',
			advancedSettlementAchFee: 'Advanced Settlement',
			quarterlyPCIAchFee: 'Quarterly PCI Fee',
			earlyTerminationAchFee: 'Early Termination Fee'
		}
		return names[code]
	}

	getTable(tiers, type, cardOrAch) {
		const acceptance = cardOrAch === 'Card' ? this.state.baseTemplate.servicesData.card.cardAcceptance.types : this.state.baseTemplate.servicesData.ach.achAcceptance.types
		let acceptanceVisible = Object.entries(acceptance)
			.filter((e) => e[1].visible === true)
			.map((e) => e[0])
		let visibleRanges = false
		if (cardOrAch === 'ACH') {
			acceptanceVisible = ['web']
		}
		return (
			<Table className="table-striped">
				<thead>
					{type === 'through' && (
						<>
							<tr key={1}>
								<th key={0}>{cardOrAch}</th>
								<th key={1}>% OneTime</th>
								<th key={2}>$ OneTime</th>
								<th key={3}>% Recurring</th>
								<th key={4}>$ Recurring</th>
								{visibleRanges && (
									<>
										<th key={5}>Low pay range</th>
										<th key={6}>High pay range</th>
									</>
								)}
							</tr>
						</>
					)}
					{type !== 'through' && (
						<>
							<tr key={1}>
								<th key={0}>{cardOrAch}</th>
								<th key={1}>% per Auth</th>
								<th key={2}>$ per Auth</th>
								{visibleRanges && (
									<>
										<th key={5}>Low pay range</th>
										<th key={6}>High pay range</th>
									</>
								)}
							</tr>
						</>
					)}
				</thead>

				<tbody>
					{tiers.map((e, i) => {
						return (
							<>
								{Object.entries(e)
									.filter((e) => acceptanceVisible.includes(e[0]))
									.map((k, j) => {
										return (
											<tr key={j}>
												<td key={0}>
													<div className="card-brands ">
														<div>
															<img
																alt=""
																style={
																	k[0] === 'web'
																		? {
																				width: '44px',
																				height: '100%'
																		  }
																		: k[0] === 'amex' || k[0] === 'masterCard'
																		? {
																				height: '28px'
																		  }
																		: null
																}
																className={k[0] === 'web' ? 'grey-icon-v2' : k[0] === 'masterCard' ? 'mastercard' : k[0] === 'discover' ? 'discover' : 'amex'}
																src={k[0] === 'web' ? achIcon : k[0] === 'visa' ? visa : k[0] === 'masterCard' ? mastercard : k[0] === 'discover' ? discover : amex}
															/>
														</div>
													</div>
												</td>
												{type === 'through' && (
													<>
														<td key={1}>{k[1].percentFeeOneTime}%</td>
														<td key={2}>$ {parseFloat(k[1].amountFeeOneTime).toFixed(2)}</td>
														<td key={3}>{k[1].percentFeeRecurring}%</td>
														<td key={4}>$ {parseFloat(k[1].amountFeeRecurring).toFixed(2)}</td>
														{visibleRanges && (
															<>
																<td key={5}>{k[1].lowPayRange}</td>
																<td key={6}>{k[1].highPayRange}</td>
															</>
														)}
													</>
												)}
												{type !== 'through' && (
													<>
														<td key={1}>{parseFloat(k[1].percentxAuth).toFixed(2)}%</td>
														<td key={2}>$ {parseFloat(k[1].amountxAuth).toFixed(2)}</td>
														{visibleRanges && (
															<>
																<td key={5}>{k[1].lowPayRange}</td>
																<td key={6}>{k[1].highPayRange}</td>
															</>
														)}
													</>
												)}
											</tr>
										)
									})}
							</>
						)
					})}
				</tbody>
			</Table>
		)
	}

	getAchPricingAbsorb() {
		const { tiers } = this.state.baseTemplate.servicesData.ach.achAbsorb
		return this.getTable(tiers, 'absorb', 'ACH')
	}

	getAchPricingPassThrough() {
		const { tiers } = this.state.baseTemplate.servicesData.ach.achPassThrough
		return this.getTable(tiers, 'through', 'ACH')
	}

	getCardPricingICPlus() {
		const { tiers } = this.state.baseTemplate.servicesData.card.cardICP
		return this.getTable(tiers, 'icp', 'Card')
	}

	getCardPricingFlat() {
		const { tiers } = this.state.baseTemplate.servicesData.card.cardFlat
		return this.getTable(tiers, 'flat', 'Card')
	}

	getCardPricingPassThrought() {
		const { tiers } = this.state.baseTemplate.servicesData.card.cardPassThrough
		return this.getTable(tiers, 'through', 'Card')
	}

	getCardFee() {
		if (this.state.baseTemplate.servicesData.card.cardFees === null) return
		const cardFees = this.state.baseTemplate.servicesData.card.cardFees
		if (cardFees && cardFees.visible === false) return
		return (
			<Table className="table-striped">
				{
					<thead>
						<tr key={1}>
							<th key={0}>Fee</th>
							<th key={1}>Amount</th>
						</tr>
					</thead>
				}
				{
					<tbody>
						{Object.entries(cardFees).map((k, j) => {
							if (k[1].visible) {
								return (
									<>
										<tr key={j}>
											<td
												key={0}
												style={{ width: '79%' }}>
												{this.getFeeName(k[0])}
											</td>
											<td key={1}>$ {parseFloat(k[1].value || 0).toFixed(2)}</td>
										</tr>
									</>
								)
							} else {
								return <></>
							}
						})}
					</tbody>
				}
			</Table>
		)
	}

	getAchFee() {
		if (this.state.baseTemplate.servicesData.ach.achFees === null) return
		const achFees = this.state.baseTemplate.servicesData.ach.achFees
		if (achFees && achFees.visible === false) return
		return (
			<Table className="table-striped">
				{
					<thead>
						<tr key={1}>
							<th key={0}>Fee</th>
							<th key={1}>Amount</th>
						</tr>
					</thead>
				}
				{
					<tbody>
						{Object.entries(achFees).map((k, j) => {
							if (k[1].visible) {
								return (
									<>
										<tr key={j}>
											<td
												key={0}
												style={{ width: '65%' }}>
												{this.getFeeName(k[0])}
											</td>
											<td key={1}>$ {parseFloat(k[1].value || 0).toFixed(2)}</td>
										</tr>
									</>
								)
							} else {
								return <></>
							}
						})}
					</tbody>
				}
			</Table>
		)
	}

	getAchAcceptance() {
		if (this.state.baseTemplate.servicesData.ach === null || this.state.baseTemplate.servicesData.ach.achAcceptance.types === null || this.state.baseData.serviceData === null) return
		var rows = []
		if (this.state.baseTemplate.servicesData.ach.achAcceptance.types.web) {
			if (this.state.baseTemplate.servicesData.ach.achAcceptance.types.web.readOnly === false) {
				rows.push(
					<CheckLink
						iInvisible={true}
						iTitle="Web"
						iName="ach_web"
						iValue={this.state.baseData.serviceData.ach.acceptWeb}
					/>
				)
			} else {
				rows.push(
					<ReadOnlyCheckLink
						iInvisible={true}
						iTitle="Web"
						iName="ach_web"
						iValue={this.state.baseData.serviceData.ach.acceptWeb}
					/>
				)
			}
		}
		if (this.state.baseTemplate.servicesData.ach.achAcceptance.types.ppd) {
			if (this.state.baseTemplate.servicesData.ach.achAcceptance.types.ppd.readOnly === false) {
				rows.push(
					<CheckLink
						iInvisible={true}
						iTitle="PPD"
						iName="ach_ppd"
						iValue={this.state.baseData.serviceData.ach.acceptPPD}
					/>
				)
			} else {
				rows.push(
					<ReadOnlyCheckLink
						iInvisible={true}
						iTitle="PPD"
						iName="ach_ppd"
						iValue={this.state.baseData.serviceData.ach.acceptPPD}
					/>
				)
			}
		}
		if (this.state.baseTemplate.servicesData.ach.achAcceptance.types.ccd) {
			if (this.state.baseTemplate.servicesData.ach.achAcceptance.types.ccd.readOnly === false) {
				rows.push(
					<CheckLink
						iInvisible={true}
						iTitle="CCD"
						iName="ach_ccd"
						iValue={this.state.baseData.serviceData.ach.acceptCCD}
					/>
				)
			} else {
				rows.push(
					<ReadOnlyCheckLink
						iInvisible={true}
						iTitle="CCD"
						iName="ach_ccd"
						iValue={this.state.baseData.serviceData.ach.acceptCCD}
					/>
				)
			}
		}
		return (
			<Row className="mt-2 mb-3">
				<h6 className="mb-2">ACH Acceptance</h6>
				<br />
				<label className="mb-2 small">Integrated per transaction fee for ACH debits and credits.</label>
				<br />
				{rows}
			</Row>
		)
	}

	selectContactAsSigner(event) {
		let signer = this.state.signer
		if (event.target.value !== '') {
			let obj = JSON.parse(event.target.value)
			signer.name = obj.contactName
			signer.email = obj.contactEmail
			signer.phone = obj.contactPhone
			signer.ssn = obj.contactSSN ? obj.contactSSN : ''
			signer.dob = obj.contactDOB ? obj.contactDOB : ''
			signer.country = obj.contactCountry ? obj.contactCountry : ''
			signer.city = obj.contactCity ? obj.contactCity : ''
			signer.state = obj.contactState ? obj.contactState : ''
			signer.address = obj.contactAddress ? obj.contactAddress : ''
			signer.address1 = obj.contactAddress2 ? obj.contactAddress2 : ''
			signer.zip = obj.contactZip ? obj.contactZip : ''
		} else {
			signer.name = ''
			signer.email = ''
			signer.phone = ''
			signer.ssn = null
			signer.dob = ''
			signer.country = ''
			signer.city = ''
			signer.state = ''
			signer.address = ''
			signer.address1 = ''
			signer.zip = ''
		}
		this.setState(
			{
				signer: signer,
				signer_dob: signer.dob,
				signer_address: signer.address,
				signer_address2: signer.address1,
				signer_city: signer.city,
				signer_state: signer.state,
				signer_zip: signer.zip,
				signer_country: signer.country
			},
			() => {
				this.props.boarding.setTemplateData('signer_dob', signer.dob)
			}
		)
	}

	onCopyBankInfo(e) {
		if (this.state.baseData.bankData.length > 1) {
			this.setState({
				bankName: this.props.boarding.template_data.deposit_bankName.value,
				routingAccount: this.props.boarding.template_data.deposit_routingAccount.value,
				accountNumber: this.props.boarding.template_data.deposit_accountNumber.value,
				typeAccount: this.props.boarding.template_data.deposit_typeAccount.value
			})
		}
	}

	closefileExceededSizel() {
		this.setState({ fileExceededSize: false })
	}

	sendLinkToApp(idApp) {
		this.setState({ appIdToModify: idApp, saveEmailModalIsOpen: true })
	}

	copyAppLink(idApp) {
		this.setState({ idAppLink: idApp, applicationLinkEmail: '' })
		this.openApplicationEmailModal()
	}
	updateStatus(idApp, appStatus) {
		this.props.global.setLoading(true)
		this.props.boarding
			.updateApplicationStatus(idApp, appStatus)
			.then((res) => {
				this.props.global.setLoading(false)
				toast.success('Status changed successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}
	sendToProcessor(idApp) {
		this.props.boarding.setLoading(true)
		if (this.state.boardingStatus === 3) {
			this.updateStatus(idApp, 10)
		}
		this.props.global.setLoading(false)
	}
	openApplicationEmailModal() {
		this.setState({ applicationEmailModalIsOpen: true })
	}
	closeApplicationEmailModal() {
		this.setState({ applicationEmailModalIsOpen: false })
	}
	handleCopyApplicationEmail(value) {
		this.setState({ applicationLinkEmail: value })
		if (!this.props.global.validators.isEmpty(value) && !this.props.global.validators.stringValidator('email', value)) {
			this.setState({ applicationLinkEmailError: false })
		} else {
			this.setState({ applicationLinkEmailError: true })
		}
	}
	copyToClipboard(key) {
		navigator.clipboard.writeText(key)
		toast.success('Text copied successfully!', {
			position: toast.POSITION.BOTTOM_RIGHT,
			className: 'toast-success-container'
		})
	}
	openApplicationCopyLinkModal() {
		this.setState({ applicationCopyLinkModalIsOpen: true })
	}
	closeApplicationCopyLinkModal() {
		this.setState({ applicationCopyLinkModalIsOpen: false })
	}
	getApplicationLink() {
		if (this.state.applicationLinkEmailError || !this.state.applicationLinkEmail || this.state.applicationLinkEmail === '') {
			return
		}

		this.props.global.setLoading(true)
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(process.env.REACT_APP_URL_API + 'Boarding/applink/' + this.state.idAppLink + '/' + this.state.applicationLinkEmail, null, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((res) => {
				if (res && res.data.responseData) {
					let data = res.data.responseData
					this.setState(
						{
							appLink: data.appLink,
							referenceId: data.referenceId
						},
						function () {
							this.closeApplicationEmailModal()
							this.openApplicationCopyLinkModal()
						}
					)
				}
				this.props.global.setLoading(false)
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}
	cancelApp(idApp) {
		this.setState({ appIdToModify: idApp })
		this.openCancelAppModal()
	}
	openCancelAppModal() {
		this.setState({ cancelAppModalIsOpen: true })
	}
	closeCancelAppModal() {
		this.setState({ cancelAppModalIsOpen: false })
	}
	cancelAppAction() {
		let appIdToDelete = this.state.appIdToModify
		if (appIdToDelete) {
			this.props.global.setLoading(true)
			this.props.reports
				.cancelApp(appIdToDelete)
				.then((result) => {
					this.setState({ cancelAppModalIsOpen: false })
					this.props.global.setLoading(false)
					toast.success('Application deleted successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
					this.props.history.push({
						pathname: '/' + PayabliStorageManager.getEntryName() + '/boarding/applications'
					})
				})
				.catch((error) => {
					this.props.global.setLoading(false)
					let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				})
		}
	}
	handleEmailChange(value) {
		this.setState({ appemail: value })
	}
	saveEmailModal() {
		if (this.state.appemail === null || this.state.appemail === '' || this.state.appemail.length <= 0) return
		if (!this.props.global.emailValidation(this.state.appemail)) return
		this.sendToComplete()
	}
	closeSaveEmailModal() {
		this.setState({ saveEmailModalIsOpen: false, appemail: '' })
	}
	sendToComplete() {
		this.props.global.setLoading(true)
		this.props.boarding
			.sendLinkApplication(this.state.appIdToModify, this.state.appemail)
			.then((res) => {
				this.props.global.setLoading(false)
				toast.success('Application Link sent!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				toast.error('Oops! Something went wrong sending email!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
		this.setState({ saveEmailModalIsOpen: false, appemail: '' })
	}

	viewRecordDetails(url) {
		this.props.history.push(url)
	}

	redirectSso(pEntry) {
		let objThis = this
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let user = encryptStorage && encryptStorage.getItem('pUser') ? encryptStorage.getItem('pUser') : null

		let entryLogo = null

		if (user && user.id && pEntry.EntryName) {
			this.props.global.setLoading(true)

			this.props.entry
				.getEntryFromApi(pEntry.EntryName)
				.then((res) => {
					entryLogo = res?.responseData?.EntryLogo
					if (entryLogo === null) {
						entryLogo = PayabliCookieManager.readCookie(`${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`) || 'payabli.png'
					}
					setTimeout(function () {
						SessionManager.getNewToken()
							.then((res) => {
								if (res) {
									PayabliCookieManager.createCookie(pEntry.EntryName + '_pToken', res, 1)
									PayabliCookieManager.createCookie(`${pEntry.EntryName}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`, objThis.props.global.normalizeFileUrl(entryLogo), 1)

									window.open(`${process.env.REACT_APP_URL_PAYPOINT}${pEntry.EntryName}/sso`, '_blank')
								} else {
									let errorMessage = "Something is Wrong. Can't open a PayPoint."
									toast.error(errorMessage, {
										position: toast.POSITION.BOTTOM_RIGHT,
										className: 'toast-error-container'
									})
								}
								objThis.props.global.setLoading(false)
							})
							.catch((error) => {
								objThis.props.global.setLoading(false)
								toast.error('Something is Wrong. ' + error, {
									position: toast.POSITION.BOTTOM_RIGHT,
									className: 'toast-error-container'
								})
							})
					}, 500)
				})
				.catch((error) => {
					Sentry.captureException(error)
					this.props.global.setLoading(false)
					let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
					return
				})
		}
	}

	render() {
		const infoTheme = {
			fontSize: '30px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}
		const rowBar = {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'row',
			gap: '1em'
		}
		const cellBar = {
			flex: '1 0 150px',
			boxSizing: 'border-box',
			maxWidth: '250px'
		}
		return (
			<Layout {...this.props}>
				<Modal
					style={{ textAlign: 'center' }}
					show={this.state.cancelAppModalIsOpen}
					onHide={this.closeCancelAppModal}
					size="sm"
					centered>
					<Modal.Body>
						<BiTrash className="icon-modal" />
						<h5>Delete</h5>
						<p className="small">Are you sure you want to delete this application?</p>
						<Button
							className="btn cancel-btn"
							variant="default"
							onClick={(e) => this.closeCancelAppModal()}>
							Cancel
						</Button>
						&nbsp;&nbsp;
						<Button
							className="btn"
							variant="danger"
							onClick={(e) => this.cancelAppAction()}>
							Delete
						</Button>
					</Modal.Body>
				</Modal>
				{this.state.saveEmailModalIsOpen && (
					<SendEmailModal
						saveEmailModalIsOpen={this.state.saveEmailModalIsOpen}
						closeSaveEmailModal={this.closeSaveEmailModal}
						saveEmailModal={this.saveEmailModal}
						appemail={this.state.appemail}
						handleEmailChange={(value) => this.handleEmailChange(value)}
					/>
				)}
				<Modal
					style={{ textAlign: 'center' }}
					show={this.state.applicationEmailModalIsOpen}
					onHide={this.closeApplicationEmailModal}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered>
					<Modal.Body>
						<BiCopyAlt className="icon-modal" />
						<h5>Copy Application Link</h5>
						<p className="small">Enter an email to create a link for this application. Proceed to next step to get a shareable link.</p>
						<p className="small-grey">{this.state.transIdToSendReceipt}</p>

						<IMaskInput
							mask={this.props.global.maskValidator('email')}
							value={this.state.applicationLinkEmail}
							unmask={true}
							placeholder="name@example.com"
							className={this.state.applicationLinkEmailError ? 'form-control mb-3 input-error' : 'form-control mb-3'}
							onAccept={(value, mask) => this.handleCopyApplicationEmail(value)}
						/>
						<div className="row">
							<div className="col">
								<Button
									className="btn full-w  cancel-btn"
									variant="default"
									onClick={this.closeApplicationEmailModal}>
									Cancel
								</Button>
							</div>
							<div className="col">
								<Button
									className="btn full-w"
									variant="primary"
									onClick={this.getApplicationLink}>
									Next
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>

				<Modal
					style={{ textAlign: 'left' }}
					show={this.state.applicationCopyLinkModalIsOpen}
					onHide={this.closeCancelAppModal}
					size="md"
					centered>
					<Modal.Body>
						<h5>Copy Application Link & Reference ID</h5>
						<p className="small">You can click on the icon to copy the Application Link and reference ID; both are needed for your user to gain access.</p>

						<p className="small">
							<BiCopyAlt
								onClick={() => this.copyToClipboard(this.state.appLink)}
								className="cursor-pointer"
								style={{ fontSize: '16px' }}
							/>
							&nbsp;&nbsp;&nbsp;{this.state.appLink}
						</p>
						<p className="small">
							<BiCopyAlt
								onClick={() => this.copyToClipboard(this.state.referenceId)}
								className="cursor-pointer"
								style={{ fontSize: '16px' }}
							/>
							&nbsp;&nbsp;&nbsp;{this.state.referenceId}
						</p>

						<Button
							className="btn cancel-btn full-w mt-2"
							variant="default"
							onClick={(e) => this.closeApplicationCopyLinkModal()}>
							Close Window
						</Button>
					</Modal.Body>
				</Modal>
				<Modal
					style={{ textAlign: 'center' }}
					show={this.state.fileExceededSize}
					onHide={this.closefileExceededSizel}
					aria-labelledby="contained-modal-title-vcenter"
					centered>
					<Modal.Body>
						<BiX className="icon-modal" />
						<h5>File limit exceeded!</h5>
						<p>It seems like you’ve exceeded the maximum file size allowed. Upload max is 30MB of any combination of .pdf .jpg. or .png files. We apologize for any inconvenience.</p>

						<p>If you need to submit larger files, please email us.</p>
						<Button
							className="btn mt-2"
							variant="outline-dark"
							onClick={(e) => this.closefileExceededSizel()}>
							Ok
						</Button>
					</Modal.Body>
				</Modal>
				<div>
					<TopBar>
						<MainTopBarMenu />
						<div className="top-bar-sub">
							<button
								className="cursorPointer btn btn-light text-transform-normal"
								onClick={(e) => this.viewRecordDetails('/' + this.props.global.getURLEntry() + '/boarding/applications/')}>
								{'< Application list'}
							</button>
							{/* <BoardingLinks gobackText="Application list" selected="applications" goback="true" gobackHref="/boarding/applications" /> */}
						</div>
					</TopBar>
					<div className="mt-body4">
						<div>
							<div style={rowBar}>
								<div style={cellBar}>
									<h5
										className="mt-2"
										data-qaid="applicationViewEdit">
										{this.state.baseData && this.state.baseData.legalName ? this.state.baseData.legalName : 'Edit Application'}
									</h5>
									<p className="small-grey">
										{this.state.baseData && this.state.baseData.dbaName ? 'DBA ' + this.state.baseData.dbaName : ''} {this.state.baseData && this.state.baseData.dbaName && this.state.baseData.ownType ? '/' : ''} {this.state.baseData && this.state.baseData.ownType ? this.state.baseData.ownType : ''}
									</p>
								</div>
								<div
									style={{
										flex: '1 0 50px',
										boxSizing: 'border-box',
										maxWidth: '370px'
									}}>
									{this.state.boardingStatus !== 100 && this.state.boardingStatus !== 0 && this.state.boardingStatus !== null && (
										<ApplicationStatus
											idApp={this.state.appId}
											changeStatus={this.changeStatus}
											status={this.props.boarding.template_data?.boardingStatus?.value ?? this.state.boardingStatus}
											subStatus={this.props.boarding.template_data?.boardingSubStatus?.value ?? this.state.boardingSubStatus}
										/>
									)}
								</div>
								<div style={cellBar}>
									{this.state.boardingStatus < 1 && (
										<>
											<DropdownButton
												menuAlign="left"
												title="Actions"
												id="actionsMenuButton"
												variant="primary"
												className="btn btn-bordered-success p-0">
												<Dropdown.Item
													style={{
														fontSize: '12px'
													}}
													onClick={(e) => this.viewRecordDetails('/' + PayabliStorageManager.getEntryName() + '/boarding/viewapplication/' + this.state.appId)}>
													View Details
												</Dropdown.Item>
												<Dropdown.Item
													style={{
														fontSize: '12px'
													}}
													onClick={(e) => this.sendLinkToApp(this.state.appId)}>
													Send link via Email
												</Dropdown.Item>
												<Dropdown.Item
													style={{
														fontSize: '12px'
													}}
													onClick={(e) => this.copyAppLink(this.state.appId)}>
													Copy App. Link
												</Dropdown.Item>
												{this.state.showSend2Processor && (
													<Dropdown.Item
														onClick={(e) => this.sendToProcessor(this.state.appId)}
														data-qaid="applicationsendToProcessor">
														Send to Processor
													</Dropdown.Item>
												)}
												<Dropdown.Divider />
												<Dropdown.Item
													style={{
														color: 'rgb(192, 0, 0)',
														fontSize: '12px'
													}}
													onClick={(e) => this.cancelApp(this.state.appId)}>
													Delete
												</Dropdown.Item>
											</DropdownButton>
										</>
									)}
								</div>
							</div>
						</div>

						{this.state.payPointId !== null && (
							<div
								className="btn"
								style={{
									border: 'solid 1px #92D050',
									width: '56.5%',
									textTransform: 'none',
									fontSize: '12px',
									cursor: 'text'
								}}>
								<span style={{ color: '#92D050' }}>
									This application was converted to a PayPoint on {Moment(this.state.createDatePayPoint).format('MMMM Do YYYY')} at {this.props.global.stringTimeFormat(this.state.createDatePayPoint)} {this.props.global.getTimeZone('v1')}{' '}
									{this.state.entryPoint && (
										<button
											className="btn btn-link"
											style={{
												textDecoration: 'none',
												cursor: 'pointer'
											}}
											onClick={() => this.redirectSso(this.state.entryPoint)}
											rel="noreferrer">
											(GO TO PAYPOINT)
										</button>
									)}
								</span>
							</div>
						)}
						{this.state.boardingStatus === 10 && this.state.payPointId === null && (
							<div
								className="mb-5"
								style={{ width: '56.5%' }}>
								<button
									onClick={(e) => this.onCreatePaypoint(e)}
									className="btn full-w"
									style={{
										color: '#fff',
										backgroundColor: '#92D050',
										border: 'solid 1px #92D050'
									}}>
									Click to Create Paypoint
								</button>
							</div>
						)}

						<div
							className="application-form view-application-form"
							style={{ marginTop: 0 }}>
							<div
								className="application-tabs"
								style={{ padding: 0 }}>
								{this.state.baseTemplate && (
									<Tabs
										activeKey={this.state.currentSection}
										className=""
										onSelect={this.setTab}>
										{this.state.baseTemplate && this.state.baseTemplate.business !== null && (
											<Tab
												eventKey="business"
												title={
													<>
														<BiDetail className="icon" />
														<br />
														<span>Business Details</span>
													</>
												}>
												<h5>Business Details</h5>
												<p className="small mb-4 dark-grey">You have an awesome business, tell us a little bit about it! </p>
												{this.getBusinessBlock()}
												{this.getBusinessAddressBlock()}
											</Tab>
										)}
										{this.state.baseTemplate && this.state.baseTemplate.owners !== null && (
											<Tab
												eventKey="owners"
												title={
													<>
														<BiUserCheck className="icon" />
														<br />
														<span>Ownership and Contacts</span>
													</>
												}>
												<h5>Ownership and Contacts</h5>
												<p className="small mb-4 dark-grey">Federal Law requires us to collect some information on the owners, the business, and whoever will be signing the Merchant Agreement.</p>
												<Row className="mt-4">{this.getOwnerBlock(0)}</Row>
												{this.state.additionalOwners.length > 0 &&
													this.state.additionalOwners.map((k) => (
														<Row
															className="mt-2"
															key={k}>
															{k === this.state.additionalOwners.length && (
																<>
																	<div className="col-12 text-right">
																		<button
																			className="btn btn-danger mb-1"
																			onClick={(e) => this.removeOwner(k)}>
																			<BiTrash
																				style={{
																					fontSize: '14px'
																				}}
																			/>
																		</button>
																	</div>
																	<br />
																</>
															)}
															{this.getOwnerBlock(k)}
														</Row>
													))}
												{this.state.baseTemplate?.ownershipData?.multipleOwners && this.state.baseTemplate?.ownershipData?.multipleOwners !== false && (
													<div id="addOwner">
														<button
															className="btn full-w bordered no-margin mb-4"
															onClick={(e) => this.addOwner()}>
															Add additional Owner with 25% or more of Ownership
														</button>
													</div>
												)}
												{this.getContactBlock(0)}
												{this.state.additionalContacts.length > 0 &&
													this.state.additionalContacts.map((k) => (
														<Row
															className="mt-2"
															key={k}>
															<div className="col-12 text-right">
																<button
																	className="btn btn-danger mb-1"
																	onClick={(e) => this.removeContact(k)}>
																	<BiTrash
																		style={{
																			fontSize: '14px'
																		}}
																	/>
																</button>
															</div>
															{this.getContactBlock(k)}
														</Row>
													))}
												{this.state.baseTemplate?.ownershipData?.multipleContacts && this.state.baseTemplate?.ownershipData?.multipleContacts !== false && (
													<div id="addContact">
														<button
															className="btn full-w bordered no-margin mb-4"
															onClick={(e) => this.addContact()}>
															Add Additional Contact
														</button>
													</div>
												)}
											</Tab>
										)}
										{this.state.baseTemplate && this.state.baseTemplate?.processingDataData !== null && (
											<Tab
												eventKey="processing"
												title={
													<>
														<BiCog className="icon" />
														<br />
														<span>Processing Information</span>
													</>
												}>
												<h5>Processing Information</h5>
												<p className="small mb-4 dark-grey">We are so proud to power your payment processing, share with us your needs.</p>
												{this.getProcessingBlock()}
											</Tab>
										)}
										{this.state.baseTemplate && this.state.baseTemplate?.servicesData !== null && (
											<Tab
												eventKey="services"
												title={
													<div>
														<BiDollarCircle className="icon" />
														<br />
														<span>Services and Pricing</span>
													</div>
												}>
												<h5>Services and Pricing</h5>
												<p className="small mb-4 dark-grey">Below you will find the rates associated with your Payment Processing Account.</p>
												{this.getCardAcceptance()}
												<h6 className="mb-4">Setup</h6>
												<Row className="mt-4">
													<div className="col-md-6">
														<InputSelectTemplate
															iDisabled="true"
															iNoToggle={true}
															iType="gateway_card"
															iTitle="Gateway"
															iVisible={true}
															iReadOnly={true}
															iName="card_gateway"
															iValue={this.state.baseTemplate.servicesData.card.gateway?.value}
														/>
														<InputSelectTemplate
															iDisabled="true"
															iNoToggle={true}
															iType="processor_card"
															iTitle="Processor"
															iVisible={true}
															iReadOnly={true}
															iName="card_processor"
															iPlaceholder=""
															iValue={this.state.baseTemplate.servicesData.card.processor?.value}
														/>
														<InputSelectTemplate
															iDisabled="true"
															iNoToggle={true}
															iType="provider"
															iTitle="Provider"
															iVisible={true}
															iReadOnly={true}
															iName="card_provider"
															iPlaceholder=""
															iValue={this.state.baseTemplate.servicesData.card.provider?.value}
														/>
														<div className="row mb-2">
															<div className="col-md-5">
																<div className="form-group mb-1">
																	<label className="font-weight-bold">Tier Name</label>
																	<select
																		className="form-control form-select"
																		onChange={this.handleChangeTierName}
																		name={'card_tierName'}
																		id={'card_tierName'}
																		required={true}
																		value={this.state.card_tierName}>
																		{this.state.tiersName.map((opt) => {
																			return (
																				<option
																					key={`card_tierName-${opt.value}`}
																					value={opt.value?.toUpperCase()}>
																					{opt.text?.toUpperCase()}
																				</option>
																			)
																		})}
																	</select>
																</div>
															</div>
														</div>
														<ReadOnlySimpleLink
															iTitle="PDF Template ID"
															iVisible={true}
															iReadOnly={true}
															iName="card_pdfTemplateId"
															iPlaceholder=""
															iValue={this.state.baseTemplate.servicesData.card.pdfTemplateId?.value}
														/>
													</div>
													<div className="col-md-6">
														<InputSelectLink
															iDisabled="true"
															iType="discount-freq"
															iTitle="Discount Frequency"
															iVisible={this.state.baseTemplate.servicesData.card.discountFrequency.visible}
															iReadOnly={this.state.baseTemplate.servicesData.card.discountFrequency.readOnly}
															iName="card_discountFrequency"
															iPlaceholder=""
															iValue={this.state.baseTemplate.servicesData.card.discountFrequency.value}
														/>
														<InputSelectLink
															iDisabled="true"
															iType="funding-type"
															iTitle="Funding RollUp"
															iVisible={this.state.baseTemplate.servicesData.card.fundingRollup.visible}
															iReadOnly={this.state.baseTemplate.servicesData.card.fundingRollup.readOnly}
															iName="card_fundingRollup"
															iValue={this.state.baseTemplate.servicesData.card.fundingRollup.value}
														/>
														{/* <ReadOnlySimpleLink iTitle="Pass-Through Cost" iRequired={[true, null].includes(this.state.baseTemplate.servicesData.card.passThroughCost.required) ? true : false} iVisible={this.state.baseTemplate.servicesData.card.passThroughCost.visible} iReadOnly={this.state.baseTemplate.servicesData.card.passThroughCost.readOnly} iName="card_passThroughCost" iValue={this.state.baseTemplate.servicesData.card.passThroughCost.value} /> */}
													</div>
												</Row>
												{this.state.baseTemplate.servicesData.card.cardAcceptance !== null && (
													<Row className="mt-4">
														<CheckLink
															iInvisible={true}
															iTitle="Accept Card Processing Services"
															iName="card"
															iValue={true}
														/>
													</Row>
												)}
												<h6 className=" mb-4">Pricing</h6>
												<Row className="mt-4">
													<div className="col-md-6">
														<InputSelectLink
															iDisabled="true"
															iType="card-pricing"
															iTitle="Pricing Type"
															iVisible={this.state.baseTemplate.servicesData.card.pricingType.visible}
															iReadOnly={this.state.baseTemplate.servicesData.card.pricingType.readOnly}
															iName="card_pricingType"
															iValue={this.state.baseTemplate.servicesData.card.pricingType.value}
														/>
													</div>
												</Row>
												{this.getCardPricing()}
												{this.getCardFee()}
												<br />
												{this.getAchAcceptance()}
												<h6 className="mb-4">Setup</h6>
												<Row className="mt-4">
													<div className="col-md-6">
														<InputSelectTemplate
															iDisabled="true"
															iNoToggle={true}
															iType="gateway_ach"
															iTitle="Gateway"
															iVisible={true}
															iReadOnly={true}
															iName="ach_gateway"
															iValue={this.state.baseTemplate.servicesData.ach.gateway?.value}
														/>
														<InputSelectTemplate
															iDisabled="true"
															iNoToggle={true}
															iType="processor_ach"
															iTitle="Processor"
															iVisible={true}
															iReadOnly={true}
															iName="ach_processor"
															iPlaceholder=""
															iValue={this.state.baseTemplate.servicesData.ach.processor?.value}
														/>
														<InputSelectTemplate
															iDisabled="true"
															iNoToggle={true}
															iType="provider"
															iTitle="Provider"
															iVisible={true}
															iReadOnly={true}
															iName="ach_provider"
															iPlaceholder=""
															iValue={this.state.baseTemplate.servicesData.ach.provider?.value}
														/>
														<div className="row mb-2">
															<div className="col-md-5">
																<div className="form-group mb-1">
																	<label className="font-weight-bold">Tier Name</label>
																	<select
																		className="form-control form-select"
																		onChange={this.handleChangeTierName}
																		name={'ach_tierName'}
																		id={'ach_tierName'}
																		required={true}
																		value={this.state.ach_tierName}>
																		{this.state.tiersName.map((opt) => {
																			return (
																				<option
																					key={`ach_tierName-${opt.value}`}
																					value={opt.value?.toUpperCase()}>
																					{opt.text?.toUpperCase()}
																				</option>
																			)
																		})}
																	</select>
																</div>
															</div>
														</div>
														<ReadOnlySimpleLink
															iTitle="PDF Template ID"
															iVisible={true}
															iReadOnly={true}
															iName="ach_pdfTemplateId"
															iPlaceholder=""
															iValue={this.state.baseTemplate.servicesData.ach.pdfTemplateId?.value}
														/>
													</div>
													<div className="col-md-6">
														<InputSelectLink
															iDisabled="true"
															iType="discount-freq"
															iTitle="Discount Frequency"
															iVisible={this.state.baseTemplate.servicesData.ach.discountFrequency.visible}
															iReadOnly={this.state.baseTemplate.servicesData.ach.discountFrequency.readOnly}
															iName="ach_discountFrequency"
															iPlaceholder=""
															iValue={this.state.baseTemplate.servicesData.ach.discountFrequency.value}
														/>
														<InputSelectLink
															iDisabled="true"
															iType="funding-type"
															iTitle="Funding RollUp"
															iVisible={this.state.baseTemplate.servicesData.ach.fundingRollup.visible}
															iReadOnly={this.state.baseTemplate.servicesData.ach.fundingRollup.readOnly}
															iName="ach_fundingRollup"
															iValue={this.state.baseTemplate.servicesData.ach.fundingRollup.value}
														/>
													</div>
												</Row>
												{this.state.baseTemplate.servicesData.ach.achAcceptance !== null && (
													<Row className="mt-4">
														<CheckLink
															iInvisible={true}
															iTitle="Accept ACH Processing Services"
															iName="ach"
															iValue={true}
														/>
													</Row>
												)}
												<h6 className=" mb-4">Pricing</h6>
												<Row className="mt-4">
													<div className="col-md-6">
														<InputSelectLink
															iDisabled="true"
															iType="ach-pricing"
															iTitle="Pricing Type"
															iVisible={this.state.baseTemplate.servicesData.ach.pricingType.visible}
															iReadOnly={this.state.baseTemplate.servicesData.ach.pricingType.readOnly}
															iName="ach_pricingType"
															iValue={this.state.baseTemplate.servicesData.ach.pricingType.value}
														/>
													</div>
												</Row>
												{this.getAchPricing()}
												{this.getAchFee()}
											</Tab>
										)}
										<Tab
											eventKey="banking"
											title={
												<div>
													<RiBankLine className="icon" />
													<br />
													<span>Banking and Documents</span>
												</div>
											}>
											<h5>Banking and Documents</h5>
											<p className="small mb-4 dark-grey">We are almost done! Please provide us with some important supporting documents and where you want us to send your funds. </p>
											{this.state.baseTemplate.documentsData !== null && (this.state.baseTemplate.documentsData.depositBank !== null || this.state.baseTemplate.documentsData.withdrawalBank !== null) && (
												<Row className="mt-4">
													{this.state.baseTemplate.documentsData.depositBank !== null && (
														<div className="col-6">
															<p className="mb-3">
																<b>Deposit Account</b>
															</p>
															{this.state.baseTemplate.documentsData.depositBank.bankName && this.state.baseTemplate.documentsData.depositBank.bankName.readOnly === false ? (
																<InputSimpleLink
																	iRequired={[true, null].includes(this.state.baseTemplate.documentsData.depositBank.bankName.required) ? true : false}
																	iLink="true"
																	iLinkInvisible="true"
																	iTooltip="Bank Account you'd like us to send funds to"
																	iTitle="Bank Name"
																	iName="deposit_bankName"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].bankName : this.state.baseTemplate.banking.depositAccount.bankName.value}
																	iValidator={this.props.global.getValidator().text}
																	full
																/>
															) : (
																<ReadOnlySimpleLink
																	iTooltip="Bank Account you'd like us to send funds to"
																	iTitle="Bank Name"
																	iName="deposit_bankName"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].bankName : this.state.baseTemplate.banking.depositAccount.bankName.value}
																/>
															)}
															{this.state.baseTemplate.documentsData.depositBank.routingNumber && this.state.baseTemplate.documentsData.depositBank.routingNumber.readOnly === false ? (
																<InputSimpleLink
																	iRequired={[true, null].includes(this.state.baseTemplate.documentsData.depositBank.routingNumber.required) ? true : false}
																	iMask="routing"
																	iTitle="Routing Number"
																	iName="deposit_routingAccount"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].routingAccount : this.state.baseTemplate.documentsData.depositBank.routingNumber.value}
																	iValidator={this.props.global.getValidator().routing}
																	full
																/>
															) : (
																<ReadOnlySimpleLink
																	iMask="routing"
																	iTitle="Routing Number"
																	iName="deposit_routingAccount"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].routingAccount : this.state.baseTemplate.documentsData.depositBank.routingNumber.value}
																/>
															)}
															{this.state.baseTemplate.documentsData.depositBank.accountNumber && this.state.baseTemplate.documentsData.depositBank.accountNumber.readOnly === false ? (
																<InputSimpleLink
																	iRequired={[true, null].includes(this.state.baseTemplate.documentsData.depositBank.accountNumber.required) ? true : false}
																	iMask="accountnumber"
																	iTitle="Account Number"
																	iName="deposit_accountNumber"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].accountNumber : this.state.baseTemplate.documentsData.depositBank.accountNumber.value}
																	iValidator={this.props.global.getValidator().accountnumber}
																	full
																/>
															) : (
																<ReadOnlySimpleLink
																	iMask="accountnumber"
																	iTitle="Account Number"
																	iName="deposit_accountNumber"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].accountNumber : this.state.baseTemplate.documentsData.depositBank.accountNumber.value}
																/>
															)}
															{this.state.baseTemplate.documentsData.depositBank.accountType && this.state.baseTemplate.documentsData.depositBank.accountType.readOnly === false ? (
																<InputSelectLink
																	iRequired={[true, null].includes(this.state.baseTemplate.documentsData.depositBank.accountType.required) ? true : false}
																	iTitle="Account Type"
																	iName="deposit_typeAccount"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].typeAccount : this.state.baseTemplate.documentsData.depositBank.accountType.value}
																	iValidator={this.props.global.getValidator().text}
																	iType="bank-type"
																	full
																/>
															) : (
																<ReadOnlySimpleLink
																	iTitle="Account Type"
																	iName="deposit_typeAccount"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].typeAccount : this.state.baseTemplate.documentsData.depositBank.accountType.value}
																/>
															)}
														</div>
													)}

													{this.state.baseTemplate.documentsData.withdrawalBank !== null && (
														<div className="col-6">
															<p className="mb-3">
																<b>Withdrawal Account</b>
															</p>
															{this.state.baseTemplate.documentsData.withdrawalBank.bankName && this.state.baseTemplate.documentsData.withdrawalBank.bankName.readOnly === false ? (
																<InputSimpleLink
																	iRequired={[true, null].includes(this.state.baseTemplate.documentsData.withdrawalBank.bankName.required) ? true : false}
																	iLink="true"
																	iLinkClick={(e) => this.onCopyBankInfo(e)}
																	iLinkText="Copy deposit account details"
																	iTooltip="Bank Account you'd like us to take our fees from"
																	iTitle="Bank Name"
																	iName="withdrawal_bankName"
																	iValue={this.state.bankName ? this.state.bankName : this.state.baseData.bankData != null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].bankName : this.state.baseTemplate.documentsData.withdrawalBank.bankName.value}
																	iValidator={this.props.global.getValidator().text}
																	full
																/>
															) : (
																<ReadOnlySimpleLink
																	iTooltip="Bank Account you'd like us to take our fees from"
																	iTitle="Bank Name"
																	iName="withdrawal_bankName"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].bankName : this.state.baseTemplate.documentsData.withdrawalBank.bankName.value}
																/>
															)}
															{this.state.baseTemplate.documentsData.withdrawalBank.routingNumber && this.state.baseTemplate.documentsData.withdrawalBank.routingNumber.readOnly === false ? (
																<InputSimpleLink
																	iRequired={[true, null].includes(this.state.baseTemplate.documentsData.withdrawalBank.routingNumber.required) ? true : false}
																	iMask="routing"
																	iTitle="Routing Number"
																	iName="withdrawal_routingAccount"
																	iValue={this.state.routingAccount ? this.state.routingAccount : this.state.baseData.bankData != null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].routingAccount : this.state.baseTemplate.documentsData.withdrawalBank.routingNumber.value}
																	iValidator={this.props.global.getValidator().routing}
																	full
																/>
															) : (
																<ReadOnlySimpleLink
																	iMask="routing"
																	iTitle="Routing Number"
																	iName="withdrawal_routingAccount"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].routingAccount : this.state.baseTemplate.documentsData.withdrawalBank.routingNumber.value}
																/>
															)}
															{this.state.baseTemplate.documentsData.withdrawalBank.accountNumber && this.state.baseTemplate.documentsData.withdrawalBank.accountNumber.readOnly === false ? (
																<InputSimpleLink
																	iRequired={[true, null].includes(this.state.baseTemplate.documentsData.withdrawalBank.accountNumber.required) ? true : false}
																	iMask="accountnumber"
																	iTitle="Account Number"
																	iName="withdrawal_accountNumber"
																	iValue={this.state.accountNumber ? this.state.accountNumber : this.state.baseData.bankData != null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].accountNumber : this.state.baseTemplate.documentsData.withdrawalBank.accountNumber.value}
																	iValidator={this.props.global.getValidator().accountnumber}
																	full
																/>
															) : (
																<ReadOnlySimpleLink
																	iMask="accountnumber"
																	iTitle="Account Number"
																	iName="withdrawal_accountNumber"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].accountNumber : this.state.baseTemplate.documentsData.withdrawalBank.accountNumber.value}
																/>
															)}
															{this.state.baseTemplate.documentsData.withdrawalBank.accountType && this.state.baseTemplate.documentsData.withdrawalBank.accountType.readOnly === false ? (
																<InputSelectLink
																	iRequired={[true, null].includes(this.state.baseTemplate.documentsData.withdrawalBank.accountType.required) ? true : false}
																	iTitle="Account Type"
																	iName="withdrawal_typeAccount"
																	iValue={this.state.typeAccount ? this.state.typeAccount : this.state.baseData.bankData != null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].typeAccount : this.state.baseTemplate.documentsData.withdrawalBank.accountType.value}
																	iValidator={this.props.global.getValidator().text}
																	iType="bank-type"
																	full
																/>
															) : (
																<ReadOnlySimpleLink
																	iTitle="Account Type"
																	iName="withdrawal_typeAccount"
																	iValue={this.state.baseData.bankData != null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].typeAccount : this.state.baseTemplate.documentsData.withdrawalBank.accountType.value}
																/>
															)}
														</div>
													)}
												</Row>
											)}
											<br />
											{this.state.baseTemplate?.documentsData?.minimumDocuments && this.state.baseTemplate?.documentsData?.minimumDocuments !== null && parseInt(this.state.baseTemplate.documentsData.minimumDocuments, 10) > 0 && this.getDocumentBlock()}

											{this.getSignerBlock()}
											{this.state.baseTemplate?.documentsData?.termsAndConditions && this.state.baseTemplate?.documentsData?.termsAndConditions && this.state.baseTemplate?.documentsData?.termsAndConditions?.visible && this.state.baseTemplate?.documentsData?.termsAndConditions.tcLinks?.length && (
												<>
													<h6 className="mt-2 text-center">Confirmation & Attestation</h6>
													<p className="small mb-4 dark-grey text-center">Please Review, and Print, or Save the documents provided in links below.</p>
												</>
											)}
											{this.state.baseTemplate?.documentsData?.termsAndConditions && this.state.baseTemplate?.documentsData?.termsAndConditions?.visible && this.state.baseTemplate?.documentsData?.termsAndConditions.tcLinks?.length && (
												<>
													<Card className="card card-in mb-3 ">
														<Card.Header>
															<div className="row">
																<div
																	className="col-1"
																	style={infoTheme}>
																	{(this.state.signer && this.state.signer.acceptance === true && <BsFillExclamationCircleFill color="limegreen" />) || <BsFillExclamationCircleFill color="red" />}
																</div>
																<div className="col-11">
																	<p>
																		By clicking below I agree and hereby confirm to have Read, Agreed and Consented to the
																		{this.state.baseTemplate.documentsData.termsAndConditions.tcLinks.map((k, i) => (
																			<>
																				<span className="text-info"> {k['label']}</span>{' '}
																				<a
																					style={{
																						textDecoration: 'none'
																					}}
																					className="text-info"
																					rel="noreferrer"
																					term={i}
																					onClick={(e) => this.handleAcceptClick(e)}
																					target="_blank"
																					href={k['value']}>
																					(CLICK HERE)
																				</a>
																				{i === this.state.baseTemplate.documentsData.termsAndConditions.tcLinks.length - 2 ? ' and ' : ', '}
																			</>
																		))}
																		<span> provided. Please Review and Save these documents provided in links above.</span>
																	</p>
																</div>
															</div>
														</Card.Header>
														<Card.Body>
															<Row>
																<div
																	className="col-1"
																	style={infoTheme}>
																	<input
																		style={{
																			backgroundColor: '#10A0E3',
																			borderColor: '#10A0E3',
																			minHeight: '22px',
																			marginTop: '6px!important',
																			marginBottom: '6px!important',
																			paddingLeft: 0,
																			width: '22px',
																			height: '22px'
																		}}
																		checked={this.state.signer && this.state.signer.acceptance}
																		type="checkbox"
																		name="acceptance"
																		id="acceptance"
																		onClick={this.handleAcceptCheckClick}
																	/>
																</div>
																<div className="col-11">
																	<label
																		style={{
																			fontSize: '12px'
																		}}
																		htmlFor="acceptance">
																		I Agree to the above information & I attest that the information provided in this application is correct to the best of my knowledge as an authorized signer for this business.
																	</label>
																</div>
															</Row>
														</Card.Body>
													</Card>
												</>
											)}
										</Tab>
									</Tabs>
								)}
							</div>
							<hr className="mt-3 mb-4" />
							<div>
								<Row>
									<div className="col-4 text-left">
										{this.state.currentPos > 0 && (
											<Button
												variant="btn btn-primary"
												onClick={(e) => this.gotoNextTab(this.state.currentPos - 1)}>
												<BiChevronLeft /> Previous
											</Button>
										)}
									</div>
									<div className="col-4 text-center">
										{this.state.complete ? (
											<Button
												variant="btn btn-primary"
												onClick={(e) => this.submitComplete()}>
												Submit
											</Button>
										) : (
											<Button
												variant="btn btn-light"
												onClick={(e) => this.saveToComplete()}>
												Save to complete later
											</Button>
										)}
									</div>
									<div className="col-4 text-right">
										{((this.state.complete && this.state.currentPos < 4) || !this.state.complete) && (
											<Button
												variant="btn btn-primary"
												onClick={(e) => this.gotoNextTab(this.state.currentPos + 1)}>
												Next <BiChevronRight />
											</Button>
										)}
									</div>
								</Row>
							</div>
							<ToastContainer transition={Bounce} />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export { EditApplication }
