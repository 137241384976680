import { inject, observer } from "mobx-react";
import React from "react";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

const ApplePayAdvanceOptions = (props) => {
  const applePayEnabled = props.vTerminal.getApplePayEnabled;
  const autopayEnabled = props.vTerminal.paymentPage.autopay.enabled;
  const hasMethod = props.store.paymentPage.paymentMethods.methods.applePay
  if (!applePayEnabled) {
    return (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip>
            {
              "This service is not activated for the paypoint to which this customer belongs"
            }
          </Tooltip>
        }
      >
        <div className="icheck-primary">
          <input
            disabled={!applePayEnabled}
            defaultChecked={false}
            type="checkbox"
            id="applePay"
          />
          <label htmlFor="applePay">Apple Pay</label>
        </div>
      </OverlayTrigger>
    );
  }

  if (autopayEnabled) {
    return (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip>{"Must deactivate autopay to enable Apple Pay"}</Tooltip>
        }
      >
        <div className="icheck-primary">
          <input
            disabled={!applePayEnabled}
            defaultChecked={false}
            type="checkbox"
            id="applePay"
          />
          <label htmlFor="applePay">Apple Pay</label>
        </div>
      </OverlayTrigger>
    );
  }

  return (
    <div className="icheck-primary">
      <input
        checked={
          hasMethod &&
          !autopayEnabled
        }
        onChange={props.handlePaymentMethods}
        type="checkbox"
        id="applePay"
      />
      <label htmlFor="applePay">Apple Pay</label>
    </div>
  );
};

export default inject(
  "store",
  "global",
  "invoice",
  "vTerminal"
)(observer(ApplePayAdvanceOptions));
