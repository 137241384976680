import { makeObservable , action, observable } from "mobx";
import globalStore from './GlobalStore';

class GooglePayStore {
    globalStore = null;
    constructor(globalStore) {
        this.globalStore = globalStore;
        makeObservable(this)
    }

    @observable 
    googlePayButtonTypes = [
        {
            text: 'Pay', value: 'pay', displayText: 'Pay with', description: 'Pay with'
        },
        {
            text: 'Book', value: 'book', displayText: 'Book with', description: 'Use this button for booking appointments, flights, or other experiences'
        },
        {
            text: 'Buy', value: 'buy', displayText: 'Buy with', description: 'Use this button for product purchases'
        },
        {
            text: 'Check Out', value: 'check-out', displayText: 'Check Out with', description: 'Use this button for purchase experiences that include other payment buttons that start with “Check out”'
        },
        {
            text: 'Donate', value: 'donate', displayText: 'Donate with', description: 'Used by approved nonprofits. Lets people make donations'
        },
        {
            text: "Order", value: "order", displayText: "Order with", description: "Use this button for placing orders for items",
        },
        {
            text: 'Plain', value: 'plain', displayText: '', description: 'An Apple Pay button with the Apple Pay logo only, useful when an additional call to action isn\'t needed'
        },
    ];

    @observable 
    googlePayButtonStyles = [
        { text: "Black (Default)", value: "black"},
        { text: "White", value: "white"},
    ];

    @observable 
    googlePayButtonLanguages = [
        { text: "English (US)", value: "en" },
        { text: "Arabic", value: "ar" },
        { text: "Catalan", value: "ca" },
        { text: "Chinese", value: "zh" },
        { text: "Croatian", value: "hr" },
        { text: "Czech", value: "cs" },
        { text: "Danish", value: "da" },
        { text: "Dutch", value: "nl" },
        { text: "Finnish", value: "fi" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Greek", value: "el" },
        { text: "Hebrew", value: "he" },
        { text: "Hindi", value: "hi" },
        { text: "Hungarian", value: "hu" },
        { text: "Indonesian", value: "id" },
        { text: "Italian", value: "it" },
        { text: "Japanese", value: "ja" },
        { text: "Korean", value: "ko" },
        { text: "Malay", value: "ms" },
        { text: "Norwegian", value: "nb" },
        { text: "Polish", value: "pl" },
        { text: "Portuguese", value: "pt" },
        { text: "Romanian", value: "ro" },
        { text: "Russian", value: "ru" },
        { text: "Slovak", value: "sk" },
        { text: "Spanish", value: "es" },
        { text: "Swedish", value: "sv" },
        { text: "Thai", value: "th" },
        { text: "Turkish", value: "tr" },
        { text: "Ukrainian", value: "uk" },
        { text: "Vietnamese", value: "vi" }
    ];

    @action
    getGooglePayButtonType(value) {
        return this.googlePayButtonTypes.find(button => button.value === value);
    }

    @action
    getGooglePayButtonStyle(value) {
        return this.googlePayButtonStyles.find(button => button.value === value);
    }

    @action
    getGooglePayButtonLanguages(value) {
        return this.googlePayButtonLanguages.find(button => button.value === value);
    }
}

const googlePayStore = new GooglePayStore(globalStore);
export default googlePayStore;