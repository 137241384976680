import React, { useContext } from 'react'
import Switch from 'react-switch'
import GroupRow from '../../../../components/templates/GroupRow'
import SelectBoxContainer from '../../../../components/inputStyles/SelectBoxContainer'
import GlobalStore from '../../../../store/GlobalStore'
import DocumentLink from './DocumentLink'
import templatesContext from '../context/templates_context'

export default function TabBankingDocument() {
	const { RootTemplate, LoadingState, BankingTemplate, handleChangeBanking, messageValid } = useContext(templatesContext)

	return (
		<>
			<h5>Banking and Documents</h5>
			<p className="small mb-4 dark-grey">This section allow to upload business documents and capture the Bank account information. </p>

			{(RootTemplate.isRoot || RootTemplate.rootTemplate.documentsData.depositBank.visible || RootTemplate.rootTemplate.documentsData.withdrawalBank.visible) && (
				<>
					<h6 className="mb-3">Banking Information</h6>

					<div className="row">
						{(RootTemplate.isRoot || RootTemplate.rootTemplate.documentsData.depositBank.visible) && (
							<div className="col-md-6">
								<div className="mb-2">
									<label className="d-flex flex-row align-items-center">
										<Switch
											onChange={(value) => handleChangeBanking('', 'depositBank', 'visible', value)}
											checked={!LoadingState && BankingTemplate.depositBank.visible}
											onColor="#10A0E3"
											onHandleColor="#ffffff"
											handleDiameter={20}
											boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
											activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
											height={20}
											width={38}
											className="react-switch"
											id={'depositBank_v'}
										/>
										<span className="ml-2 small">Deposit Account</span>
									</label>
								</div>
								<GroupRow
									fieldData={!LoadingState && BankingTemplate.depositBank}
									parentField={RootTemplate.rootTemplate.documentsData.depositBank}
									handleChange={(property, field, values) => handleChangeBanking('depositBank', property, field, values)}
									field="bankName"
									inputType={{
										title: 'Bank Name',
										type: 'mask',
										mask: GlobalStore.maskValidator('text'),
										TooltipValid: !LoadingState && BankingTemplate.depositBank.bankName.value.trim().length === 0 && (!BankingTemplate.depositBank.bankName.visible || BankingTemplate.depositBank.bankName.readOnly) ? messageValid : '',
										classNameInput: !LoadingState && BankingTemplate.depositBank.bankName.value.trim().length === 0 && (!BankingTemplate.depositBank.bankName.visible || BankingTemplate.depositBank.bankName.readOnly) ? 'input-error' : ''
									}}
									checkOptions={{
										state: {
											visible: true,
											readOnly: true,
											required: true
										}
									}}
								/>
								<GroupRow
									fieldData={!LoadingState && BankingTemplate.depositBank}
									parentField={RootTemplate.rootTemplate.documentsData.depositBank}
									handleChange={(property, field, values) => handleChangeBanking('depositBank', property, field, values)}
									field="routingNumber"
									inputType={{
										title: 'Routing Number',
										type: 'mask',
										mask: GlobalStore.maskValidator('routing'),
										TooltipValid: !LoadingState && BankingTemplate.depositBank.routingNumber.value.trim().length === 0 && (!BankingTemplate.depositBank.routingNumber.visible || BankingTemplate.depositBank.routingNumber.readOnly) ? messageValid : '',
										classNameInput: !LoadingState && BankingTemplate.depositBank.routingNumber.value.trim().length === 0 && (!BankingTemplate.depositBank.routingNumber.visible || BankingTemplate.depositBank.routingNumber.readOnly) ? 'input-error' : ''
									}}
									checkOptions={{
										state: {
											visible: true,
											readOnly: true,
											required: true
										}
									}}
								/>
								<GroupRow
									fieldData={!LoadingState && BankingTemplate.depositBank}
									parentField={RootTemplate.rootTemplate.documentsData.depositBank}
									handleChange={(property, field, values) => handleChangeBanking('depositBank', property, field, values)}
									field="accountNumber"
									inputType={{
										title: 'Account Number',
										type: 'mask',
										mask: GlobalStore.maskValidator('text'),
										TooltipValid: !LoadingState && BankingTemplate.depositBank.accountNumber.value.trim().length === 0 && (!BankingTemplate.depositBank.accountNumber.visible || BankingTemplate.depositBank.accountNumber.readOnly) ? messageValid : '',
										classNameInput: !LoadingState && BankingTemplate.depositBank.accountNumber.value.trim().length === 0 && (!BankingTemplate.depositBank.accountNumber.visible || BankingTemplate.depositBank.accountNumber.readOnly) ? 'input-error' : ''
									}}
									checkOptions={{
										state: {
											visible: true,
											readOnly: true,
											required: true
										}
									}}
								/>
								<GroupRow
									fieldData={!LoadingState && BankingTemplate.depositBank}
									parentField={RootTemplate.rootTemplate.ownershipData}
									handleChange={(property, field, values) => handleChangeBanking('depositBank', property, field, values)}
									field="accountType"
									inputType={{
										title: 'Account Type',
										type: 'select',
										mask: GlobalStore.getOptionsType('bank-type'),
										TooltipValid: !LoadingState && BankingTemplate.depositBank.accountType.value.trim().length === 0 && (!BankingTemplate.depositBank.accountType.visible || BankingTemplate.depositBank.accountType.readOnly) ? messageValid : '',
										classNameInput: !LoadingState && BankingTemplate.depositBank.accountType.value.trim().length === 0 && (!BankingTemplate.depositBank.accountType.visible || BankingTemplate.depositBank.accountType.readOnly) ? 'input-error' : ''
									}}
									checkOptions={{
										state: {
											visible: true,
											readOnly: true,
											required: true
										}
									}}
								/>
							</div>
						)}
						{(RootTemplate.isRoot || RootTemplate.rootTemplate.documentsData.withdrawalBank.visible) && (
							<div className="col-md-6">
								<div className="mb-2">
									<label className="d-flex flex-row align-items-center">
										<Switch
											onChange={(value) => handleChangeBanking('', 'withdrawalBank', 'visible', value)}
											checked={!LoadingState && BankingTemplate.withdrawalBank.visible}
											onColor="#10A0E3"
											onHandleColor="#ffffff"
											handleDiameter={20}
											boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
											activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
											height={20}
											width={38}
											className="react-switch"
											id={'withdrawalBank_v'}
										/>
										<span className="ml-2 small">Withdrawal Account</span>
									</label>
								</div>
								<GroupRow
									fieldData={!LoadingState && BankingTemplate.withdrawalBank}
									parentField={RootTemplate.rootTemplate.documentsData.withdrawalBank}
									handleChange={(property, field, values) => handleChangeBanking('withdrawalBank', property, field, values)}
									field="bankName"
									inputType={{
										title: 'Bank Name',
										type: 'mask',
										mask: GlobalStore.maskValidator('text'),
										TooltipValid: !LoadingState && BankingTemplate.withdrawalBank.bankName.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.bankName.visible || BankingTemplate.withdrawalBank.bankName.readOnly) ? messageValid : '',
										classNameInput: !LoadingState && BankingTemplate.withdrawalBank.bankName.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.bankName.visible || BankingTemplate.withdrawalBank.bankName.readOnly) ? 'input-error' : ''
									}}
									checkOptions={{
										state: {
											visible: true,
											readOnly: true,
											required: true
										}
									}}
								/>
								<GroupRow
									fieldData={!LoadingState && BankingTemplate.withdrawalBank}
									parentField={RootTemplate.rootTemplate.documentsData.withdrawalBank}
									handleChange={(property, field, values) => handleChangeBanking('withdrawalBank', property, field, values)}
									field="routingNumber"
									inputType={{
										title: 'Routing Number',
										type: 'mask',
										mask: GlobalStore.maskValidator('routing'),
										TooltipValid: !LoadingState && BankingTemplate.withdrawalBank.routingNumber.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.routingNumber.visible || BankingTemplate.withdrawalBank.routingNumber.readOnly) ? messageValid : '',
										classNameInput: !LoadingState && BankingTemplate.withdrawalBank.routingNumber.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.routingNumber.visible || BankingTemplate.withdrawalBank.routingNumber.readOnly) ? 'input-error' : ''
									}}
									checkOptions={{
										state: {
											visible: true,
											readOnly: true,
											required: true
										}
									}}
								/>
								<GroupRow
									fieldData={!LoadingState && BankingTemplate.withdrawalBank}
									parentField={RootTemplate.rootTemplate.documentsData.withdrawalBank}
									handleChange={(property, field, values) => handleChangeBanking('withdrawalBank', property, field, values)}
									field="accountNumber"
									inputType={{
										title: 'Account Number',
										type: 'mask',
										mask: GlobalStore.maskValidator('text'),
										TooltipValid: !LoadingState && BankingTemplate.withdrawalBank.accountNumber.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.accountNumber.visible || BankingTemplate.withdrawalBank.accountNumber.readOnly) ? messageValid : '',
										classNameInput: !LoadingState && BankingTemplate.withdrawalBank.accountNumber.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.accountNumber.visible || BankingTemplate.withdrawalBank.accountNumber.readOnly) ? 'input-error' : ''
									}}
									checkOptions={{
										state: {
											visible: true,
											readOnly: true,
											required: true
										}
									}}
								/>
								<GroupRow
									fieldData={!LoadingState && BankingTemplate.withdrawalBank}
									parentField={RootTemplate.rootTemplate.ownershipData}
									handleChange={(property, field, values) => handleChangeBanking('withdrawalBank', property, field, values)}
									field="accountType"
									inputType={{
										title: 'Account Type',
										type: 'select',
										mask: GlobalStore.getOptionsType('bank-type'),
										TooltipValid: !LoadingState && BankingTemplate.withdrawalBank.accountType.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.accountType.visible || BankingTemplate.withdrawalBank.accountType.readOnly) ? messageValid : '',
										classNameInput: !LoadingState && BankingTemplate.withdrawalBank.accountType.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.accountType.visible || BankingTemplate.withdrawalBank.accountType.readOnly) ? 'input-error' : ''
									}}
									checkOptions={{
										state: {
											visible: true,
											readOnly: true,
											required: true
										}
									}}
								/>
							</div>
						)}
					</div>
				</>
			)}
			{(RootTemplate.isRoot || RootTemplate.rootTemplate.documentsData.uploadDocuments) && (
				<div className="row">
					<div className="col-md-4">
						<div className="mb-2 mt-4">
							<label className="d-flex flex-row align-items-center">
								<Switch
									onChange={(value) => handleChangeBanking('', '', 'uploadDocuments', value, true)}
									checked={!LoadingState && BankingTemplate.uploadDocuments}
									onColor="#10A0E3"
									onHandleColor="#ffffff"
									handleDiameter={20}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={20}
									width={38}
									className="react-switch"
									id={'uploadDocuments'}
								/>
								<span className="ml-2 small">Documents</span>
							</label>
						</div>
						<SelectBoxContainer
							inputDisabled={false}
							labelClassName="font-weight-bold"
							inputStyle={{ maxWidth: '70px' }}
							classNameInput="form-control form-select"
							divClass="form-group mb-1 d-flex flex-row align-items-center flex-wrap gap-2"
							labelValue="Minimum Documents to upload"
							//isLoading={LoadingState}
							name="minimumDocuments"
							value={!LoadingState && BankingTemplate.minimumDocuments}
							setValue={(values) => handleChangeBanking('', '', 'minimumDocuments', values, true)}
							optionList={GlobalStore.getOptionsType('s5x1').map((opt) => {
								return (
									<option
										key={'minimumDocuments' + opt.value}
										value={opt.value}>
										{opt.text}
									</option>
								)
							})}
						/>
					</div>
				</div>
			)}

			<div className="mb-4">
				<label className="d-flex flex-row align-items-center">
					<Switch
						onChange={(value) => handleChangeBanking('', 'signer', 'visible', value)}
						checked={!LoadingState && BankingTemplate.signer.visible}
						onColor="#10A0E3"
						onHandleColor="#ffffff"
						handleDiameter={20}
						boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
						activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
						height={20}
						width={38}
						className="react-switch"
						id={'signer_v'}
					/>
					<h6 className="d-flex flex-row align-items-center ml-2 card-title mb-0">Signer</h6>
				</label>
			</div>
			<div className="row">
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="name"
						inputType={{
							title: 'Full Name',
							type: 'mask',
							mask: GlobalStore.maskValidator('text'),
							TooltipValid: !LoadingState && BankingTemplate.signer.name.value.trim().length === 0 && (!BankingTemplate.signer.name.visible || BankingTemplate.signer.name.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && BankingTemplate.signer.name.value.trim().length === 0 && (!BankingTemplate.signer.name.visible || BankingTemplate.signer.name.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="ssn"
						inputType={{ title: 'Signer SSN', type: 'mask', mask: GlobalStore.maskValidator('ssn'), TooltipValid: !LoadingState && BankingTemplate.signer.ssn.value.trim().length === 0 && (!BankingTemplate.signer.ssn.visible || BankingTemplate.signer.ssn.readOnly) ? messageValid : '', classNameInput: !LoadingState && BankingTemplate.signer.ssn.value.trim().length === 0 && (!BankingTemplate.signer.ssn.visible || BankingTemplate.signer.ssn.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="dob"
						inputType={{ title: 'Date of Birth', type: 'dateBox', allowedNullDate: true, mask: '', maxDate: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay()) }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="phone"
						inputType={{
							title: 'Phone Number',
							type: 'mask',
							mask: GlobalStore.maskValidator('phone'),
							TooltipValid: !LoadingState && BankingTemplate.signer.phone.value.trim().length === 0 && (!BankingTemplate.signer.phone.visible || BankingTemplate.signer.phone.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && BankingTemplate.signer.phone.value.trim().length === 0 && (!BankingTemplate.signer.phone.visible || BankingTemplate.signer.phone.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="email"
						inputType={{
							title: 'Email Address',
							type: 'mask',
							mask: GlobalStore.maskValidator('email'),
							TooltipValid:
								!LoadingState &&
								(BankingTemplate.signer.email.value.trim().length === 0 && (!BankingTemplate.signer.email.visible || BankingTemplate.signer.email.readOnly)
									? messageValid
									: !GlobalStore.emailValidation(BankingTemplate.signer.email.value) && (!BankingTemplate.signer.email.visible || BankingTemplate.signer.email.readOnly)
									? 'The email is not valid'
									: BankingTemplate.signer.email.value.trim().length !== 0 && !GlobalStore.emailValidation(BankingTemplate.signer.email.value)
									? 'The email is not valid'
									: ''),
							classNameInput:
								!LoadingState &&
								(BankingTemplate.signer.email.value.trim().length === 0 && (!BankingTemplate.signer.email.visible || BankingTemplate.signer.email.readOnly) ? 'input-error' : !GlobalStore.emailValidation(BankingTemplate.signer.email.value) && (!BankingTemplate.signer.email.visible || BankingTemplate.signer.email.readOnly) ? 'input-error' : BankingTemplate.signer.email.value.trim().length !== 0 && !GlobalStore.emailValidation(BankingTemplate.signer.email.value) ? 'input-error' : '')
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>

					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="address"
						inputType={{
							title: 'Business Address',
							type: 'mask',
							mask: GlobalStore.maskValidator('text'),
							TooltipValid: !LoadingState && BankingTemplate.signer.address.value.trim().length === 0 && (!BankingTemplate.signer.address.visible || BankingTemplate.signer.address.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && BankingTemplate.signer.address.value.trim().length === 0 && (!BankingTemplate.signer.address.visible || BankingTemplate.signer.address.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="address1"
						inputType={{
							title: 'Mailing Address',
							type: 'mask',
							mask: GlobalStore.maskValidator('text'),
							TooltipValid: !LoadingState && BankingTemplate.signer.address1.value.trim().length === 0 && (!BankingTemplate.signer.address1.visible || BankingTemplate.signer.address1.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && BankingTemplate.signer.address1.value.trim().length === 0 && (!BankingTemplate.signer.address1.visible || BankingTemplate.signer.address1.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="city"
						inputType={{ title: 'City', type: 'mask', mask: GlobalStore.maskValidator('alpha'), TooltipValid: !LoadingState && BankingTemplate.signer.city.value.trim().length === 0 && (!BankingTemplate.signer.city.visible || BankingTemplate.signer.city.readOnly) ? messageValid : '', classNameInput: !LoadingState && BankingTemplate.signer.city.value.trim().length === 0 && (!BankingTemplate.signer.city.visible || BankingTemplate.signer.city.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="country"
						inputType={{
							title: 'Country',
							type: 'select',
							mask: GlobalStore.getOptionsType('us_countries'),
							TooltipValid: !LoadingState && BankingTemplate.signer.country.value.trim().length === 0 && (!BankingTemplate.signer.country.visible || BankingTemplate.signer.country.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && BankingTemplate.signer.country.value.trim().length === 0 && (!BankingTemplate.signer.country.visible || BankingTemplate.signer.country.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="state"
						inputType={{
							title: 'State',
							type: 'select',
							mask: GlobalStore.getStates(BankingTemplate.signer.country.value),
							TooltipValid: !LoadingState && BankingTemplate.signer.state.value.trim().length === 0 && (!BankingTemplate.signer.state.visible || BankingTemplate.signer.state.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && BankingTemplate.signer.state.value.trim().length === 0 && (!BankingTemplate.signer.state.visible || BankingTemplate.signer.state.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BankingTemplate.signer}
						parentField={RootTemplate.rootTemplate.documentsData.signer}
						handleChange={(property, field, values) => handleChangeBanking('signer', property, field, values)}
						field="zip"
						inputType={{ title: 'Zip', type: 'mask', mask: GlobalStore.maskValidator('zipcode'), TooltipValid: !LoadingState && BankingTemplate.signer.zip.value.trim().length === 0 && (!BankingTemplate.signer.zip.visible || BankingTemplate.signer.zip.readOnly) ? messageValid : '', classNameInput: !LoadingState && BankingTemplate.signer.zip.value.trim().length === 0 && (!BankingTemplate.signer.zip.visible || BankingTemplate.signer.zip.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
			</div>

			{(RootTemplate.isRoot || RootTemplate.rootTemplate.documentsData.termsAndConditions) && (
				<>
					<div className="row mt-5">
						<div className="col-md-6">
							<div className="mb-2">
								<div className="col">
									<label className="d-flex flex-row align-items-center">
										<Switch
											onChange={(value) => handleChangeBanking('', 'termsAndConditions', 'visible', value)}
											checked={!LoadingState && BankingTemplate.termsAndConditions.visible}
											onColor="#10A0E3"
											onHandleColor="#ffffff"
											handleDiameter={20}
											boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
											activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
											height={20}
											width={38}
											className="react-switch"
											id={'termsAndConditionsVisible'}
										/>
										<span className="ml-2 small">{'Enable Attestation from Authorized Signer'}</span>
									</label>
								</div>
							</div>
							<DocumentLink />
						</div>
					</div>
				</>
			)}
		</>
	)
}
