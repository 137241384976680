import React from "react";
import { inject, observer } from "mobx-react";

const ApplePayButtonType = (props) => {
  function handleTextChangeSelect(e) {
    const selectedValue = e.target.value;
    props.vTerminal.setApplePayButton({ buttonType: selectedValue });
  }
  return (
    <div>
        <div className="col-sm mb-3">
          <div className="form-floating">
            <select
              className="form-control"
              style={{ appearance: 'none'}}
              id="appleButtonType"
              name="appleButtonType"
              onChange={handleTextChangeSelect}
              value={
                props.vTerminal.paymentPage.paymentMethods.settings.applePay
                  .buttonType
              }
              data-qaid="appleButtonTypeInput"
            >
              {props.applepay.applePayButtonTypes.map((type) => (
                <option
                  key={type.value}
                  value={type.value}
                  title={type.description}
                >
                  {type.text}
                </option>
              ))}
            </select>
            <label htmlFor="appleButtonType">Type</label>
          </div>
        </div>
      </div>
  )
}

export default inject(
  "vTerminal",
  "applepay"
)(observer(ApplePayButtonType));
