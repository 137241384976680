import React from "react";
import { inject, observer } from "mobx-react";

export const ApplePayButtonLanguage = inject(
  "vTerminal",
  "applepay"
)(
  observer((props) => {
    function handleTextChangeSelect(e) {
      const selectedValue = e.target.value;
      props.vTerminal.setApplePayButton({ language: selectedValue });
    }
    return (
      <div>
        <div className="col-sm mb-3">
          <div className="form-floating">
            <select
              className="form-control"
              style={{ appearance: "none" }}
              id="appleButtonLanguage"
              name="appleButtonLanguage"
              onChange={handleTextChangeSelect}
              value={
                props.vTerminal.paymentPage.paymentMethods.settings.applePay
                  .language
              }
              data-qaid="appleButtonLanguageInput"
            >
              {props.applepay.applePayButtonLanguages.map((language) => (
                <option
                  key={language.value}
                  value={language.value}
                  title={language.description}
                >
                  {language.text}
                </option>
              ))}
            </select>
            <label htmlFor="appleButtonLanguage">Language</label>
          </div>
        </div>
      </div>
    );
  })
);
