import React from "react";
import { inject, observer } from "mobx-react";

export const GooglePayButtonLanguage = inject(
  "vTerminal",
  "googlepay"
)(
  observer((props) => {
    function handleTextChangeSelect(e) {
      const selectedValue = e.target.value;
      props.vTerminal.setGooglePayButton({ language: selectedValue });
    }
    return (
      <div>
        <div className="col-sm mb-3">
          <div className="form-floating">
            <select
              className="form-control"
              style={{ appearance: "none" }}
              id="googleButtonLanguage"
              name="googleButtonLanguage"
              onChange={handleTextChangeSelect}
              value={
                props.vTerminal.paymentPage.paymentMethods.settings.googlePay
                  .language
              }
              data-qaid="googleButtonLanguageInput"
            >
              {props.googlepay.googlePayButtonLanguages.map((language) => (
                <option
                  key={language.value}
                  value={language.value}
                  title={language.description}
                >
                  {language.text}
                </option>
              ))}
            </select>
            <label htmlFor="googleButtonLanguage">Language</label>
          </div>
        </div>
      </div>
    );
  })
);
