import React from "react";
import { inject, observer } from "mobx-react";

const GooglePayButtonType = (props) => {
  function handleTextChangeSelect(e) {
    const selectedValue = e.target.value;
    props.vTerminal.setGooglePayButton({ buttonType: selectedValue });
  }
  return (
    <div>
        <div className="col-sm mb-3">
          <div className="form-floating">
            <select
              className="form-control"
              style={{ appearance: 'none'}}
              id="googleButtonType"
              name="googleButtonType"
              onChange={handleTextChangeSelect}
              value={
                props.vTerminal.paymentPage.paymentMethods.settings.googlePay
                  .buttonType
              }
              data-qaid="googleButtonTypeInput"
            >
              {props.googlepay.googlePayButtonTypes.map((type) => (
                <option
                  key={type.value}
                  value={type.value}
                  title={type.description}
                >
                  {type.text}
                </option>
              ))}
            </select>
            <label htmlFor="googleButtonType">Type</label>
          </div>
        </div>
      </div>
  )
}

export default inject(
  "vTerminal",
  "googlepay"
)(observer(GooglePayButtonType));
