import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../../components/TopBar';
import { MainTopBarMenu } from '../../../components/MainTopBarMenu';
import { ToastContainer , toast, Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReportPaginator } from '../../../components/ReportPaginator';
import { BoardingLinks } from '../../../components/BoardingLinks';
import { Layout } from '../../../components/Layout'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Modal, Button } from "react-bootstrap";
import axios from 'axios';
import { PayabliStorageManager } from '../../../api/localStorageManager'
import { ParentFilterPanel } from '../../../components/filters/ParentFilterPanel';
import { RecordsNotFound } from '../../../components/RecordsNotFound';
import {IMaskInput} from 'react-imask';
import { BiCopyAlt, BiDotsVerticalRounded, BiLinkExternal, BiTrash, BiSortAlt2 } from 'react-icons/bi'
import { MainBar } from '../../../components/MainBar';
import NotesEventsTooltip from '../../../components/Applications/NotesEventsTooltip';
import NotesModal from './NotesModal'
import ManagementApplication from '../../../store/Applications/ManagementApplication'
import SendEmailModal from './SendEmailModal'

@inject('reports','store','global','boarding')
@observer
class Applications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            sortDirection: "desc",
            orgId:0,
            cancelAppModalIsOpen: false,
            saveEmailModalIsOpen:false,
            rootTemplate:false,
            visibleSearch: false,
            appIdToModify: null,
            appType: null,
            appemail:'',
            quickviewUrl:null,
            applicationEmailModalIsOpen: false,
            applicationCopyLinkModalIsOpen: false,
            applicationLinkEmail: '',
            applicationLinkEmailError: false,
            idAppLink: null,
            appLink: "",
            flagItemAllColumn: true,
            referenceId: "",
            showModalNotesEvent: false,
            notesEvents:[],
            showingNotesEvent: false,
            loadedAppId:{id:null, lastTimeloadedAppId: new Date()},
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);        
        this.openCancelAppModal = this.openCancelAppModal.bind(this);
        this.closeCancelAppModal = this.closeCancelAppModal.bind(this);
        this.cancelAppAction = this.cancelAppAction.bind(this);
        this.getRootTemplate = this.getRootTemplate.bind(this);
        this.saveEmailModal = this.saveEmailModal.bind(this);
        this.closeSaveEmailModal = this.closeSaveEmailModal.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.openApplicationEmailModal = this.openApplicationEmailModal.bind(this);
        this.closeApplicationEmailModal = this.closeApplicationEmailModal.bind(this);
        this.handleCopyApplicationEmail = this.handleCopyApplicationEmail.bind(this);
        this.getApplicationLink = this.getApplicationLink.bind(this);
        this.copyAppLink = this.copyAppLink.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.toggleVisibleSearchBar = this.toggleVisibleSearchBar.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.sortBy = this.sortBy.bind(this);
    }

    getMappingFields(){
        return {
            'OrgParentName': 'orgparentname',
            'LegalName': 'legalname',
            'DbaName': 'dbaname',
            'Ein': 'ein',
            'BoardingStatus': 'status',
            'CreatedAt': 'createdat',
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }


    toggleVisibleSearchBar(e){
        this.setState({visibleSearch: !this.state.visibleSearch});
    }

    componentDidMount() {
        this.props.global.protect(this.props.history);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let orgId = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId;
        this.setState({ head: "", orgId: orgId });
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                OrgParentName   : { label: 'Organization', class: '', display: true, filter:"orgparentname" },
                IdApplication   : { label: 'Application ID', class: '', display: true },
                LegalName       : { label: 'Legal Name', class: '', display: true, filter: "legalname" },
                DbaName         : { label: 'DBA Name', class: '', display: true, filter: "dbaname" },
                ContactName     : { label: 'Contact Name', class: '', display: false },
                ContactEmail    : { label: 'Contact Email', class: '', display: false },
                Ein             : { label: 'EIN', class: '', display: true, filter: "ein" },
                BoardingStatus  : { label: 'Status', class: '', display: true, filter: "status" },
                CreatedAt       : { label: 'Created At', class: '', display: true, filter:"createdAt" },
                LastModified    : { label: 'Last Modified', class: '', display: true },
				NotesAndEvents: { label: 'Notes & Events', class: '', display: true }
            }
        )
        this.getRootTemplate();
        this.props.reports.setRecords([]);
        // this.clearFilters(); 
        // if(this.props.global.globalSearchbarFilters){

        //     this.props.reports.filters = this.props.global.globalSearchbarFilters
        // }
        this.getReportFromApi();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    copyToClipboard(key){
        navigator.clipboard.writeText(key);
        toast.success("Text copied successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-success-container',
        });
    }

    copyAppLink(idApp){
        this.setState({idAppLink: idApp, applicationLinkEmail: ''});
        this.openApplicationEmailModal();
    }

    getApplicationLink(){
        if(this.state.applicationLinkEmailError || (!this.state.applicationLinkEmail || this.state.applicationLinkEmail === "")){
            return;
        }

        this.props.global.setLoading(true);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.put(process.env.REACT_APP_URL_API + 'Boarding/applink/' + this.state.idAppLink + "/"+this.state.applicationLinkEmail, null, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }
        })
        .then(res => {
           if(res && res.data.responseData){
               
               let data = res.data.responseData;
               this.setState({
                    appLink: data.appLink,
                    referenceId: data.referenceId
               }, function(){
                   this.closeApplicationEmailModal();
                   this.openApplicationCopyLinkModal();
               });
           }
           this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });        
    }

    handleCopyApplicationEmail(value){
        this.setState({ applicationLinkEmail: value });
        if(!this.props.global.validators.isEmpty(value) && !this.props.global.validators.stringValidator('email',value)){
            this.setState({ applicationLinkEmailError: false });
        }
        else{
            this.setState({ applicationLinkEmailError: true });
        }
    }

    closeApplicationEmailModal(){
        this.setState({applicationEmailModalIsOpen: false})
    }
    
    openApplicationEmailModal(){
        this.setState({applicationEmailModalIsOpen: true})
    }
    
    openApplicationCopyLinkModal(){
        this.setState({applicationCopyLinkModalIsOpen: true})
    }
    closeApplicationCopyLinkModal(){
        this.setState({applicationCopyLinkModalIsOpen: false})
    }

    handleShowColumn(event) {
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }

    handleEmailChange(value) {
        this.setState({ appemail: value });
    }

    exportFile(format) {
        this.props.reports.exportAllDataFile('boarding', format, this.state.orgId);
    }

    clearFilters() {
        this.setState({ head: "" }, () => {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    showHideFilters() {
        this.props.global.setFilterRightPanelOpen(true);
    }

    handleQuickFilter(value) {
        if (value === 'incomplete') {
            this.setState({ head: value, appType: -99 });
            this.filter('status(eq)', -99)
        }
        else if (value === 'pending') {
            this.setState({ head: value, appType: 2 });
            this.filter('status(eq)', 2)
        }
        else if (value === 'underwriting') {
            this.setState({ head: value, appType: 3 });
            this.filter('status(eq)', 3)
        }
        else if (value === 'submitted') {
            this.setState({ head: value, appType: 4 });
            this.filter('status(eq)', 4)
        }
        else if (value === 'manual') {
            this.setState({ head: value, appType: 6 });
            this.filter('status(eq)', 6)
        }
        else if (value === 'approved') {
            this.setState({ head: value, appType: 7 });
            this.filter('status(eq)', 7)
        }
        else if (value === 'withdraw') {
            this.setState({ head: value, appType: 8 });
            this.filter('status(eq)', 8)
        }
        else if (value === 'deaactivated') {
            this.setState({ head: value, appType: 9 });
            this.filter('status(eq)', 9)
        }
        else if (value === 'boarding') {
            this.setState({ head: value, appType: 10 });
            this.filter('status(eq)', 10)
        }
        else if (value === 'activated') {
            this.setState({ head: value, appType: 99 });
            this.filter('status(eq)', 99)
        }
        else if (value === 'live') {
            this.setState({ head: value, appType: 100 });
            this.filter('status(eq)', 100)
        }
        else if (value === 'declined') {
            this.setState({ head: value, appType: 0 });
            this.filter('status(eq)', 0)
        }
        else if (value === 'exception') {
            this.setState({ head: value, appType: -1 });
            this.filter('status(eq)', -1)
        }
        else {
            this.clearFilters();
        }
    }

    filter(type, value) {
        this.props.global.setLoading(true);
        this.props.reports.filterOrg(type, value, 'boarding').then(res => {
            this.props.global.setLoading(false);
        })
            .catch(error => {
                this.props.global.setLoading(false);
            });
    }

    clearFilter(e, filter, filterType) {
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter, "");
    }

    getReportFromApi() {
         if(this.state.sortBy){
            this.filter("sortBy", `${this.state.sortDirection}(${this.state.sortBy})`)}
        this.props.global.setLoading(true);
        this.props.reports.getReportOrgFromApi('boarding').then(res => {
            this.props.global.setLoading(false);
        })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
    }

    saveToComplete() {
        this.props.global.setLoading(true);
        this.props.boarding.sendLinkApplication(this.state.appIdToModify, this.state.appemail).then(res => {
            this.props.global.setLoading(false);
            toast.success("Application Link sent!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
            });
        }).catch(error => {
            this.props.global.setLoading(false);
            toast.error('Oops! Something went wrong sending email!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
        this.setState({ saveEmailModalIsOpen: false, appemail: '' });
    }

    getRootTemplate() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let orgId = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId;

        return axios.get(process.env.REACT_APP_URL_API + 'Templates/master/' + orgId, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }
        })
            .then(res => {
                this.setState({ rootTemplate: true, orgId: orgId });
            })
            .catch(error => {
                this.setState({ rootTemplate: false, orgId: orgId });
            });
    }

    cancelApp(idApp) {
        this.setState({ appIdToModify: idApp });
        this.openCancelAppModal();
    }

    sendLinkToApp(idApp) {
        this.setState({ appIdToModify: idApp, saveEmailModalIsOpen: true });
    }
    saveEmailModal() {
        if (this.state.appemail === null || this.state.appemail === '' || this.state.appemail.length <= 0) return;
        if (!this.props.global.emailValidation(this.state.appemail)) return;
        this.saveToComplete()
    }
    closeSaveEmailModal() {
        this.setState({ saveEmailModalIsOpen: false, appemail: '' });
    }

    openCancelAppModal() {
        this.setState({ cancelAppModalIsOpen: true });
    }

    closeCancelAppModal() {
        this.setState({ cancelAppModalIsOpen: false });
    }

    cancelAppAction() {
        let appIdToDelete = this.state.appIdToModify;
        if (appIdToDelete) {
            this.props.global.setLoading(true);
            this.props.reports.cancelApp(appIdToDelete)
                .then(result => {
                    this.setState({ cancelAppModalIsOpen: false });
                    this.props.global.setLoading(false);
                    toast.success("Application deleted successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.getReportFromApi();
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
        }
    }

    viewRecordDetails(url, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            this.props.history.push(url);
        }
    }

    getFiltersDropdown(CustomHeadToggle){
        return(
            <div className="mr-3 inline">
                <DropdownButton
                    as={CustomHeadToggle}
                    menuAlign="left"
                    title={<h6 data-qaid="allApplicationsReportsPage">{this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "All Applications"}</h6>}
                    size="sm"
                    variant=""
                >
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('incomplete')}>Incomplete</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('inprocess')}>In Process</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('pending')}>Pending Signature</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('underwriting')}>Underwriting</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('boarding')}>Boarding</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('activated')}>Activated</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('live')}>Live</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('declined')}>Declined</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('hold')}>Hold</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => this.handleQuickFilter('')}>All Applications</Dropdown.Item>
                </DropdownButton>
                </div>
        )
    }

    render() {
        const CustomHeadToggle = React.forwardRef(({ children, onClick }, ref) => (
            <>
                {children}
            </>

        ));
        
        return (
            <><Layout {...this.props}>
                <Modal style={{ textAlign: "center" }} show={this.state.cancelAppModalIsOpen} onHide={this.closeCancelAppModal} size="sm" centered>
                    <Modal.Body>
                        <BiTrash className="icon-modal" />
                        <h5 data-qaid="applicationDeletePopUp">Delete</h5>
                        <p className="small">Are you sure you want to delete this application?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeCancelAppModal()} data-qaid="cancelDeleteButton">
                        Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="danger" onClick={(e) => this.cancelAppAction()}>
                            Delete
                        </Button>
                    </Modal.Body>
                </Modal>
                {this.state.saveEmailModalIsOpen && <SendEmailModal saveEmailModalIsOpen={this.state.saveEmailModalIsOpen} closeSaveEmailModal={this.closeSaveEmailModal} saveEmailModal={this.saveEmailModal}
                                appemail={this.state.appemail} handleEmailChange={(value)=> this.handleEmailChange(value)}/>}
                
                <Modal style={{textAlign: "center"}} show={this.state.applicationEmailModalIsOpen} onHide={this.closeApplicationEmailModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiLinkExternal className="icon-modal"/>
                    <h5 data-qaid="appLinkPopUp">App. Link</h5>
                    <p className="small">Enter an email to create a link for this application.</p>
                    <p className="small-grey">{this.state.transIdToSendReceipt}</p>

                    <IMaskInput
                        mask={this.props.global.maskValidator('email')}
                        value={this.state.applicationLinkEmail}
                        unmask={true}
                        placeholder= "Email"
                        data-qaid="inputEmailApplication"
                        className={this.state.applicationLinkEmailError ? "form-control mb-3 input-error" : "form-control mb-3" }                        
                        onAccept={
                            (value, mask) => this.handleCopyApplicationEmail(value)
                        }
                    />
                    <div className="row">
                        <div className="col">
                            <Button className="btn full-w  cancel-btn" variant="default" onClick={this.closeApplicationEmailModal}>
                                Cancel
                            </Button>
                        </div>
                        <div className="col">
                            <Button className="btn full-w" variant="primary" onClick={this.getApplicationLink} data-qaid="getAppLinkButton">
                                Get
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                </Modal>

                <Modal style={{ textAlign: "left" }} show={this.state.applicationCopyLinkModalIsOpen} onHide={this.closeCancelAppModal} size="md" centered>
                    <Modal.Body>
                        <h5 data-qaid="appLinkReferenceIdPopUp">App. Link & Reference ID</h5>
                        <p className="small">Please copy the below values by clicking on the icons to the left.</p>

                        <p className="small"><BiCopyAlt onClick={()=>this.copyToClipboard(this.state.appLink)} className="cursor-pointer" style={{fontSize: "16px"}}/>&nbsp;&nbsp;&nbsp;{this.state.appLink}</p>
                        <p className="small"><BiCopyAlt onClick={()=>this.copyToClipboard(this.state.referenceId)} className="cursor-pointer" style={{fontSize: "16px"}}/>&nbsp;&nbsp;&nbsp;{this.state.referenceId}</p>

                        <Button className="btn cancel-btn full-w mt-2" variant="default" onClick={(e) => this.closeApplicationCopyLinkModal()} data-qaid="cancelAppLinkButton">
                        Cancel
                        </Button>
                    </Modal.Body>
                </Modal>
                    <div>
                        <ParentFilterPanel report={'boarding'} />
                        <TopBar>
                            <MainTopBarMenu />
                            <div className="top-bar-sub">
                                <BoardingLinks selected="applications" />
                            </div>
                        </TopBar>
                        <div className="mt-body4">
                            <MainBar
                                btnNewName="New Application" 
                                newLink={`/${PayabliStorageManager.getEntryName()}/boarding/newapptpl/0/${this.state.orgId}`}
                                reportNameComponent={(e) => this.getFiltersDropdown(CustomHeadToggle)}
                                dataQAName="Applications"
                                reportTitle="Applications"
                                showHideFilters={this.showHideFilters}
                                selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                                handleShowColumn={(e) => this.handleShowColumn(e)}
                                rootTemplate={this.state.rootTemplate}
                                totalRecords={this.props.reports.totalRecords}
                                getHeaders={this.props.reports.getHeaders}
                                headers={this.props.reports.headers}
                                refreshView={this.clearFilters}
                                buttonExport={true}
                                searchBar={false}
                                exportFile={this.exportFile}
                                reports={this.props.reports}
                                report={"boarding"}
                                flagItemAllColumn={this.state.flagItemAllColumn}
                            />
                            <div className="report-container">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            {this.props.reports.getHeaders.map((record, i) => (
                                                (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                                                    record[1].display && 
                                                    (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                                        <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                                                    </th> ))
                                                    ||
                                                    (record[1].display && 
                                                    (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                                                    </th> ))
                                            ))}
                                            <th className='text-center sticky-row' scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.reports.records.map((record, i) => (
                                            <tr key={i} onMouseUp={(e)=> this.props.global.setCoordClickUp(e)} onMouseDown={(e)=> this.props.global.setCoordClickDown(e)} className="cursorPointer" onClick={(e) => !this.state.showingNotesEvent && this.viewRecordDetails("/" + this.props.global.getURLEntry() + "/boarding/viewapplication/" + record.IdApplication,e)} data-qaid={"appRow-"+(i)}>
                                                {(this.props.reports.headers.OrgParentName && this.props.reports.headers.OrgParentName.display) &&
                                                    <td>{record.OrgParentName ? this.props.global.capitalizeFirstLetterOfEachWord(record.OrgParentName) : '-'}</td>}
                                                {(this.props.reports.headers.IdApplication && this.props.reports.headers.IdApplication.display) &&
                                                    <td>{record.IdApplication ? record.IdApplication : '-'}</td>}
                                                {(this.props.reports.headers.LegalName && this.props.reports.headers.LegalName.display) &&
                                                    <td>{record.LegalName ? record.LegalName : '-'}</td>}
                                                {(this.props.reports.headers.DbaName && this.props.reports.headers.DbaName.display) &&
                                                    <td>{record.DbaName ? record.DbaName : '-'}</td>}
                                                {(this.props.reports.headers.ContactName && this.props.reports.headers.ContactName.display) &&
                                                    <td>{record.ContactData.length && record.ContactData[0].ContactName && record.ContactData[0].ContactName !== '' ? record.ContactData[0].ContactName : '-'}</td>}
                                                {(this.props.reports.headers.ContactEmail && this.props.reports.headers.ContactEmail.display) &&
                                                    <td>{record.ContactData.length && record.ContactData[0].ContactEmail && record.ContactData[0].ContactEmail !== '' ? record.ContactData[0].ContactEmail : '-'}</td>}
                                                {(this.props.reports.headers.Ein && this.props.reports.headers.Ein.display) &&
                                                    <td>{record.Ein ? this.props.global.maskedNumber(record.Ein) : '-'}</td>}
                                                {(this.props.reports.headers.BoardingStatus && this.props.reports.headers.BoardingStatus.display) &&
                                                    <td>
                                                        {this.props.global.getAppStatusTextV1(record.BoardingStatus)}
                                                    </td>}
                                                {(this.props.reports.headers.CreatedAt && this.props.reports.headers.CreatedAt.display) &&
                                                    <td>{this.props.global.stringDateFormatV3(record.CreatedAt)}</td>}
                                                {(this.props.reports.headers.LastModified && this.props.reports.headers.LastModified.display) &&
                                                    <td>{this.props.global.stringDateFormatV3(record.LastModified)}</td>}
												{this.props.reports.headers.NotesAndEvents && this.props.reports.headers.NotesAndEvents.display && (
													<td
														className="d-flex w-100 justify-content-center align-items-center">
                                                        <ManagementApplication>
														    <NotesEventsTooltip appId={record.IdApplication ? record.IdApplication : 0}
                                                                                notesEvents ={this.state.notesEvents}
                                                                                showingNotesEvent ={(state)=> this.setState({ showingNotesEvent: state })}
                                                                                setNotesEvents={(data)=> this.setState({ notesEvents: data })}
                                                                                loadedAppId={this.state.loadedAppId}
                                                                                SetLoadedAppId={(id)=> this.setState({ loadedAppId: { id: id, lastTimeloadedAppId: new Date() } })}
                                                                                openModal={()=> this.setState({ showModalNotesEvent: true, showingNotesEvent: false })}
                                                                                />
                                                        </ManagementApplication>
													</td>
												)}	
                                                <td className='text-center sticky-row'>
                                                    <DropdownButton
                                                        menuAlign="right"
                                                        title={<BiDotsVerticalRounded/>}
                                                        data-qaid={"actionApp-"+(record && record.LegalName)}
                                                        id="actionsMenuButton"
                                                        size="sm"
                                                        variant="default"
                                                    >
                                                        <Dropdown.Item id="viewDetailsDropDownItem" onClick={() => this.viewRecordDetails("/" + this.props.global.getURLEntry() + "/boarding/viewapplication/" + record.IdApplication)} data-qaid="applicationViewDetails">View Details</Dropdown.Item>
                                                        {(record.BoardingStatus < 1) &&
                                                            <>
                                                            {(record.BoardingStatus !== 10 && record.BoardingStatus !== 99 && record.BoardingStatus !== 100 && record.BoardingStatus !== 0) &&
                                                            <Dropdown.Item onClick={(e) => this.props.history.push("/" + this.props.global.getURLEntry() + "/boarding/applicationedit/" + record.IdApplication)} data-qaid="applicationEdit">Edit</Dropdown.Item>
                                                            }
                                                                <Dropdown.Item onClick={(e) => this.sendLinkToApp(record.IdApplication)} data-qaid="applicationSendLink">Send link via Email</Dropdown.Item>
                                                                <Dropdown.Item onClick={(e) => this.copyAppLink(record.IdApplication)} data-qaid="applicationCopyAppLink">Copy App. Link</Dropdown.Item>
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item style={{ color: "rgb(192, 0, 0)" }} onClick={(e) => this.cancelApp(record.IdApplication)} data-qaid="applicationDelete">Delete</Dropdown.Item>
                                                            </>}
                                                    </DropdownButton>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {this.props.reports.records.length < 1 &&
                                    <RecordsNotFound btnLink={`/${this.props.global.getURLEntry()}/boarding/newapptpl/0/${this.state.orgId}`} btnText={"+ New Application"} message="No applications just yet" description={<span>New merchant applications will appear here in real time <br/>so you can check their progress and status.</span>}/>
                                }
                        </div>
                        <ReportPaginator report="boarding" mode={this.state.orgId} option={1} />
                        </div>
                        <ToastContainer transition={Bounce} />
                    </div>
                
                <NotesModal showModal= {this.state.showModalNotesEvent} closeModal ={()=> this.setState({ showModalNotesEvent: false, showingNotesEvent: false })} notesEvents ={this.state.notesEvents}/>

                </Layout></>
        )
    }
}

export { Applications };