import { action } from 'mobx'

export default function ServiceFunctionHandle({ service, setService }) {
	const handleChangeServices = action((serviceType, serviceName, indexService, id, field, value, connector = false, currency = false, idexCurrency, bankTier = false, fielBankTier = '', indexBankTier, payorPrice = false, credentials = false, updatePurpose = false, updateGateways = true, configuration = false) => {
		const newData = service[serviceType][serviceName].map((data, index) => {
			if (index === indexService) {
				if (updateGateways) {
					const newDataGateway = data.Gateways.map((dataGateway) => {
						if (dataGateway.Id === id) {
							if (connector) {
								return {
									...dataGateway,
									Connector: {
										...dataGateway.Connector,
										[field]: value
									}
								}
							} else if (configuration) {
								return {
									...dataGateway,
									Connector: {
										...dataGateway.Connector,
										configuration: {
											...dataGateway.Connector.configuration,
											[field]: value
										}
									}
								}
							} else if (credentials) {
								return {
									...dataGateway,
									Connector: {
										...dataGateway.Connector,
										configuration: {
											...dataGateway.Connector.configuration,
											credentials: {
												...dataGateway.Connector.configuration.credentials,
												[field]: value
											}
										}
									}
								}
							} else if (bankTier) {
								if (fielBankTier === 'priceFilter') {
									const newTiers = dataGateway.Tiers.map((dataTiers, idx) => {
										if (idx === indexBankTier) {
											const newPriceFilter = dataGateway.Tiers[idx].payorPrice[fielBankTier].map((priceFilterData, idxP) => {
												if (idxP === idexCurrency) {
													return {
														...priceFilterData,
														[field]: value
													}
												}
												return priceFilterData
											})
											return {
												...dataTiers,
												payorPrice: {
													...dataTiers.payorPrice,
													[fielBankTier]: newPriceFilter
												}
											}
										}
										return dataTiers
									})
									return {
										...dataGateway,
										Tiers: newTiers
									}
								} else {
									const newBankTier = dataGateway[fielBankTier].map((dataBankTier, indexY) => {
										if (indexY === indexBankTier) {
											if (!payorPrice) {
												if (!updatePurpose) {
													return {
														...dataBankTier,
														[field]: value
													}
												}
												return {
													...dataBankTier,
													[field]: value,
													bankAccountFunction: 3
												}
											}
											return {
												...dataBankTier,
												payorPrice: {
													...dataBankTier.payorPrice,
													[field]: value
												}
											}
										}
										return dataBankTier
									})
									return {
										...dataGateway,
										[fielBankTier]: newBankTier
									}
								}
							} else if (currency) {
								const newCurrency = dataGateway.Currencies.map((dataCurrency, indexK) => {
									if (indexK === idexCurrency) {
										return value
									}
									return dataCurrency
								})
								return {
									...dataGateway,
									Currencies: newCurrency
								}
							} else if (field === 'GatewayName') {
								return {
									...dataGateway,
									GatewayName: value,
									Connector: {
										...dataGateway.Connector,
										Name: ''
									}
								}
							} else {
								return {
									...dataGateway,
									[field]: value
								}
							}
						}
						return dataGateway
					})
					return {
						...data,
						Gateways: newDataGateway
					}
				} else {
					return {
						...data,
						[field]: value
					}
				}
			}
			return data
		})
		setService({
			...service,
			[serviceType]: {
				...service[serviceType],
				[serviceName]: newData
			}
		})
	})
	const handleChangeServicesHS = action((field, value) => {
		setService({ ...service, [field]: value })
	})
	const addPriceFilter = action((serviceType, serviceName, indexService, id, tierIdx) => {
		const newData = service[serviceType][serviceName].map((data, index) => {
			if (index === indexService) {
				const newDataGateway = data.Gateways.map((dataGateway) => {
					if (dataGateway.Id === id) {
						const newTiers = dataGateway.Tiers.map((dataTiers, idx) => {
							if (idx === tierIdx) {
								if (dataTiers.payorPrice?.priceFilter === null) {
									return {
										...dataTiers,
										payorPrice: {
											...dataTiers.payorPrice,
											priceFilter: [{ cardType: 'DEBIT', cardBrand: 'MASTERCARD', cardBin: '' }]
										}
									}
								} else {
									return {
										...dataTiers,
										payorPrice: {
											...dataTiers.payorPrice,
											priceFilter: [...dataTiers.payorPrice.priceFilter, { cardType: 'DEBIT', cardBrand: 'MASTERCARD', cardBin: '' }]
										}
									}
								}
							}
							return dataTiers
						})
						return {
							...dataGateway,
							Tiers: newTiers
						}
					}
					return dataGateway
				})
				return {
					...data,
					Gateways: newDataGateway
				}
			}
			return data
		})
		setService({
			...service,
			[serviceType]: {
				...service[serviceType],
				[serviceName]: newData
			}
		})
	})

	const deletePriceFilter = action((serviceType, serviceName, indexService, id, tierIdx, idxPrice) => {
		const newData = service[serviceType][serviceName].map((data, index) => {
			if (index === indexService) {
				const newDataGateway = data.Gateways.map((dataGateway) => {
					if (dataGateway.Id === id) {
						const newTiers = dataGateway.Tiers.map((dataTiers, idx) => {
							if (idx === tierIdx) {
								const newPriceFilter = dataTiers.payorPrice.priceFilter.filter((_, idxP) => idxP !== idxPrice)
								return {
									...dataTiers,
									payorPrice: {
										...dataTiers.payorPrice,
										priceFilter: newPriceFilter
									}
								}
							}
							return dataTiers
						})
						return {
							...dataGateway,
							Tiers: newTiers
						}
					}
					return dataGateway
				})
				return {
					...data,
					Gateways: newDataGateway
				}
			}
			return data
		})
		setService({
			...service,
			[serviceType]: {
				...service[serviceType],
				[serviceName]: newData
			}
		})
	})

	return {
		handleChangeServices,
		handleChangeServicesHS,
		addPriceFilter,
		deletePriceFilter
	}
}
