
import React from 'react';
import { inject, observer } from 'mobx-react';
import {toast} from 'react-toastify';
import { Dropdown, DropdownButton } from 'react-bootstrap';

@inject('boarding','global')
@observer
class ApplicationStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appStatus: "",
            appSubstatus: ""
        };
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.saveStatus = this.saveStatus.bind(this);
    }

    componentDidMount(){
        if(this.props.idApp){
            this.setState({
                appStatus: this.props.status,
                appSubstatus: this.props.subStatus});
        }
    }

    handleStatusChange(status, subStatus){
        this.props.boarding.setTemplateData('boardingStatus', status);
        this.setState({
            appStatus: status,
            appSubstatus: subStatus || ""
        });
    }

    saveStatus(e){
        e.preventDefault();
        this.props.global.setLoading(true);
        this.props.boarding.updateApplicationStatus(this.props.idApp, this.state.appStatus, this.state.appSubstatus).then(res => {
            this.props.changeStatus(this.state.appStatus, this.state.appSubstatus);
            toast.success("Status changed successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
            });
            this.props.global.setLoading(false);
        }).catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    render() {
       
        return (
            <div className="row popover-body" style={{ padding: 0 }}>
                <div className="col-sm-7">
                    <div className="form-floating mb-4">
                        <DropdownButton
                            id="dropdown-basic-button"
                            title={
                                applicationStatus.find(status => status.idStatus === this.state.appStatus)?.subStatus?.find(sub => sub.idSubStatus === this.state.appSubstatus)?.label ||
                                applicationStatus.find(status => status.idStatus === this.state.appStatus)?.label ||
                                "Unknown Status"
                            }
                            variant="outline-primary"
                        >
                            {applicationStatus.map(status => (
                                <React.Fragment key={status.idStatus}>
                                    {status.subStatus ? (
                                        <Dropdown drop="right" className="actions-btn-cont w-100">
                                            <Dropdown.Toggle
                                                as="div"
                                                className="dropdown-item"
                                                style={{
                                                    fontSize: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                {status.label}
                                                <span
                                                    className="dropdown-caret"
                                                    style={{
                                                        display: 'inline-block',
                                                        fontSize: '5px',
                                                        marginLeft: '5px',
                                                    }}
                                                >
                                                    ►
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {status.subStatus.map(sub => (
                                                    <Dropdown.Item
                                                        key={sub.idSubStatus}
                                                        style={{ fontSize: '12px' }}
                                                        onClick={() => this.handleStatusChange(status.idStatus, sub.idSubStatus)}
                                                    >
                                                        {sub.label}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        <Dropdown.Item
                                            style={{ fontSize: '12px' }}
                                            onClick={() => this.handleStatusChange(status.idStatus, null)}
                                        >
                                            {status.label}
                                        </Dropdown.Item>
                                    )}
                                </React.Fragment>
                            ))}
                        </DropdownButton>
                    </div>
                </div>
                <div className="col-sm-5">
                    <button onClick={(e) => this.saveStatus(e)} className="btn btn-outline-primary full-w">Update Status</button>
                </div>
            </div>
        )
    }
}

const applicationStatus = [
	{
		idStatus: -99,
		label: "Not Submitted",
	},
	{
		idStatus: 0,
		label: "Declined",
		subStatus: [
			{
				idSubStatus: 9,
				label: "Standard Decline",
			},
			{
				idSubStatus: 10,
				label: "Credit Decline",
			},
			{
				idSubStatus: 11,
				label: "Processor Decline",
			},
		],
	},
	{
		idStatus: 2,
		label: "Pending Signature",
	},
	{
		idStatus: 3,
		label: "Underwriting",
	},
	{
		idStatus: 4,
		label: "Submitted",
	},
	{
		idStatus: 6,
		label: "Manual Review",
		subStatus: [
			{
				idSubStatus: 1,
				label: "Pending Review",
			},
			{
				idSubStatus: 2,
				label: "Pending Documentation",
			},
			{
				idSubStatus: 3,
				label: "Pending Action",
			},
			{
				idSubStatus: 4,
				label: "Missing Data",
			},
		],
	},
	{
		idStatus: 7,
		label: "Approved",
	},
	{
		idStatus: 8,
		label: "Withdrawn",
	},
	{
		idStatus: 10,
		label: "Boarding",
		subStatus: [
			{
				idSubStatus: 5,
				label: "Service Provider Review",
			},
			{
				idSubStatus: 6,
				label: "Service Provider Processing",
			},
			{
				idSubStatus: 7,
				label: "Service Provider Hold",
			},
			{
				idSubStatus: 8,
				label: "Pending Configuration",
			},
		],
	},
	{
		idStatus: 99,
		label: "Activated",
	},
	{
		idStatus: 100,
		label: "Live",
	},
	{
		idStatus: -1,
		label: "Exception Error",
	},
];

export { ApplicationStatus }