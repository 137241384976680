/* eslint-disable no-loop-func */
import { servicePOST, servicePUT, serviceDELETE } from '../../Connections/ServicesConnections'
import { updateMerchantBankAccountPUT } from '../../Connections/BankConnections'

import { toast } from 'react-toastify'
import { action } from 'mobx'

export default function ServicesFunctionAPI({ service, payPoint, selectGateway, structureService, selectProcess, loadPaypointService, getValuesForBankGP, banking }) {
	const updateBankServiceGP = action((bankData, gatewayId = null) => {
		const serviceCard = { moneyin: ['card', 'ach', 'check', 'cloud', 'cash', 'wallet'], moneyout: ['managed', 'ach', 'check', 'vcard', 'pushToCard'] }
		let newBank = [],
			serviceGP = []

		/* create new bank */
		bankData.forEach((dataBank) => {
			newBank.push({
				accountNumber: dataBank.accountNumber,
				bankAccountFunction: dataBank.bankAccountFunction,
				bankAccountHolderName: dataBank.bankAccountHolderName,
				bankAccountHolderType: dataBank.bankAccountHolderType,
				bankName: dataBank.bankName,
				id: gatewayId === null ? dataBank.idSelect : dataBank.id,
				routingAccount: dataBank.routingAccount,
				typeAccount: dataBank.typeAccount
			})
		})

		for (let serviceType in serviceCard) {
			serviceCard[serviceType].forEach((type) =>
				service[serviceType][type].forEach((dataService) => {
					if (dataService.Gateways.find((gateway) => gateway.Connector.Name.toLowerCase() === 'gp')) {
						const newGateways = []

						/* add new Gateways */
						dataService.Gateways.forEach((gateway) => {
							if (gateway.Connector.Name.toLowerCase() === 'gp') {
								let addGateway = false
								/*I compare and check that there is even a difference in the banks, if so the change is assigned*/
								bankData.forEach((dataBank) => {
									gateway.BankData.forEach((bank) => {
										const CompareIdBank = gatewayId === null ? dataBank.idSelect : dataBank.id
										if (CompareIdBank !== bank.id && (dataBank.bankAccountFunction === 0 || dataBank.bankAccountFunction === 2) && (bank.bankAccountFunction === 0 || bank.bankAccountFunction === 2)) {
											addGateway = true
										}
									})
								})

								const newPush = {
									GatewayName: selectGateway(gateway.GatewayName),
									AccountId: gateway.AccountId,
									FundingRollUp: gateway.FundingRollUp,
									FundingHoldingDays: gateway.FundingHoldingDays === null ? 1 : gateway.FundingHoldingDays,
									FundingMode: gateway.FundingMode,
									FundingBatchPreference: gateway.FundingBatchPreference,
									Connector: gateway.Connector,
									Tiers: gateway.Tiers,
									Currencies: gateway.Currencies,
									BankData: newBank,
									Id: gateway.Id
								}

								if (addGateway && gatewayId === null) {
									newGateways.push(newPush)
								} else if (addGateway && gatewayId !== gateway.Id) {
									newGateways.push(newPush)
								}
							}
						})

						/* add bank to Services GP */
						if (newGateways.length > 0) {
							serviceGP.push({
								ServiceName: dataService.ServiceName,
								ServiceGroup: dataService.ServiceGroup,
								ServiceProvider: 0, //dataService.ServiceProviderName,
								Enabled: dataService.ServiceEnabled,
								Gateways: newGateways
							})
						}
					}
				})
			)
		}
		return serviceGP.length > 0 ? { Services: serviceGP } : ''
	})
	const validateBankGP = () => {
		const serviceCard = { moneyin: ['card', 'ach', 'check', 'cloud', 'cash', 'wallet'], moneyout: ['managed', 'ach', 'check', 'vcard', 'pushToCard'] }
		let errorBank = false,
			serviceNull = []

		for (let serviceType in serviceCard) {
			serviceCard[serviceType].forEach((type) =>
				service[serviceType][type].forEach((dataService) => {
					if (dataService.Gateways.find((gateway) => gateway.Connector.Name.toLowerCase() === 'gp')) {
						dataService.Gateways.forEach((gateway) => {
							if (gateway.Connector.Name.toLowerCase() === 'gp') {
								if (!Array.isArray(gateway.BankData)) {
									serviceNull.push(dataService.ServiceName)
									errorBank = true
								}
							}
						})
					}
				})
			)
		}
		return { bankNull: errorBank, service: serviceNull }
	}
	const addServiceUpdate = action((serviceData, updateserviceData) => {
		const newServiceData = serviceData.Services
		const newupdateserviceData = updateserviceData.Services

		newupdateserviceData.forEach((dataServ) => {
			newServiceData.push(dataServ)
		})

		return { Services: newServiceData }
	})
	const createBankForGlobal = action((dataBank, isNewService = false) => {
		const NewBanking = {}
		if (Array.isArray(dataBank) && dataBank.length > 0) {
			const findBanck = dataBank.find((data) => data.bankAccountFunction === 0 || data.bankAccountFunction === 2)
			if (!Array.isArray(findBanck) && findBanck?.id) {
				const idBank = isNewService ? findBanck.idSelect : findBanck.id
				const banckData = banking.bankData.find(({ id }) => id === idBank)
				const merchantID = getValuesForBankGP('merchantID', banckData.id)
				NewBanking.id = banckData.id
				NewBanking.bankName = banckData.bankName
				NewBanking.bankAccountHolderName = banckData.bankAccountHolderName
				NewBanking.bankAccountHolderType = banckData.bankAccountHolderType
				NewBanking.typeAccount = banckData.typeAccount
				NewBanking.country = banckData.country === null || banckData.country === undefined || banckData.country.trim().length === 0 ? 'US' : banckData.country
				NewBanking.bankAccountFunction = findBanck.bankAccountFunction
				NewBanking.routingAccount = banckData.routingAccount
				NewBanking.accountNumber = banckData.accountNumber
				NewBanking.merchantID = merchantID
			}
		}
		return NewBanking?.id ? NewBanking : ''
	})
	const createService = action(async (dataService, messageSuccessfull, create = true) => {
		try {
			const serviceData = structureService(dataService.processor, dataService)
			let updateserviceData = ''

			if (dataService.processor === 'gp') {
				const validateService = validateBankGP()
				if (validateService?.bankNull) {
					let errorMessage = `The following services ${Array.isArray(validateService?.service) ? validateService?.service.join(', ').toString() : ''} do not have an assigned bank, you must assign the banks corresponding to each service`
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
					return false
				}
				updateserviceData = updateBankServiceGP(dataService.BankData)
			}

			const res = create ? await servicePOST(payPoint.entryPoints[0].entryName, serviceData) : await servicePUT(payPoint.entryPoints[0].entryName, serviceData)
			if (res.data.isSuccess === true) {
				if (updateserviceData !== '') {
					const res = await servicePUT(payPoint.entryPoints[0].entryName, updateserviceData)
					if (res.data.isSuccess === true) {
						console.log('other services successfully updated')
					}
					const NewBanking = createBankForGlobal(dataService.BankData, true)
					if (NewBanking !== '') {
						const response = await updateMerchantBankAccountPUT(payPoint.entryPoints[0].entryName, NewBanking)
						if (response.data.isSuccess === true) {
							console.log('The bank was sent to global')
						}
					}
				}
				toast.success(messageSuccessfull, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
				return true
			}
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})
	const updateService = action(async (select, serviceType, moneyType, indexService, indexGateways, messageSuccessfull, dataFields, indexTier, state) => {
		try {
			let serviceData = selectProcess(select, serviceType, moneyType, indexService, indexGateways, dataFields, indexTier, state)
			let updateserviceData = ''
			let BankData = service[moneyType][serviceType][indexService].Gateways[indexGateways].BankData
			let idGateway = service[moneyType][serviceType][indexService].Gateways[indexGateways].Id

			/*Here the banks of GP services are updated*/
			if (service[moneyType][serviceType][indexService].Gateways[indexGateways].Connector.Name === 'gp') {
				const validateService = validateBankGP()
				if (validateService?.bankNull) {
					let errorMessage = `The following services ${Array.isArray(validateService?.service) ? validateService?.service.join(', ').toString() : ''} do not have an assigned bank, you must assign the banks corresponding to each service`
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
					return false
				}
				updateserviceData = updateBankServiceGP(BankData, idGateway)
			}
			if (updateserviceData !== '') serviceData = addServiceUpdate(serviceData, updateserviceData)

			const res = await servicePUT(payPoint.entryPoints[0].entryName, serviceData)
			if (res.data.isSuccess === true) {
				if (updateserviceData !== '') {
					const NewBanking = createBankForGlobal(BankData)
					if (NewBanking !== '') {
						const response = await updateMerchantBankAccountPUT(payPoint.entryPoints[0].entryName, NewBanking)
						if (response.data.isSuccess === true) {
							console.log('The bank was sent to global')
						}
					}
				}

				toast.success(messageSuccessfull, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
				if (updateserviceData !== '') loadPaypointService()
				return true
			}
			return false
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})
	const deleteService = action(async (serviceType, moneyType, indexService, indexGateways, allServices = false) => {
		try {
			const serviceData = {
				Services: [
					{
						ServiceName: service[moneyType][serviceType][indexService].ServiceName,
						ServiceGroup: service[moneyType][serviceType][indexService].ServiceGroup,
						ServiceProvider: service[moneyType][serviceType][indexService].ServiceProvider,
						Enabled: service[moneyType][serviceType][indexService].ServiceEnabled,
						Gateways: !allServices ? [service[moneyType][serviceType][indexService].Gateways[indexGateways]] : service[moneyType][serviceType][indexService].Gateways
					}
				]
			}
			const res = await serviceDELETE(payPoint.entryPoints[0].entryName, serviceData)

			if (res.data.isSuccess === true) {
				toast.success('Service removed successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			}
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return
		}
	})

	return {
		createService,
		updateService,
		deleteService
	}
}
